var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "base-block",
    {
      staticClass: "mt-4",
      attrs: { title: "User properties", "header-bg": "" }
    },
    [
      _vm.spinner
        ? _c("div", { staticClass: "text-center" }, [
            _c("div", {
              staticClass: "spinner-grow text-primary m-5",
              attrs: { role: "status" }
            })
          ])
        : _c(
            "b-table-simple",
            { attrs: { responsive: "", "table-class": "table-vcenter" } },
            [
              _c("b-thead", { attrs: { "head-variant": "light" } }),
              _vm._v(" "),
              _c(
                "b-tbody",
                [
                  _c(
                    "b-tr",
                    [
                      _c("b-td", [_vm._v(" Name")]),
                      _vm._v(" "),
                      _c("b-td", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.user.first_name) +
                            " " +
                            _vm._s(_vm.user.last_name) +
                            " "
                        )
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tr",
                    [
                      _c("b-td", [_vm._v(" Email ")]),
                      _vm._v(" "),
                      _c("b-td", [_vm._v(" " + _vm._s(_vm.user.email) + " ")])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tr",
                    [
                      _c("b-td", [_vm._v(" Tracker")]),
                      _vm._v(" "),
                      _c("b-td", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.tracker[_vm.user.affiliateid].name) +
                            " "
                        )
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tr",
                    [
                      _c("b-td", [_vm._v(" Registration date")]),
                      _vm._v(" "),
                      _c("b-td", [
                        _vm._v(" " + _vm._s(_vm.user.timestamp) + " ")
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tr",
                    [
                      _c("b-td", [_vm._v(" Registered? ")]),
                      _vm._v(" "),
                      _c("b-td", [
                        _c("i", {
                          class: _vm.user.temporary
                            ? "fa fa-fw fa-times"
                            : "fa fa-fw fa-check"
                        })
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tr",
                    [
                      _c("b-td", [_vm._v(" Currency ")]),
                      _vm._v(" "),
                      _c("b-td", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.currencies[_vm.user.currency].name) +
                            " (" +
                            _vm._s(_vm.currencies[_vm.user.currency].sign) +
                            ") "
                        )
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tr",
                    [
                      _c("b-td", [_vm._v(" Language ")]),
                      _vm._v(" "),
                      _c("b-td", [
                        _vm._v(" " + _vm._s(_vm.user.language) + " ")
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tr",
                    [
                      _c("b-td", [_vm._v(" Country ")]),
                      _vm._v(" "),
                      _c(
                        "b-td",
                        [
                          _c("country-flag", {
                            attrs: {
                              country: _vm.countries[
                                _vm.user.countryid
                              ].iso.toLowerCase(),
                              size: "normal"
                            }
                          }),
                          _vm._v(
                            " " +
                              _vm._s(_vm.countries[_vm.user.countryid].name) +
                              "\n\t\t\t\t"
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }