import { Vue, Component, Ref } from 'vue-property-decorator';
import EditModel2dTable from 'components/Model2dTable/EditModel2dTable.vue';

@Component({
	components: {
		EditModel2dTable,
	},
})
export default class EditModel2d extends Vue {
	@Ref('editmodel2d') readonly model2dModal!: HTMLFormElement;

	private isLoading = false;

	get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	updateModel2dProperties(): void {
		this.model2dModal.editModel2dImage();
	}
}
