var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: _vm.subcategory ? "" : "ml-5 mt-5",
      style: _vm.subcategory ? "" : "width: 95%"
    },
    [
      _c("loading-overlay", {
        attrs: { active: _vm.isLoaded, "can-cancel": true }
      }),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "addproductcategory-modal",
          attrs: { id: "productcategory-modal", "hide-footer": "" },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function() {
                return [_vm._v("\n\t\t\tCreate New Lister Item\n\t\t")]
              },
              proxy: true
            }
          ])
        },
        [
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-block text-center" },
            [
              _c(
                "b-form-group",
                {
                  staticClass: "form-group",
                  attrs: { label: "Name: ", "label-for": "block-form1-name" }
                },
                [
                  _c("b-form-input", {
                    attrs: { type: "text" },
                    on: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.addProductCategory.apply(null, arguments)
                      }
                    },
                    model: {
                      value: _vm.name,
                      callback: function($$v) {
                        _vm.name = $$v
                      },
                      expression: "name"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { block: "" },
              on: {
                click: function($event) {
                  return _vm.addProductCategory()
                }
              }
            },
            [_vm._v("\n\t\t\tSave\n\t\t")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "base-block",
        {
          attrs: {
            title: _vm.subcategory ? "" : "Existing Lister Items",
            "header-bg": ""
          }
        },
        [_c("div", { ref: "productCategory" })]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }