var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-tr",
    [
      _c("b-td", [_vm._v(_vm._s(_vm.badg.id))]),
      _vm._v(" "),
      _c("b-td", [_vm._v(_vm._s(_vm.badg.name))]),
      _vm._v(" "),
      _c(
        "b-td",
        [
          _c("b-form-checkbox", {
            attrs: { switch: "", size: "lg" },
            on: {
              change: function($event) {
                return _vm.editBadges(_vm.badg)
              }
            },
            model: {
              value: _vm.selected,
              callback: function($$v) {
                _vm.selected = $$v
              },
              expression: "selected"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }