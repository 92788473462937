var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "base-block",
        { attrs: { title: "Page properties", "header-bg": "" } },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { md: "6" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { "label-for": "example-select" } },
                    [
                      _c(
                        "b-form-select",
                        {
                          staticClass: "mb-2",
                          attrs: { id: "example-select", plain: "" },
                          on: { change: _vm.onPageTemplateChange },
                          model: {
                            value: _vm.templateData,
                            callback: function($$v) {
                              _vm.templateData = $$v
                            },
                            expression: "templateData"
                          }
                        },
                        [
                          _c("b-form-select-option", { attrs: { value: {} } }, [
                            _vm._v(
                              "\n\t\t\t\t\t\t\tPlease select an option\n\t\t\t\t\t\t"
                            )
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.template, function(temp, id) {
                            return _c(
                              "b-form-select-option",
                              { key: id, attrs: { value: temp } },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t" +
                                    _vm._s(temp.name) +
                                    "\n\t\t\t\t\t\t"
                                )
                              ]
                            )
                          })
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { attrs: { md: "6" } },
                [
                  _vm.templateData.name
                    ? _c(
                        "b-button",
                        {
                          attrs: { variant: "success" },
                          on: {
                            click: function($event) {
                              return _vm.editPageTemplate()
                            }
                          }
                        },
                        [_c("i", { staticClass: "fa fa-edit" })]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "danger" },
                      on: {
                        click: function($event) {
                          return _vm.deletePageTemplate()
                        }
                      }
                    },
                    [_c("i", { staticClass: "fa fa-trash" })]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }