var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ml-5 mt-4", staticStyle: { width: "95%" } },
    [
      _c("loading-overlay", {
        attrs: { active: _vm.isLoaded, "can-cancel": true }
      }),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "addcurrency-modal",
          attrs: { id: "currency-modal", "hide-footer": "" },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function() {
                return [_vm._v("\n\t\t\tCreate New Currency\n\t\t")]
              },
              proxy: true
            }
          ])
        },
        [
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-block text-left" },
            [
              _c(
                "b-form-group",
                {
                  staticClass: "form-group",
                  attrs: { label: "ID ", "label-for": "block-form1-name" }
                },
                [
                  _c("ValidationProvider", {
                    attrs: { rules: "required|apha_dash", name: "ID" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function({ classes, errors }) {
                          return [
                            _c(
                              "div",
                              { staticClass: "control", class: classes },
                              [
                                _c("b-form-input", {
                                  attrs: { type: "text", required: "" },
                                  model: {
                                    value: _vm.currencyData.id,
                                    callback: function($$v) {
                                      _vm.$set(_vm.currencyData, "id", $$v)
                                    },
                                    expression: "currencyData.id"
                                  }
                                }),
                                _vm._v(" "),
                                _c("span", [_vm._v(_vm._s(errors[0]))])
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-group",
                { attrs: { label: "Currency", "label-for": "example-select" } },
                [
                  _c("ValidationProvider", {
                    attrs: { rules: "required", name: "Currency" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function({ classes, errors }) {
                          return [
                            _c(
                              "div",
                              { staticClass: "control", class: classes },
                              [
                                _c(
                                  "b-form-select",
                                  {
                                    staticClass: "mb-2",
                                    attrs: { id: "example-select", plain: "" },
                                    model: {
                                      value: _vm.currencyData.data,
                                      callback: function($$v) {
                                        _vm.$set(_vm.currencyData, "data", $$v)
                                      },
                                      expression: "currencyData.data"
                                    }
                                  },
                                  [
                                    _c(
                                      "b-form-select-option",
                                      { attrs: { value: "" } },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\tPlease select an option\n\t\t\t\t\t\t\t"
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm._l(_vm.jsonCurrency, function(
                                      curr,
                                      id
                                    ) {
                                      return _c(
                                        "b-form-select-option",
                                        { key: id, attrs: { value: curr } },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t" +
                                              _vm._s(curr.name) +
                                              " - " +
                                              _vm._s(curr.sign) +
                                              " - " +
                                              _vm._s(curr.name) +
                                              "\n\t\t\t\t\t\t\t"
                                          )
                                        ]
                                      )
                                    })
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _c("span", [_vm._v(_vm._s(errors[0]))])
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  staticClass: "mt-3",
                  attrs: { block: "" },
                  on: {
                    click: function($event) {
                      return _vm.addCurrency()
                    }
                  }
                },
                [_vm._v("\n\t\t\t\tSave\n\t\t\t")]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "base-block",
        { attrs: { title: "Existing currencies", "header-bg": "" } },
        [_c("div", { ref: "currencyTable" })]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }