var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ml-5", staticStyle: { width: "95%" } },
    [
      _c("loading-overlay", {
        attrs: { active: _vm.isLoading, "can-cancel": true }
      }),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "addappsetting-modal",
          attrs: { id: "appsetting-modal", "hide-footer": "" },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function() {
                return [_vm._v("\n\t\t\tAdd new Setting\n\t\t")]
              },
              proxy: true
            }
          ])
        },
        [
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-block text-center" },
            [
              _c(
                "b-form-group",
                {
                  staticClass: "form-group",
                  attrs: { label: "Key: ", "label-for": "block-form1-name" }
                },
                [
                  _c("b-form-input", {
                    attrs: { type: "text" },
                    model: {
                      value: _vm.settingsData.key,
                      callback: function($$v) {
                        _vm.$set(_vm.settingsData, "key", $$v)
                      },
                      expression: "settingsData.key"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-group",
                {
                  staticClass: "form-group",
                  attrs: { label: "Value: ", "label-for": "block-form1-name" }
                },
                [
                  _c("b-form-input", {
                    attrs: { type: "text" },
                    model: {
                      value: _vm.settingsData.value,
                      callback: function($$v) {
                        _vm.$set(_vm.settingsData, "value", $$v)
                      },
                      expression: "settingsData.value"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { block: "" },
              on: {
                click: function($event) {
                  return _vm.saveSetting()
                }
              }
            },
            [_vm._v("\n\t\t\tSave\n\t\t")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "base-block",
        { attrs: { title: "App Settings", "header-bg": "" } },
        [
          _c(
            "b-row",
            { staticClass: "d-flex justify-content-end mb-2" },
            [
              _c(
                "b-col",
                {
                  staticClass: "d-flex justify-content-end mb-2",
                  attrs: { md: "6" }
                },
                [
                  _c(
                    "b-form-group",
                    [
                      _c(
                        "b-form-select",
                        {
                          attrs: { id: "per-page-select", size: "sm" },
                          on: {
                            change: function($event) {
                              return _vm.getData()
                            }
                          },
                          model: {
                            value: _vm.affiliateid,
                            callback: function($$v) {
                              _vm.affiliateid = $$v
                            },
                            expression: "affiliateid"
                          }
                        },
                        [
                          _c(
                            "b-form-select-option",
                            { attrs: { value: "null" } },
                            [_vm._v("\n\t\t\t\t\t\t\tAll\n\t\t\t\t\t\t")]
                          ),
                          _vm._v(" "),
                          _vm._l(_vm.AffiliateData, function(data, index) {
                            return _c(
                              "b-form-select-option",
                              { key: index, attrs: { value: data.id } },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t" +
                                    _vm._s(data.title) +
                                    "\n\t\t\t\t\t\t"
                                )
                              ]
                            )
                          })
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { ref: "appSettingTable" })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }