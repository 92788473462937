import { Vue, Component, Ref } from 'vue-property-decorator';
import EditPdpTable from 'components/PdpTable/EditPdpTable.vue';

@Component({
	components: {
		EditPdpTable,
	},
})
export default class EditPdp extends Vue {
	@Ref('editPdpComponent') readonly editPdpChild!: HTMLFormElement;

	get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	updatePdpProperties(): void {
		this.editPdpChild.updatePDP();
	}
}
