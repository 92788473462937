import { Vue, Component, Ref } from 'vue-property-decorator';
import PageObjectEditTable from 'components/Themes/ThemesTable/PageObjectEditTable';
import Template from './template.vue';

@Component({
	components: {
		PageObjectEditTable,
	},
})
export default class PageObject extends Vue.extend(Template) {
	@Ref('objecteditpage')
	private readonly objectedit!: HTMLFormElement;

	private pageId = this.$route.params.pageid;

	private objectId = this.$route.params.objectid;

	protected updateObjectEditProperties(): void {
		this.objectedit.editPageObject();
	}
}
