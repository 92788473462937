var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ml-5 mt-4", staticStyle: { width: "95%", height: "100%" } },
    [
      _c("loading-overlay", {
        attrs: { active: _vm.isLoading, "can-cancel": true }
      }),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "12", lg: "12", sm: "12" } },
            [
              _c(
                "b-tabs",
                { attrs: { card: "", "content-class": "mt-3", fill: "" } },
                [
                  _c(
                    "b-tab",
                    { attrs: { title: "Page properties", active: "" } },
                    [
                      _c(
                        "base-block",
                        {
                          attrs: { title: "Page properties", "header-bg": "" }
                        },
                        [
                          _vm.spinner
                            ? _c("div", { staticClass: "text-center" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "spinner-grow text-primary m-5",
                                    attrs: { role: "status" }
                                  },
                                  [
                                    _c("span", { staticClass: "sr-only" }, [
                                      _vm._v("Loading...")
                                    ])
                                  ]
                                )
                              ])
                            : _c(
                                "div",
                                { staticClass: "d-block text-center" },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-for": "example-select",
                                        "label-cols-lg": "4"
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function() {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "d-flex flex-row"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\tType\n\t\t\t\t\t\t\t\t\t\t"
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: { id: "type" }
                                                  })
                                                ]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ])
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "type",
                                            triggers: "hover",
                                            placement: "bottom"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\tOne of the three object types(photo, text)\n\t\t\t\t\t\t\t\t"
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("b-form-select", {
                                        staticClass: "mb-2",
                                        attrs: {
                                          id: "type",
                                          disabled: "",
                                          plain: ""
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "first",
                                            fn: function() {
                                              return [
                                                _c("b-form-select-option", [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.singlePageObjectEdit
                                                          .type
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t\t\t"
                                                  )
                                                ])
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ])
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "form-group",
                                      attrs: {
                                        "label-for": "block-form1-name",
                                        "label-cols-lg": "4"
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function() {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "d-flex flex-row"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\tX-axis\n\t\t\t\t\t\t\t\t\t\t"
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: { id: "xaxis" }
                                                  })
                                                ]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ])
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "xaxis",
                                            triggers: "hover",
                                            placement: "bottom"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\tThe x-axis coordinate of the object on the page\n\t\t\t\t\t\t\t\t"
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-input-group",
                                        [
                                          _c("b-form-input", {
                                            attrs: { type: "text" },
                                            model: {
                                              value:
                                                _vm.singlePageObjectEdit.x_axis,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.singlePageObjectEdit,
                                                  "x_axis",
                                                  _vm._n($$v)
                                                )
                                              },
                                              expression:
                                                "singlePageObjectEdit.x_axis"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "form-group",
                                      attrs: {
                                        "label-for": "block-form1-name",
                                        "label-cols-lg": "4"
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function() {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "d-flex flex-row"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\tY-axis\n\t\t\t\t\t\t\t\t\t\t"
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: { id: "yaxis" }
                                                  })
                                                ]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ])
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "yaxis",
                                            triggers: "hover",
                                            placement: "bottom"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\tThe y-axis coordinate of the object on the page\n\t\t\t\t\t\t\t\t"
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-input-group",
                                        [
                                          _c("b-form-input", {
                                            attrs: { type: "text" },
                                            model: {
                                              value:
                                                _vm.singlePageObjectEdit.y_axis,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.singlePageObjectEdit,
                                                  "y_axis",
                                                  _vm._n($$v)
                                                )
                                              },
                                              expression:
                                                "singlePageObjectEdit.y_axis"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "form-group",
                                      attrs: {
                                        "label-for": "block-form1-name",
                                        "label-cols-lg": "4"
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function() {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "d-flex flex-row"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\tZ-axis\n\t\t\t\t\t\t\t\t\t\t"
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: { id: "zaxis" }
                                                  })
                                                ]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ])
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "zaxis",
                                            triggers: "hover",
                                            placement: "bottom"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\tThe z-axis coordinate of the object on the page\n\t\t\t\t\t\t\t\t"
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-input-group",
                                        [
                                          _c("b-form-input", {
                                            attrs: { type: "text" },
                                            model: {
                                              value:
                                                _vm.singlePageObjectEdit.z_axis,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.singlePageObjectEdit,
                                                  "z_axis",
                                                  _vm._n($$v)
                                                )
                                              },
                                              expression:
                                                "singlePageObjectEdit.z_axis"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "form-group",
                                      attrs: {
                                        "label-for": "block-form1-name",
                                        "label-cols-lg": "4"
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function() {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "d-flex flex-row"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\tWidth\n\t\t\t\t\t\t\t\t\t\t"
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: { id: "width" }
                                                  })
                                                ]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ])
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "width",
                                            triggers: "hover",
                                            placement: "bottom"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\tThe width of the object in pixels on the page\n\t\t\t\t\t\t\t\t"
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-input-group",
                                        [
                                          _c("b-form-input", {
                                            attrs: { type: "text" },
                                            model: {
                                              value:
                                                _vm.singlePageObjectEdit.width,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.singlePageObjectEdit,
                                                  "width",
                                                  _vm._n($$v)
                                                )
                                              },
                                              expression:
                                                "singlePageObjectEdit.width"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "form-group",
                                      attrs: {
                                        "label-for": "block-form1-name",
                                        "label-cols-lg": "4"
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function() {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "d-flex flex-row"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\tHeight\n\t\t\t\t\t\t\t\t\t\t"
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: { id: "height" }
                                                  })
                                                ]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ])
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "height",
                                            triggers: "hover",
                                            placement: "bottom"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\tThe height of the object in pixels on the page\n\t\t\t\t\t\t\t\t"
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-input-group",
                                        [
                                          _c("b-form-input", {
                                            attrs: { type: "text" },
                                            model: {
                                              value:
                                                _vm.singlePageObjectEdit.height,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.singlePageObjectEdit,
                                                  "height",
                                                  _vm._n($$v)
                                                )
                                              },
                                              expression:
                                                "singlePageObjectEdit.height"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "form-group",
                                      attrs: {
                                        "label-for": "block-form1-name",
                                        "label-cols-lg": "4"
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function() {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "d-flex flex-row"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\tMaximum Width\n\t\t\t\t\t\t\t\t\t\t"
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: { id: "maxwidth" }
                                                  })
                                                ]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ])
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "maxwidth",
                                            triggers: "hover",
                                            placement: "bottom"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\tThe maximum width the object can be in pixels\n\t\t\t\t\t\t\t\t"
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-input-group",
                                        {
                                          scopedSlots: _vm._u([
                                            {
                                              key: "append",
                                              fn: function() {
                                                return [
                                                  _c(
                                                    "b-input-group-text",
                                                    {
                                                      staticClass:
                                                        "input-group-text-alt",
                                                      staticStyle: {
                                                        cursor: "pointer"
                                                      }
                                                    },
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          id:
                                                            "checkbox-maxwidth",
                                                          name: "checkbox-1",
                                                          checked: _vm.checkPropertyNull(
                                                            "maxwidth"
                                                          ),
                                                          "unchecked-value":
                                                            "not_accepted"
                                                        },
                                                        on: {
                                                          change: function(
                                                            $event
                                                          ) {
                                                            return _vm.setPropertyNull(
                                                              "maxwidth"
                                                            )
                                                          }
                                                        }
                                                      }),
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t\t\t"
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ])
                                        },
                                        [
                                          _c("b-form-select", {
                                            staticClass: "mb-2",
                                            attrs: {
                                              id: "example-select",
                                              plain: ""
                                            },
                                            model: {
                                              value:
                                                _vm.singlePageObjectEdit
                                                  .maxwidth,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.singlePageObjectEdit,
                                                  "maxwidth",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "singlePageObjectEdit.maxwidth"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "form-group",
                                      attrs: {
                                        "label-for": "block-form1-name",
                                        "label-cols-lg": "4"
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function() {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "d-flex flex-row"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\tMaximum Height\n\t\t\t\t\t\t\t\t\t\t"
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: { id: "maxheight" }
                                                  })
                                                ]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ])
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "maxheight",
                                            triggers: "hover",
                                            placement: "bottom"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\tThe maximum height the object can be in pixels\n\t\t\t\t\t\t\t\t"
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-input-group",
                                        {
                                          scopedSlots: _vm._u([
                                            {
                                              key: "append",
                                              fn: function() {
                                                return [
                                                  _c(
                                                    "b-input-group-text",
                                                    {
                                                      staticClass:
                                                        "input-group-text-alt",
                                                      staticStyle: {
                                                        cursor: "pointer"
                                                      }
                                                    },
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          id:
                                                            "checkbox-maxheight",
                                                          name: "checkbox-1",
                                                          checked: _vm.checkPropertyNull(
                                                            "maxheight"
                                                          ),
                                                          "unchecked-value":
                                                            "not_accepted"
                                                        },
                                                        on: {
                                                          change: function(
                                                            $event
                                                          ) {
                                                            return _vm.setPropertyNull(
                                                              "maxheight"
                                                            )
                                                          }
                                                        }
                                                      }),
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t\t\t"
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ])
                                        },
                                        [
                                          _c("b-form-select", {
                                            staticClass: "mb-2",
                                            attrs: {
                                              id: "example-select",
                                              plain: ""
                                            },
                                            model: {
                                              value:
                                                _vm.singlePageObjectEdit
                                                  .maxheight,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.singlePageObjectEdit,
                                                  "maxheight",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "singlePageObjectEdit.maxheight"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "form-group",
                                      attrs: {
                                        "label-for": "block-form1-name",
                                        "label-cols-lg": "4"
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function() {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "d-flex flex-row"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\tRotational angle\n\t\t\t\t\t\t\t\t\t\t"
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: {
                                                      id: "rotationalangle"
                                                    }
                                                  })
                                                ]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ])
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "rotationalangle",
                                            triggers: "hover",
                                            placement: "bottom"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\tThe angle(In degrees) in which the object is displayed on the page\n\t\t\t\t\t\t\t\t"
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-input-group",
                                        [
                                          _c("b-form-input", {
                                            attrs: { type: "text" },
                                            model: {
                                              value:
                                                _vm.singlePageObjectEdit.rotate,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.singlePageObjectEdit,
                                                  "rotate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "singlePageObjectEdit.rotate"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "form-group",
                                      attrs: {
                                        "label-for": "block-form1-name",
                                        "label-cols-lg": "4"
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function() {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "d-flex flex-row"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\tBorder width\n\t\t\t\t\t\t\t\t\t\t"
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: { id: "borderwidth" }
                                                  })
                                                ]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ])
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "borderwidth",
                                            triggers: "hover",
                                            placement: "bottom"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\tThe hex width of the border around the object\n\t\t\t\t\t\t\t\t"
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-input-group",
                                        [
                                          _c("b-form-input", {
                                            attrs: { type: "text" },
                                            model: {
                                              value:
                                                _vm.singlePageObjectEdit
                                                  .borderwidth,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.singlePageObjectEdit,
                                                  "borderwidth",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "singlePageObjectEdit.borderwidth"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "form-group",
                                      attrs: {
                                        "label-for": "block-form1-name",
                                        "label-cols-lg": "4"
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function() {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "d-flex flex-row"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\tBorder color\n\t\t\t\t\t\t\t\t\t\t"
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: { id: "bordercolor" }
                                                  })
                                                ]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ])
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "bordercolor",
                                            triggers: "hover",
                                            placement: "bottom"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\tThe hex color of the border around the object\n\t\t\t\t\t\t\t\t"
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-input-group",
                                        [
                                          _c("b-form-input", {
                                            attrs: { type: "text" },
                                            model: {
                                              value:
                                                _vm.singlePageObjectEdit
                                                  .bordercolor,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.singlePageObjectEdit,
                                                  "bordercolor",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "singlePageObjectEdit.bordercolor"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "form-group",
                                      attrs: {
                                        "label-for": "block-form1-name",
                                        "label-cols-lg": "4"
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function() {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "d-flex flex-row"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\tBorder image\n\t\t\t\t\t\t\t\t\t\t"
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: { id: "bgimage" }
                                                  })
                                                ]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ])
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "bgimage",
                                            triggers: "hover",
                                            placement: "bottom"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\tThe border image that should be place around the object\n\t\t\t\t\t\t\t\t"
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-input-group",
                                        {
                                          scopedSlots: _vm._u([
                                            {
                                              key: "append",
                                              fn: function() {
                                                return [
                                                  _c(
                                                    "b-input-group-text",
                                                    {
                                                      staticClass:
                                                        "input-group-text-alt",
                                                      staticStyle: {
                                                        cursor: "pointer"
                                                      }
                                                    },
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          id:
                                                            "checkbox-borderimage",
                                                          name: "checkbox-1",
                                                          checked: _vm.checkPropertyNull(
                                                            "border_image"
                                                          ),
                                                          "unchecked-value":
                                                            "not_accepted"
                                                        },
                                                        on: {
                                                          change: function(
                                                            $event
                                                          ) {
                                                            return _vm.setPropertyNull(
                                                              "border_image"
                                                            )
                                                          }
                                                        }
                                                      }),
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t\t\t"
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ])
                                        },
                                        [
                                          _c("b-form-select", {
                                            staticClass: "mb-2",
                                            attrs: {
                                              id: "example-select",
                                              plain: ""
                                            },
                                            model: {
                                              value:
                                                _vm.singlePageObjectEdit
                                                  .borderimage,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.singlePageObjectEdit,
                                                  "borderimage",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "singlePageObjectEdit.borderimage"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-class": "font-w600",
                                        "label-cols-md": "4"
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function() {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "d-flex flex-row"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\tEditable\n\t\t\t\t\t\t\t\t\t\t"
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: { id: "editable" }
                                                  })
                                                ]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ])
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "editable",
                                            triggers: "hover",
                                            placement: "bottom"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\tIs the page editable by the user\n\t\t\t\t\t\t\t\t"
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("b-form-checkbox", {
                                        attrs: {
                                          switch: "",
                                          size: "lg",
                                          checked: _vm.checkPropertyTrue(
                                            "editable"
                                          )
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.updateProperty(
                                              "editable",
                                              $event
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-class": "font-w600",
                                        "label-cols-md": "4"
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function() {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "d-flex flex-row"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\tTransformable\n\t\t\t\t\t\t\t\t\t\t"
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: { id: "movable" }
                                                  })
                                                ]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ])
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "transformable",
                                            triggers: "hover",
                                            placement: "bottom"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\tIs the object draggable, resizable and rotatable by the user\n\t\t\t\t\t\t\t\t"
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("b-form-checkbox", {
                                        attrs: {
                                          switch: "",
                                          size: "lg",
                                          checked: _vm.checkPropertyTrue(
                                            "transformable"
                                          )
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.updateProperty(
                                              "transformable",
                                              $event
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    { attrs: { title: "Sub Object properties" } },
                    [
                      _c(
                        "base-block",
                        {
                          attrs: { title: "Page properties", "header-bg": "" }
                        },
                        [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.singlePageObjectEdit.type === "photo",
                                  expression:
                                    "singlePageObjectEdit.type === 'photo'"
                                }
                              ]
                            },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: { "label-cols-lg": "4" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "label",
                                      fn: function() {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "d-flex flex-row" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\timage\n\t\t\t\t\t\t\t\t\t\t"
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("div", {
                                                staticClass: "tip",
                                                attrs: { id: "image" }
                                              })
                                            ]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ])
                                },
                                [
                                  _vm._v(" "),
                                  _c("b-form-file", {
                                    ref: "logodesktop",
                                    attrs: {
                                      id: "thumbnail",
                                      placeholder: _vm.placeHolders.icon
                                    },
                                    nativeOn: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.updateIcon.apply(
                                          null,
                                          arguments
                                        )
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _vm.subObjectProperty.source
                                    ? _c("div", { staticClass: "thumbnail" }, [
                                        _c("img", {
                                          attrs: {
                                            src: _vm.subObjectProperty.source,
                                            alt: "",
                                            srcset: "",
                                            height: "100",
                                            width: "100"
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: { cursor: "pointer" },
                                            on: { click: _vm.removeIcon }
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t✖ Remove\n\t\t\t\t\t\t\t\t\t"
                                            )
                                          ]
                                        )
                                      ])
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.subObjectProperty.type === "text",
                                  expression:
                                    "subObjectProperty.type === 'text'"
                                }
                              ]
                            },
                            [
                              _c(
                                "b-form-group",
                                { attrs: { "label-for": "example-select" } },
                                [
                                  _c(
                                    "b-form-select",
                                    {
                                      staticClass: "mb-2",
                                      attrs: {
                                        id: "example-select",
                                        disabled: "",
                                        plain: ""
                                      },
                                      model: {
                                        value: _vm.fonts.fontface,
                                        callback: function($$v) {
                                          _vm.$set(_vm.fonts, "fontface", $$v)
                                        },
                                        expression: "fonts.fontface"
                                      }
                                    },
                                    [
                                      _c(
                                        "b-form-select-option",
                                        { attrs: { value: "" } },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\tPlease select an option\n\t\t\t\t\t\t\t\t\t"
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm._l(_vm.fonts, function(font, id) {
                                        return _c(
                                          "b-form-select-option",
                                          { key: id, attrs: { value: font } },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(font) +
                                                "\n\t\t\t\t\t\t\t\t\t"
                                            )
                                          ]
                                        )
                                      })
                                    ],
                                    2
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.fonts.fontbold === true
                                ? _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-class": "font-w600",
                                        "label-cols-md": "4"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "label",
                                            fn: function() {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex flex-row"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "mr-2" },
                                                      [
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\tBold\n\t\t\t\t\t\t\t\t\t\t"
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("div", {
                                                      staticClass: "tip",
                                                      attrs: { id: "bold" }
                                                    })
                                                  ]
                                                )
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ],
                                        null,
                                        false,
                                        437443167
                                      )
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "Bold",
                                            triggers: "hover",
                                            placement: "bottom"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\tIs the text displayed in Bold\n\t\t\t\t\t\t\t\t"
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("b-form-checkbox", {
                                        attrs: {
                                          switch: "",
                                          size: "lg",
                                          checked: _vm.checkPropertyTrue(
                                            "fontbold"
                                          )
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.updateProperty(
                                              "fontbold",
                                              $event
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.fonts.fontbold === true
                                ? _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-class": "font-w600",
                                        "label-cols-md": "4"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "label",
                                            fn: function() {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex flex-row"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "mr-2" },
                                                      [
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\tItalic\n\t\t\t\t\t\t\t\t\t\t"
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("div", {
                                                      staticClass: "tip",
                                                      attrs: { id: "italic" }
                                                    })
                                                  ]
                                                )
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ],
                                        null,
                                        false,
                                        4118863839
                                      )
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "italic",
                                            triggers: "hover",
                                            placement: "bottom"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\tIs the text displayed in Italic\n\t\t\t\t\t\t\t\t"
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("b-form-checkbox", {
                                        attrs: {
                                          switch: "",
                                          size: "lg",
                                          checked: _vm.checkPropertyTrue(
                                            "fontitalic"
                                          )
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.updateProperty(
                                              "fontitalic",
                                              $event
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "b-form-group",
                                {
                                  staticClass: "form-group",
                                  attrs: {
                                    "label-for": "block-form1-name",
                                    "label-cols-lg": "4"
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "label",
                                      fn: function() {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "d-flex flex-row" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\tText color\n\t\t\t\t\t\t\t\t\t\t"
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("div", {
                                                staticClass: "tip",
                                                attrs: { id: "textcolor" }
                                              })
                                            ]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ])
                                },
                                [
                                  _vm._v(" "),
                                  _c(
                                    "b-tooltip",
                                    {
                                      attrs: {
                                        target: "textcolor",
                                        triggers: "hover",
                                        placement: "bottom"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\tThe hex color of the text\n\t\t\t\t\t\t\t\t"
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-input-group",
                                    [
                                      _c("b-form-input", {
                                        attrs: { type: "text" },
                                        model: {
                                          value: _vm.fonts.fontcolor,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.fonts,
                                              "fontcolor",
                                              $$v
                                            )
                                          },
                                          expression: "fonts.fontcolor"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-form-group",
                                {
                                  staticClass: "form-group",
                                  attrs: {
                                    "label-for": "block-form1-name",
                                    "label-cols-lg": "4"
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "label",
                                      fn: function() {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "d-flex flex-row" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\tBackground color\n\t\t\t\t\t\t\t\t\t\t"
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("div", {
                                                staticClass: "tip",
                                                attrs: { id: "bgcolor" }
                                              })
                                            ]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ])
                                },
                                [
                                  _vm._v(" "),
                                  _c(
                                    "b-tooltip",
                                    {
                                      attrs: {
                                        target: "bgcolor",
                                        triggers: "hover",
                                        placement: "bottom"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\tThe Hex color behind the text\n\t\t\t\t\t\t\t\t"
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-input-group",
                                    {
                                      scopedSlots: _vm._u([
                                        {
                                          key: "append",
                                          fn: function() {
                                            return [
                                              _c(
                                                "b-input-group-text",
                                                {
                                                  staticClass:
                                                    "input-group-text-alt",
                                                  staticStyle: {
                                                    cursor: "pointer"
                                                  }
                                                },
                                                [
                                                  _c("b-form-checkbox", {
                                                    attrs: {
                                                      id: "checkbox-bgcolor",
                                                      name: "checkbox-1",
                                                      checked: _vm.checkPropertyNull(
                                                        "bgcolor"
                                                      ),
                                                      "unchecked-value":
                                                        "not_accepted"
                                                    },
                                                    on: {
                                                      change: function($event) {
                                                        return _vm.setPropertyNull(
                                                          "bgcolor"
                                                        )
                                                      }
                                                    }
                                                  }),
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t\t\t"
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ])
                                    },
                                    [
                                      _c("b-form-select", {
                                        staticClass: "mb-2",
                                        attrs: {
                                          id: "example-select",
                                          plain: ""
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-form-group",
                                {
                                  staticClass: "form-group",
                                  attrs: {
                                    "label-for": "block-form1-name",
                                    "label-cols-lg": "4"
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "label",
                                      fn: function() {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "d-flex flex-row" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\tFont size\n\t\t\t\t\t\t\t\t\t\t"
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("div", {
                                                staticClass: "tip",
                                                attrs: { id: "fontsize" }
                                              })
                                            ]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ])
                                },
                                [
                                  _vm._v(" "),
                                  _c(
                                    "b-tooltip",
                                    {
                                      attrs: {
                                        target: "fontsize",
                                        triggers: "hover",
                                        placement: "bottom"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\tThe size of the font in points\n\t\t\t\t\t\t\t\t"
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-input-group",
                                    [
                                      _c("b-form-input", {
                                        attrs: { type: "text" },
                                        model: {
                                          value: _vm.fonts.fontSize,
                                          callback: function($$v) {
                                            _vm.$set(_vm.fonts, "fontSize", $$v)
                                          },
                                          expression: "fonts.fontSize"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-form-group",
                                {
                                  staticClass: "form-group",
                                  attrs: {
                                    "label-for": "block-form1-name",
                                    "label-cols-lg": "4"
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "label",
                                      fn: function() {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "d-flex flex-row" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\tRaw text\n\t\t\t\t\t\t\t\t\t\t"
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("div", {
                                                staticClass: "tip",
                                                attrs: { id: "rawtext" }
                                              })
                                            ]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ])
                                },
                                [
                                  _vm._v(" "),
                                  _c(
                                    "b-tooltip",
                                    {
                                      attrs: {
                                        target: "rawtext",
                                        triggers: "hover",
                                        placement: "bottom"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\tThe raw text\n\t\t\t\t\t\t\t\t"
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-input-group",
                                    [
                                      _c("b-form-input", {
                                        attrs: { type: "text" },
                                        model: {
                                          value: _vm.fonts.text,
                                          callback: function($$v) {
                                            _vm.$set(_vm.fonts, "text", $$v)
                                          },
                                          expression: "fonts.text"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-form-group",
                                {
                                  staticClass: "form-group",
                                  attrs: {
                                    "label-for": "block-form1-name",
                                    "label-cols-lg": "4"
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "label",
                                      fn: function() {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "d-flex flex-row" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\tFormatted text\n\t\t\t\t\t\t\t\t\t\t"
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("div", {
                                                staticClass: "tip",
                                                attrs: { id: "formattedtext" }
                                              })
                                            ]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ])
                                },
                                [
                                  _vm._v(" "),
                                  _c(
                                    "b-tooltip",
                                    {
                                      attrs: {
                                        target: "formattedtext",
                                        triggers: "hover",
                                        placement: "bottom"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\tThe text including line breaks\n\t\t\t\t\t\t\t\t"
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-input-group",
                                    [
                                      _c("b-form-input", {
                                        attrs: { type: "text" },
                                        model: {
                                          value: _vm.fonts.text_formatted,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.fonts,
                                              "text_formatted",
                                              $$v
                                            )
                                          },
                                          expression: "fonts.text_formatted"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-form-group",
                                { attrs: { "label-for": "example-select" } },
                                [
                                  _c(
                                    "b-form-select",
                                    {
                                      staticClass: "mb-2",
                                      attrs: {
                                        id: "example-select",
                                        disabled: "",
                                        plain: ""
                                      },
                                      model: {
                                        value: _vm.fonts.align,
                                        callback: function($$v) {
                                          _vm.$set(_vm.fonts, "align", $$v)
                                        },
                                        expression: "fonts.align"
                                      }
                                    },
                                    [
                                      _c(
                                        "b-form-select-option",
                                        { attrs: { value: "" } },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\tPlease select an option\n\t\t\t\t\t\t\t\t\t"
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm._l(_vm.alignment, function(ali, id) {
                                        return _c(
                                          "b-form-select-option",
                                          { key: id, attrs: { value: ali } },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(ali) +
                                                "\n\t\t\t\t\t\t\t\t\t"
                                            )
                                          ]
                                        )
                                      })
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }