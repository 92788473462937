import { Vue, Component, Ref } from 'vue-property-decorator';
import EditTemplatePositionEdit from 'components/Themes/TemplateTable/TemplatePositionEdit';
import Template from './template.vue';

@Component({
	components: {
		EditTemplatePositionEdit,
	},
})
export default class EditTemplatePosition extends Vue.extend(Template) {
	@Ref('editTemplatePositionEdit')
	private readonly template!: HTMLFormElement;

	private templatePositionId = this.$route.params.id;

	protected updateTemplatePositionEdit(): void {
		this.template.editTemplate();
	}
}
