var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ml-5", staticStyle: { width: "95%" } },
    [
      _c("loading-overlay", {
        attrs: { active: _vm.isLoading, "can-cancel": true }
      }),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "6", lg: "6", sm: "12" } },
            [
              _c(
                "base-block",
                {
                  attrs: {
                    title: "Page Background properties",
                    "header-bg": ""
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-block text-center" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Name",
                            "label-for": "example-select",
                            "label-cols-lg": "4"
                          }
                        },
                        [
                          _c(
                            "b-input-group",
                            {
                              scopedSlots: _vm._u([
                                {
                                  key: "append",
                                  fn: function() {
                                    return [
                                      _c(
                                        "b-input-group-text",
                                        {
                                          staticClass: "input-group-text-alt",
                                          staticStyle: { cursor: "pointer" }
                                        },
                                        [
                                          _c("b-form-checkbox", {
                                            attrs: {
                                              id: "checkbox-2",
                                              name: "checkbox-1",
                                              checked: _vm.isNameChecked,
                                              "unchecked-value": "not_accepted"
                                            },
                                            on: {
                                              change: _vm.handleCheckboxChange
                                            }
                                          }),
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t"
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ])
                            },
                            [
                              _c("b-form-input", {
                                attrs: { type: "text" },
                                model: {
                                  value: _vm.singlePageBg.name,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.singlePageBg,
                                      "name",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "singlePageBg.name"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        { attrs: { label: "Image", "label-cols-lg": "4" } },
                        [
                          _c("b-form-file", {
                            ref: "image",
                            attrs: {
                              id: "image",
                              placeholder: _vm.placeHolders.thumbnailPlaceholder
                            },
                            nativeOn: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.updateImagePreview.apply(
                                  null,
                                  arguments
                                )
                              }
                            }
                          }),
                          _vm._v(" "),
                          _vm.singlePageBg.image
                            ? _c("div", { staticClass: "thumbnail" }, [
                                _c("img", {
                                  attrs: {
                                    src: _vm.singlePageBg.image,
                                    alt: "",
                                    srcset: "",
                                    height: "100",
                                    width: "100"
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    on: { click: _vm.removeImage }
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t✖ Remove\n\t\t\t\t\t\t\t"
                                    )
                                  ]
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Color",
                            "label-for": "example-select",
                            "label-cols-lg": "4"
                          }
                        },
                        [
                          _c(
                            "b-input-group",
                            {
                              scopedSlots: _vm._u([
                                {
                                  key: "append",
                                  fn: function() {
                                    return [
                                      _c(
                                        "b-input-group-text",
                                        {
                                          staticClass: "input-group-text-alt",
                                          staticStyle: { cursor: "pointer" }
                                        },
                                        [
                                          _c("b-form-checkbox", {
                                            attrs: {
                                              id: "checkbox-color",
                                              name: "checkbox-1",
                                              checked: _vm.isColorChecked,
                                              "unchecked-value": "not_accepted"
                                            },
                                            on: {
                                              change:
                                                _vm.handleColorCheckboxChange
                                            }
                                          }),
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t"
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ])
                            },
                            [
                              _c("b-form-input", {
                                attrs: { type: "text" },
                                model: {
                                  value: _vm.singlePageBg.color,
                                  callback: function($$v) {
                                    _vm.$set(_vm.singlePageBg, "color", $$v)
                                  },
                                  expression: "singlePageBg.color"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "form-group",
                          attrs: {
                            label: "serialnumber ",
                            "label-for": "block-form1-name",
                            "label-cols-lg": "4"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.singlePageBg.serialnumber,
                              callback: function($$v) {
                                _vm.$set(_vm.singlePageBg, "serialnumber", $$v)
                              },
                              expression: "singlePageBg.serialnumber"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }