import { Vue, Component, Ref } from 'vue-property-decorator';
import EditCategoryTable from 'components/Themes/CategoryTable/EditCategoryTable';
import Template from './template.vue';

@Component({
	components: {
		EditCategoryTable,
	},
})
export default class EditCategory extends Vue.extend(Template) {
	@Ref('editthemecategory')
	private readonly themecategory!: HTMLFormElement;

	private themeCategoryId = this.$route.params.id;

	protected updateThemecategoryProperties(): void {
		this.themecategory.editCategory();
	}
}
