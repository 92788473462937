var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _c("base-page-heading", {
        staticClass: "d-print-none",
        attrs: { title: "Photoframes", subtitle: "" },
        scopedSlots: _vm._u([
          {
            key: "extra",
            fn: function() {
              return [
                _c(
                  "b-button",
                  {
                    ref: "photoframeShow",
                    attrs: { type: "submit", size: "md", variant: "secondary" },
                    on: { click: _vm.showModal }
                  },
                  [
                    _c("i", { staticClass: "fa fa-fw fa-plus" }),
                    _vm._v(" \tCreate new\n\t\t\t")
                  ]
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row" },
        [
          _c("PhotoFrameTable", {
            ref: "updatePhotoframe",
            on: {
              routePhotoframe: _vm.routePhotoframe,
              openInNewTab: _vm.openInNewTab
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }