var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ml-5 mt-4", staticStyle: { width: "95%", height: "100%" } },
    [
      _c("loading-overlay", {
        attrs: { active: _vm.isLoading, "can-cancel": true }
      }),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "12", lg: "12", sm: "12" } },
            [
              _c(
                "base-block",
                { attrs: { title: "Photoframe properties", "header-bg": "" } },
                [
                  _vm.spinner
                    ? _c("div", { staticClass: "text-center" }, [
                        _c(
                          "div",
                          {
                            staticClass: "spinner-grow text-primary m-5",
                            attrs: { role: "status" }
                          },
                          [
                            _c("span", { staticClass: "sr-only" }, [
                              _vm._v("Loading...")
                            ])
                          ]
                        )
                      ])
                    : _c(
                        "div",
                        { staticClass: "d-block text-center" },
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "form-group",
                              attrs: {
                                label: "name",
                                "label-for": "block-form1-name",
                                "label-cols-lg": "4"
                              }
                            },
                            [
                              _c(
                                "b-input-group",
                                {
                                  scopedSlots: _vm._u([
                                    {
                                      key: "append",
                                      fn: function() {
                                        return [
                                          _c(
                                            "b-input-group-text",
                                            {
                                              staticClass:
                                                "input-group-text-alt",
                                              staticStyle: { cursor: "pointer" }
                                            },
                                            [
                                              _c("b-form-checkbox", {
                                                attrs: {
                                                  id: "checkbox-name-3",
                                                  name: "checkbox-1",
                                                  checked:
                                                    _vm.singlePhotoframe.name ==
                                                    null
                                                      ? true
                                                      : false,
                                                  "unchecked-value":
                                                    "not_accepted"
                                                },
                                                on: {
                                                  change: function($event) {
                                                    return _vm.setPropertyNull(
                                                      "name"
                                                    )
                                                  }
                                                }
                                              }),
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t"
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ])
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: { type: "text" },
                                    model: {
                                      value: _vm.singlePhotoframe.name,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.singlePhotoframe,
                                          "name",
                                          $$v
                                        )
                                      },
                                      expression: "singlePhotoframe.name"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "source",
                                "label-for": "thumbnail",
                                "label-cols-lg": "4"
                              }
                            },
                            [
                              _c("b-form-file", {
                                ref: "logodesktop",
                                attrs: {
                                  id: "thumbnail",
                                  placeholder: _vm.placeHolders.icon
                                },
                                nativeOn: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.updateIcon.apply(null, arguments)
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _vm.singlePhotoframe.source
                                ? _c("div", { staticClass: "thumbnail" }, [
                                    _c("img", {
                                      attrs: {
                                        src: _vm.singlePhotoframe.source,
                                        alt: "",
                                        srcset: "",
                                        height: "100",
                                        width: "100"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { cursor: "pointer" },
                                        on: { click: _vm.removeIcon }
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t✖ Remove\n\t\t\t\t\t\t\t"
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-form-group",
                            {
                              staticClass: "form-group",
                              attrs: {
                                label: "width",
                                "label-for": "block-form1-width",
                                "label-cols-lg": "4"
                              }
                            },
                            [
                              _c(
                                "b-input-group",
                                {
                                  scopedSlots: _vm._u([
                                    {
                                      key: "append",
                                      fn: function() {
                                        return [
                                          _c(
                                            "b-input-group-text",
                                            {
                                              staticClass:
                                                "input-group-text-alt",
                                              staticStyle: { cursor: "pointer" }
                                            },
                                            [
                                              _c("b-form-checkbox", {
                                                attrs: {
                                                  id: "checkbox-width-3",
                                                  name: "checkbox-1",
                                                  checked: _vm.isPropertyNull(
                                                    "width"
                                                  ),
                                                  "unchecked-value":
                                                    "not_accepted"
                                                },
                                                on: {
                                                  change: function($event) {
                                                    return _vm.setPropertyNull(
                                                      "width"
                                                    )
                                                  }
                                                }
                                              }),
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t"
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ])
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: { type: "text" },
                                    model: {
                                      value: _vm.singlePhotoframe.width,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.singlePhotoframe,
                                          "width",
                                          $$v
                                        )
                                      },
                                      expression: "singlePhotoframe.width"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-form-group",
                            {
                              staticClass: "form-group",
                              attrs: {
                                label: "height",
                                "label-for": "block-form1-height",
                                "label-cols-lg": "4"
                              }
                            },
                            [
                              _c(
                                "b-input-group",
                                {
                                  scopedSlots: _vm._u([
                                    {
                                      key: "append",
                                      fn: function() {
                                        return [
                                          _c(
                                            "b-input-group-text",
                                            {
                                              staticClass:
                                                "input-group-text-alt",
                                              staticStyle: { cursor: "pointer" }
                                            },
                                            [
                                              _c("b-form-checkbox", {
                                                attrs: {
                                                  id: "checkbox-height-3",
                                                  name: "checkbox-1",
                                                  checked: _vm.isPropertyNull(
                                                    "height"
                                                  ),
                                                  "unchecked-value":
                                                    "not_accepted"
                                                },
                                                on: {
                                                  change: function($event) {
                                                    return _vm.setPropertyNull(
                                                      "height"
                                                    )
                                                  }
                                                }
                                              }),
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t"
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ])
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: { type: "text" },
                                    model: {
                                      value: _vm.singlePhotoframe.height,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.singlePhotoframe,
                                          "height",
                                          $$v
                                        )
                                      },
                                      expression: "singlePhotoframe.height"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-form-group",
                            {
                              staticClass: "form-group",
                              attrs: {
                                label: "borderwidth_top",
                                "label-for": "block-form1-borderwidth_top",
                                "label-cols-lg": "4"
                              }
                            },
                            [
                              _c(
                                "b-input-group",
                                {
                                  scopedSlots: _vm._u([
                                    {
                                      key: "append",
                                      fn: function() {
                                        return [
                                          _c(
                                            "b-input-group-text",
                                            {
                                              staticClass:
                                                "input-group-text-alt",
                                              staticStyle: { cursor: "pointer" }
                                            },
                                            [
                                              _c("b-form-checkbox", {
                                                attrs: {
                                                  id:
                                                    "checkbox-borderwidth_top-3",
                                                  name: "checkbox-1",
                                                  checked: _vm.isPropertyNull(
                                                    "borderwidth_top"
                                                  ),
                                                  "unchecked-value":
                                                    "not_accepted"
                                                },
                                                on: {
                                                  change: function($event) {
                                                    return _vm.setPropertyNull(
                                                      "borderwidth_top"
                                                    )
                                                  }
                                                }
                                              }),
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t"
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ])
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: { type: "text" },
                                    model: {
                                      value:
                                        _vm.singlePhotoframe.borderwidth_top,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.singlePhotoframe,
                                          "borderwidth_top",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "singlePhotoframe.borderwidth_top"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-form-group",
                            {
                              staticClass: "form-group",
                              attrs: {
                                label: "borderwidth_right",
                                "label-for": "block-form1-borderwidth_right",
                                "label-cols-lg": "4"
                              }
                            },
                            [
                              _c(
                                "b-input-group",
                                {
                                  scopedSlots: _vm._u([
                                    {
                                      key: "append",
                                      fn: function() {
                                        return [
                                          _c(
                                            "b-input-group-text",
                                            {
                                              staticClass:
                                                "input-group-text-alt",
                                              staticStyle: { cursor: "pointer" }
                                            },
                                            [
                                              _c("b-form-checkbox", {
                                                attrs: {
                                                  id:
                                                    "checkbox-borderwidth_right-3",
                                                  name: "checkbox-1",
                                                  checked: _vm.isPropertyNull(
                                                    "borderwidth_right"
                                                  ),
                                                  "unchecked-value":
                                                    "not_accepted"
                                                },
                                                on: {
                                                  change: function($event) {
                                                    return _vm.setPropertyNull(
                                                      "borderwidth_right"
                                                    )
                                                  }
                                                }
                                              }),
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t"
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ])
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: { type: "text" },
                                    model: {
                                      value:
                                        _vm.singlePhotoframe.borderwidth_right,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.singlePhotoframe,
                                          "borderwidth_right",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "singlePhotoframe.borderwidth_right"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-form-group",
                            {
                              staticClass: "form-group",
                              attrs: {
                                label: "borderwidth_bottom",
                                "label-for": "block-form1-borderwidth_bottom",
                                "label-cols-lg": "4"
                              }
                            },
                            [
                              _c(
                                "b-input-group",
                                {
                                  scopedSlots: _vm._u([
                                    {
                                      key: "append",
                                      fn: function() {
                                        return [
                                          _c(
                                            "b-input-group-text",
                                            {
                                              staticClass:
                                                "input-group-text-alt",
                                              staticStyle: { cursor: "pointer" }
                                            },
                                            [
                                              _c("b-form-checkbox", {
                                                attrs: {
                                                  id:
                                                    "checkbox-borderwidth_bottom-3",
                                                  name: "checkbox-1",
                                                  checked: _vm.isPropertyNull(
                                                    "borderwidth_bottom"
                                                  ),
                                                  "unchecked-value":
                                                    "not_accepted"
                                                },
                                                on: {
                                                  change: function($event) {
                                                    return _vm.setPropertyNull(
                                                      "borderwidth_bottom"
                                                    )
                                                  }
                                                }
                                              }),
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t"
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ])
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: { type: "text" },
                                    model: {
                                      value:
                                        _vm.singlePhotoframe.borderwidth_bottom,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.singlePhotoframe,
                                          "borderwidth_bottom",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "singlePhotoframe.borderwidth_bottom"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-form-group",
                            {
                              staticClass: "form-group",
                              attrs: {
                                label: "borderwidth_left",
                                "label-for": "block-form1-borderwidth_left",
                                "label-cols-lg": "4"
                              }
                            },
                            [
                              _c(
                                "b-input-group",
                                {
                                  scopedSlots: _vm._u([
                                    {
                                      key: "append",
                                      fn: function() {
                                        return [
                                          _c(
                                            "b-input-group-text",
                                            {
                                              staticClass:
                                                "input-group-text-alt",
                                              staticStyle: { cursor: "pointer" }
                                            },
                                            [
                                              _c("b-form-checkbox", {
                                                attrs: {
                                                  id:
                                                    "checkbox-borderwidth_left-3",
                                                  name: "checkbox-1",
                                                  checked: _vm.isPropertyNull(
                                                    "borderwidth_left"
                                                  ),
                                                  "unchecked-value":
                                                    "not_accepted"
                                                },
                                                on: {
                                                  change: function($event) {
                                                    return _vm.setPropertyNull(
                                                      "borderwidth_left"
                                                    )
                                                  }
                                                }
                                              }),
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t"
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ])
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: { type: "text" },
                                    model: {
                                      value:
                                        _vm.singlePhotoframe.borderwidth_left,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.singlePhotoframe,
                                          "borderwidth_left",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "singlePhotoframe.borderwidth_left"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-form-group",
                            {
                              staticClass: "form-group",
                              attrs: {
                                label: "scaling",
                                "label-for": "block-form1-scaling",
                                "label-cols-lg": "4"
                              }
                            },
                            [
                              _c(
                                "b-input-group",
                                {
                                  scopedSlots: _vm._u([
                                    {
                                      key: "append",
                                      fn: function() {
                                        return [
                                          _c(
                                            "b-input-group-text",
                                            {
                                              staticClass:
                                                "input-group-text-alt",
                                              staticStyle: { cursor: "pointer" }
                                            },
                                            [
                                              _c("b-form-checkbox", {
                                                attrs: {
                                                  id: "checkbox-scaling-3",
                                                  name: "checkbox-1",
                                                  checked: _vm.isPropertyNull(
                                                    "scaling"
                                                  ),
                                                  "unchecked-value":
                                                    "not_accepted"
                                                },
                                                on: {
                                                  change: function($event) {
                                                    return _vm.setPropertyNull(
                                                      "scaling"
                                                    )
                                                  }
                                                }
                                              }),
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t"
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ])
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: { type: "text" },
                                    model: {
                                      value: _vm.singlePhotoframe.scaling,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.singlePhotoframe,
                                          "scaling",
                                          $$v
                                        )
                                      },
                                      expression: "singlePhotoframe.scaling"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "fixedratio",
                                "label-class": "font-w600",
                                "label-cols-lg": "4"
                              }
                            },
                            [
                              _c("b-form-checkbox", {
                                attrs: {
                                  switch: "",
                                  size: "lg",
                                  checked:
                                    _vm.singlePhotoframe.fixedratio === 1
                                      ? true
                                      : false
                                },
                                on: {
                                  change: function($event) {
                                    _vm.singlePhotoframe.fixedratio = $event
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }