var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.tag,
    {
      tag: "component",
      class: _vm.classContainer,
      attrs: {
        type: _vm.tag === "button" ? "button" : false,
        href: _vm.tag === "a" ? "#" : false
      },
      on: {
        click: function($event) {
          $event.preventDefault()
          return _vm.layout(_vm.action)
        }
      }
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }