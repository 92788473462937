import { Vue, Component, Ref } from 'vue-property-decorator';
import EditTemplateTable from 'components/Themes/TemplateTable/EditTemplateTable';
import Template from './template.vue';

@Component({
	components: {
		EditTemplateTable,
	},
})
export default class EditTemplate extends Vue.extend(Template) {
	@Ref('edittemplate')
	private readonly template!: HTMLFormElement;

	private templateId = this.$route.params.id

	protected updateTemplateProperties(): void {
		this.template.editTemplate();
	}

	protected routeTemplateEdit(
		data: {
			id: string,
			templateid: string
		},
	): void {
		this.$router.push(`/template/${data.id}/state/${data.templateid}`);
	}
}
