var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-tr",
    [
      _c("loading-overlay", {
        attrs: { active: _vm.isLoading, "can-cancel": true }
      }),
      _vm._v(" "),
      _c("b-td", { staticClass: "font-w600 font-size-sm" }, [
        _c("a", [_vm._v("\n\t\t\t" + _vm._s(_vm.reg.name) + "\n\t\t")])
      ]),
      _vm._v(" "),
      _c(
        "b-td",
        { staticClass: "d-none d-sm-table-cell" },
        [
          _c(
            "b-form-group",
            [
              _c("b-form-checkbox", {
                ref: "inp",
                attrs: { switch: "", size: "lg" },
                on: {
                  change: function($event) {
                    return _vm.editRegions(_vm.reg)
                  }
                },
                model: {
                  value: _vm.selected,
                  callback: function($$v) {
                    _vm.selected = $$v
                  },
                  expression: "selected"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-td",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isActive,
              expression: "isActive"
            }
          ],
          staticClass: "text-center"
        },
        [
          _c(
            "b-form-group",
            { attrs: { "label-for": "example-text-input" } },
            [
              _c("b-form-input", {
                attrs: {
                  value: _vm.data[_vm.reg.id] ? _vm.data[_vm.reg.id].vat : null
                },
                on: {
                  input: function($event) {
                    _vm.data[_vm.reg.id].vat = $event
                  }
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }