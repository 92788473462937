import { Vue, Component, Prop } from 'vue-property-decorator';
import Template from './template.vue';

@Component({
	filters: {
		convertUnixDate: (value: string) => new Date(value).toLocaleString('en-US'),
	},
})
export default class PhotoDate extends Vue.extend(Template) {
	@Prop({
		type: Number,
		required: true,
	})
	public readonly photodate!: number;
}
