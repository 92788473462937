var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "mt-2 mb-2" },
    [
      _c("img", {
        attrs: {
          src: _vm.value,
          height: _vm.params.size,
          width: _vm.params.size
        }
      }),
      _vm._v(" "),
      _vm.params.edit
        ? _c(
            "b-button",
            { staticClass: "mt-3", on: { click: _vm.editImage } },
            [_c("i", { staticClass: "fa fa-file-upload" })]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }