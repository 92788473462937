import { ICellRendererParams } from 'ag-grid-community';
import { Vue, Component } from 'vue-property-decorator';

@Component({})
export default class CreditHistory extends Vue {
	private params!: ICellRendererParams;

	value = 0;

	beforeMount(): void {
		this.update();
	}

	update(): void {
		this.value = this.params.value;
	}

	showCreditHistory(): void {
		this.params.context.componentParent.showCreditHistory(this.params,
			this.value);
	}
}
