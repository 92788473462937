import {
	Vue, Component, Watch, Prop, Ref,
} from 'vue-property-decorator';
import * as DB from 'interfaces/database';
import mitt from 'mitt';
import VatInput from 'components/Tabulator/VatInput';
import { httpClient } from 'utils/http';
import { TabEvent } from 'interfaces/app';
import { CellComponentExtended, ColumnDefinitionExtended, TabulatorFull as Tabulator } from 'tabulator-tables';
import PriceDetails from 'components/Tabulator/PriceDetails';
import { createInstance } from 'utils/vue';
import TabulatorSwitchBox from 'components/Tabulator/TabulatorSwitchBox';
import Template from './template.vue';

const eventBus = mitt<TabEvent<DB.OfferingModel>>();
@Component({
	components: {
		TabulatorSwitchBox,
		VatInput,
	},
})
export default class OfferingTable extends Vue.extend(Template) {
	@Ref('offeringTable')
	private readonly tableReference!: HTMLDivElement;

	private loaded = false;

	private checkOffering = {} as Record<string, DB.RegionOfferingModel>;

	private columnDefs: ColumnDefinitionExtended[] = [];

	private rowData: DB.OfferingModel[] = [];

	private table?: Tabulator;

	@Prop({
		type: String,
		required: true,
	})
	private readonly routeId!: string;

	private get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	protected beforeMount(): void {
		this.columnDefs = [
			{
				field: 'id',
				title: 'Offering ID',
				headerFilter: true,
			},
			{
				title: 'External ID',
				field: 'externalid',
				headerFilter: true,
			},
			{
				title: 'Name',
				field: 'externalid',
				formatter(cell: CellComponentExtended<DB.OfferingModel>) {
					const instance = createInstance({
						component: PriceDetails,
						props: {
							data: cell.getData(),
						},
					});
					instance.$mount();

					return (instance.$el as HTMLElement);
				},
				headerFilter: 'input',
				headerFilterPlaceholder: 'Filter...',
			},
			{
				title: 'Available In region?',
				formatter: (cell: CellComponentExtended<DB.OfferingModel>) => {
					const data = cell.getData();
					const instance = createInstance({
						component: TabulatorSwitchBox,
						props: {
							data,
							eventBus,
							checked: Boolean(data && this.checkOffering[data.id]),
						},
					});
					instance.$mount();

					return (instance.$el as HTMLElement);
				},
			},
			{
				title: 'Vat',
				formatter: (cell: CellComponentExtended<DB.OfferingModel>) => {
					const data = cell.getData();
					const instance = createInstance({
						component: VatInput,
						props: {
							data,
							eventBus,
							checkOffering: this.checkOffering,
						},
					});
					instance.$mount();

					return (instance.$el as HTMLElement);
				},
			},
		];
	}

	private switchBoxChanged(data: TabEvent<DB.OfferingModel>['switchBoxChanged']): void {
		this.table?.alert('Loading...');
		if (data.event) {
			httpClient
				.post('/api/regionoffering',
					{
						offeringid: data.params.id,
						regionid: parseInt(this.routeId,
							10),
						groupid: data.params.groupid,
						typeid: data.params.typeid,
					})
				.then((response) => {
					this.checkOffering[response.data.offeringid] = response.data;
					return undefined;
				})
				.finally(() => {
					this.table?.clearAlert();
				}).catch((err) => {
					this.$bvToast.toast(`${err.message}`,
						{
							solid: true,
							variant: 'danger',
						});
				});
		} else {
			httpClient
				.delete(`/api/regionoffering/${this.checkOffering[data.params.id].id}`)
				.then(() => {
					delete this.checkOffering[data.params.id];
					return undefined;
				})
				.finally(() => {
					this.table?.clearAlert();
				}).catch((err) => {
					this.$bvToast.toast(`${err.message}`,
						{
							solid: true,
							variant: 'danger',
						});
				});
		}
	}

	protected mounted(): void {
		this.getRegionOffering();
		eventBus.on('switchBoxChanged',
			this.switchBoxChanged);
		this.tableInitialization();
		this.table?.on('tableBuilt',
			this.onTableBuilt);
	}

	protected beforeDestroy(): void {
		eventBus.off('switchBoxChanged',
			this.switchBoxChanged);
		this.table?.off('tableBuilt',
			this.onTableBuilt);
		this.table?.destroy();
	}

	private onTableBuilt(): void {
		this.getData();
	}

	private tableInitialization(): void {
		this.table = new Tabulator(this.tableReference,
			{
				height: '60vh',
				layout: 'fitColumns',
				columns: this.columnDefs,
			});
	}

	private async getData(): Promise<void> {
		const parameter = new URLSearchParams({
			limit: '0',
		});
		this.table?.alert('Loading');
		try {
			const { data } = await httpClient.get<DB.OfferingModel[]>(`/api/offering?${parameter}`);
			this.table?.setData(data);
		} catch (err: any) {
			this.$bvToast.toast(`${err.message}`,
				{
					solid: true,
					variant: 'danger',
				});
		} finally {
			this.table?.clearAlert();
		}
	}

	@Watch('loggedIn')
	private async getRegionOffering(): Promise<void> {
		if (this.loggedIn) {
			const params = new URLSearchParams({
				where: JSON.stringify({ regionid: this.routeId }),
				limit: '0',
			});

			try {
				const resp = await httpClient.get<DB.RegionOfferingModel[]>(`/api/regionoffering?${params}`);
				this.checkOffering = resp.data.reduce((obj, item) => ({
					...obj,
					[item.offeringid]: item,
				}),
				{});
			} catch (error: any) {
				this.$bvToast.toast(`${error.message}`,
					{
						solid: true,
						variant: 'danger',
					});
			}
		}
		return undefined;
	}
}
