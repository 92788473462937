import { Vue, Component, Ref } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { getModule } from 'vuex-module-decorators';
import PricingCurrencies from 'components/Globals/PricingCurrencies.vue';
import PriceInput from 'components/Tabulator/PriceInput/';
import ImageComponent from 'components/Globals/ImageComponent.vue';
import PriceDetails from 'components/Tabulator/PriceDetails/';
import { AgGridVue } from 'ag-grid-vue';
import { httpClient } from 'utils/http';
import * as DB from 'interfaces/database';
import { CellComponent, ColumnDefinition, TabulatorFull as Tabulator } from 'tabulator-tables';
import OffTable from '../../store/offerings/offeringTable';
import Reg from '../../store/offerings/region';

const OfferingTable = namespace('OfferingTable');

interface OfferingWithGroupName extends DB.OfferingModel {
	groupname: string;
}

@Component({
	components: {
		ImageComponent,
		AgGridVue,
		PricingCurrencies,
		PriceInput,
		PriceDetails,
	},
})

export default class PricingTable extends Vue {
	@Ref('Alltable')
	private allTableReference!: HTMLDivElement;

	@Ref('Discountedtable')
	private DiscountedTableReference!: HTMLDivElement;

	@Ref('Cataloguetable')
	private CatalogueTableReference!: HTMLDivElement;

	private searchFilters = {
		search0: '',
	};

	private table?: Tabulator;

	private rowData: DB.OfferingModel[] | OfferingWithGroupName[] = [];

	private discountedTable?: Tabulator;

	private catalogueTable?: Tabulator;

	private indexes = 0;

	private columnDefs: ColumnDefinition[] = [];

	private tabIndex = 0;

	@OfferingTable.Getter
	private getGroupName!: Array<DB.ProductGroupModel>;

	@OfferingTable.Getter
	private getJustGroupName!: Array<string>;

	private tableData = [];

	private created(): void {
		getModule(OffTable,
			this.$store);
		getModule(Reg,
			this.$store);
		this.$nextTick(() => {
			this.$store.dispatch('Regions/getCurrency');
		});
	}

	private mounted(): void {
		this.table = new Tabulator(this.allTableReference,
			{
				height: '60vh',
				layout: 'fitColumns',
				columns: this.columnDefs,
			});
		this.discountedTable = new Tabulator(this.DiscountedTableReference,
			{
				height: '60vh',
				layout: 'fitColumns',
				columns: this.columnDefs,
			});
		this.catalogueTable = new Tabulator(this.CatalogueTableReference,
			{
				height: '60vh',
				layout: 'fitColumns',
				columns: this.columnDefs,
			});
		this.getData();
	}

	protected discountedFilterFunction() {
		const filteredData = this.rowData.filter((i: DB.OfferingModel) => i?.currencies?.some((curr: DB.PricingModel) => (curr.price_base !== null || curr.price_page !== null) && (curr.price_base_from !== curr.price_base && curr.price_page_from !== curr.price_page)));
		this.discountedTable?.setData(filteredData);
		return true;
	}

	protected catalogueFilterFunction() {
		const filteredData = this.rowData.filter((i: DB.OfferingModel) => i?.currencies?.some((curr: DB.PricingModel) => curr.price_base_from === null && curr.price_page_from === null));
		this.catalogueTable?.setData(filteredData);
		return true;
	}

	private async getData(): Promise<void> {
		await this.$store.dispatch('OfferingTable/getGroupNameAction');
		this.table?.alert('Loading');
		try {
			const parameter = new URLSearchParams({
				limit: '0',
			});
			const [offeringResponse, pricingResponse] = await Promise.all([
				httpClient.get<DB.OfferingModel[]>(`/api/offering?${parameter}`),
				httpClient.get<DB.PricingModel[]>(`/api/price?${parameter}`),
			]);
			this.rowData = offeringResponse.data.map((item) => ({
				...item,
				currencies: pricingResponse.data.filter((curr) => curr.offeringid === item.id),
				groupname: (this.getGroupName.find((j) => j.id === item.groupid))?.name,
			}));
			this.table?.setData(this.rowData);
		} catch (error: any) {
			this.$bvToast.toast(`${error.message}`,
				{
					solid: true,
					variant: 'danger',
				});
		} finally {
			this.table?.clearAlert();
		}
	}

	// Destroy the table when the component is destroyed
	private beforeDestroy() {
		this?.table?.destroy();
	}

	// eslint-disable-next-line class-methods-use-this
	private customHeaderFilter(headerValue: string, rowValue: DB.PricingModel[]) {
		// headerValue - the value of the header filter element
		// rowValue - the value of the column in this row
		return rowValue.some((i) => i.currency.toLowerCase() === headerValue.toLowerCase());
	}

	private beforeMount(): void {
		this.columnDefs = [
			{
				field: 'id',
				title: 'ID',
				headerFilter: 'input',
				headerFilterPlaceholder: 'Filter...',
			},
			{
				title: 'Thumbnail',
				field: 'thumbnail',
				formatter: (cell: CellComponent) => `<img src="${cell.getValue()}" style="width: 100px; height: auto; object-fit: cover;" />`,
			},
			{
				title: 'Info',
				field: 'externalid',
				formatter(cell: CellComponent) {
					const instance = new PriceDetails({
						propsData: {
							data: cell.getData(),
						},
					});
					instance.$mount();

					return (instance.$el as HTMLElement);
				},
				headerFilter: 'input',
				headerFilterPlaceholder: 'Filter...',
			},
			{
				title: 'Currency',
				field: 'currencies',
				formatter(cell: CellComponent) {
					const currencies = cell.getValue();

					const wrapperDiv = document.createElement('div');
					wrapperDiv.className = 'container';

					currencies.forEach((i: DB.PricingModel) => {
						const currencyDiv = document.createElement('div');
						currencyDiv.className = 'pl-6 mt-4';
						currencyDiv.textContent = i.currency;

						wrapperDiv.appendChild(currencyDiv);
					});

					return wrapperDiv;
				},
				sorter(a: DB.PricingModel[], b: DB.PricingModel[]) {
					const aCurrencies = a.map((i: DB.PricingModel) => i.currency);
					const bCurrencies = b.map((i: DB.PricingModel) => i.currency);
					return aCurrencies.join(', ') > bCurrencies.join(', ') ? 1 : -1;
				},
				headerFilter: 'input',
				headerFilterFunc: this.customHeaderFilter,
			},
			{
				title: 'Catalogue Price',
				columns: [
					{
						title: 'Base Price',
						field: 'currencies',
						formatter(cell: CellComponent) {
							const instance = new PriceInput({
								propsData: {
									data: cell.getData(),
									type: 'catalogue_base_price',
								},
							});
							instance.$mount();

							return (instance.$el as HTMLElement);
						},
					},
					{
						title: 'Extra Pages',
						field: 'currencies',
						formatter(cell: CellComponent) {
							const instance = new PriceInput({
								propsData: {
									data: cell.getData(),
									type: 'catalogue_extra_pages',
								},
							});
							instance.$mount();

							return (instance.$el as HTMLElement);
						},
					},
				],
			},
			{
				title: 'Discounted Price',
				resizable: false,
				columns: [
					{
						title: 'Base Price',
						field: 'currencies',
						formatter(cell: CellComponent) {
							const instance = new PriceInput({
								propsData: {
									data: cell.getData(),
									type: 'discounted_base_price',
								},
							});
							instance.$mount();

							return (instance.$el as HTMLElement);
						},
					},
					{
						title: 'Extra Pages',
						field: 'currencies',
						formatter(cell: CellComponent) {
							const instance = new PriceInput({
								propsData: {
									data: cell.getData(),
									type: 'discounted_extra_pages',
								},
							});
							instance.$mount();

							return (instance.$el as HTMLElement);
						},
					},
				],
			},
		];
	}

	private get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	protected externalAllFilterChanged(search: string): void {
		const Allfiltered = (this.rowData as OfferingWithGroupName[]).filter((data: OfferingWithGroupName) => {
			if (data.groupname !== undefined) {
				return data.groupname.toLowerCase().indexOf(search.toLowerCase()) > -1;
			}
			return data;
		});
		this.table?.setData(Allfiltered);
	}

	protected externalDiscountedFilterChanged(): void {
		const Allfiltered = (this.rowData as OfferingWithGroupName[]).filter((data: OfferingWithGroupName) => data?.groupname.toLowerCase().includes(this.searchFilters.search0));
		this.discountedTable?.setData(Allfiltered);
	}

	protected externalMissingFilterChanged(): void {
		const Allfiltered = (this.rowData as OfferingWithGroupName[]).filter((data: OfferingWithGroupName) => data?.groupname.toLowerCase().includes(this.searchFilters.search0));
		this.catalogueTable?.setData(Allfiltered);
	}
}
