var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "ml-5", staticStyle: { width: "95%" } },
    [
      _c("loading-overlay", {
        attrs: { active: _vm.isLoading, "can-cancel": true }
      }),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "6", lg: "6", sm: "12" } },
            [
              _c(
                "base-block",
                { attrs: { title: "2D Model properties", "header-bg": "" } },
                [
                  _c(
                    "div",
                    { staticClass: "d-block text-center" },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "form-group",
                          attrs: {
                            label: "Name: ",
                            "label-for": "block-form1-name",
                            "label-cols-lg": "4"
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "control" },
                            [
                              _c("b-form-input", {
                                attrs: { type: "text" },
                                model: {
                                  value: _vm.singleModel2d.name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.singleModel2d, "name", $$v)
                                  },
                                  expression: "singleModel2d.name"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        { attrs: { label: "Image Url", "label-cols-lg": "4" } },
                        [
                          _c("ValidationProvider", {
                            attrs: { rules: "image", name: "Image Url" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function({ classes, errors }) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "control",
                                        class: classes
                                      },
                                      [
                                        _c("b-form-file", {
                                          ref: "imageUrl",
                                          attrs: {
                                            id: "imageUrl",
                                            placeholder:
                                              _vm.placeHolders
                                                .thumbnailPlaceholder
                                          },
                                          nativeOn: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              return _vm.updateImagePreview.apply(
                                                null,
                                                arguments
                                              )
                                            }
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("span", [_vm._v(_vm._s(errors[0]))])
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          }),
                          _vm._v(" "),
                          _vm.imageUrl
                            ? _c("div", { staticClass: "thumbnail" }, [
                                _c("img", {
                                  attrs: {
                                    src: _vm.singleModel2d.imageUrl,
                                    alt: "",
                                    height: "100",
                                    width: "100"
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    on: { click: _vm.removeThumbnail }
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t✖ Remove\n\t\t\t\t\t\t\t"
                                    )
                                  ]
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "form-group",
                          attrs: {
                            label: "Image Width ",
                            "label-for": "block-form1-name",
                            "label-cols-lg": "4"
                          }
                        },
                        [
                          _c("ValidationProvider", {
                            attrs: { rules: "integer", name: "Image Width" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function({ classes, errors }) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "control",
                                        class: classes
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: { type: "text" },
                                          model: {
                                            value: _vm.singleModel2d.imageWidth,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.singleModel2d,
                                                "imageWidth",
                                                _vm._n($$v)
                                              )
                                            },
                                            expression:
                                              "singleModel2d.imageWidth"
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("span", [_vm._v(_vm._s(errors[0]))])
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "form-group",
                          attrs: {
                            label: "Image Height",
                            "label-for": "block-form1-name",
                            "label-cols-lg": "4"
                          }
                        },
                        [
                          _c("ValidationProvider", {
                            attrs: { rules: "integer", name: "Image Height" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function({ classes, errors }) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "control",
                                        class: classes
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: { type: "text" },
                                          model: {
                                            value:
                                              _vm.singleModel2d.imageHeight,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.singleModel2d,
                                                "imageHeight",
                                                _vm._n($$v)
                                              )
                                            },
                                            expression:
                                              "singleModel2d.imageHeight"
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("span", [_vm._v(_vm._s(errors[0]))])
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "form-group",
                          attrs: {
                            label: "Frame Left",
                            "label-for": "block-form1-name",
                            "label-cols-lg": "4"
                          }
                        },
                        [
                          _c("ValidationProvider", {
                            attrs: { rules: "integer", name: "Frame Left" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function({ classes, errors }) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "control",
                                        class: classes
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: { type: "text" },
                                          model: {
                                            value: _vm.singleModel2d.frameLeft,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.singleModel2d,
                                                "frameLeft",
                                                _vm._n($$v)
                                              )
                                            },
                                            expression:
                                              "singleModel2d.frameLeft"
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("span", [_vm._v(_vm._s(errors[0]))])
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "form-group",
                          attrs: {
                            label: "Frame Top",
                            "label-for": "block-form1-name",
                            "label-cols-lg": "4"
                          }
                        },
                        [
                          _c("ValidationProvider", {
                            attrs: { rules: "integer", name: "Frame Top" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function({ classes, errors }) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "control",
                                        class: classes
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: { type: "text" },
                                          model: {
                                            value: _vm.singleModel2d.frameTop,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.singleModel2d,
                                                "frameTop",
                                                _vm._n($$v)
                                              )
                                            },
                                            expression: "singleModel2d.frameTop"
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("span", [_vm._v(_vm._s(errors[0]))])
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Frame Width",
                            "label-for": "example-select",
                            "label-cols-lg": "4"
                          }
                        },
                        [
                          _c("ValidationProvider", {
                            attrs: { rules: "integer", name: "Frame Width" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function({ classes, errors }) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "control",
                                        class: classes
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: { type: "text" },
                                          model: {
                                            value: _vm.singleModel2d.frameWidth,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.singleModel2d,
                                                "frameWidth",
                                                _vm._n($$v)
                                              )
                                            },
                                            expression:
                                              "singleModel2d.frameWidth"
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("span", [_vm._v(_vm._s(errors[0]))])
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "form-group",
                          attrs: {
                            label: "Frame Height",
                            "label-for": "block-form1-name",
                            "label-cols-lg": "4"
                          }
                        },
                        [
                          _c("ValidationProvider", {
                            attrs: { rules: "integer", name: "Frame Height" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function({ classes, errors }) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "control",
                                        class: classes
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: { type: "text" },
                                          model: {
                                            value:
                                              _vm.singleModel2d.frameHeight,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.singleModel2d,
                                                "frameHeight",
                                                _vm._n($$v)
                                              )
                                            },
                                            expression:
                                              "singleModel2d.frameHeight"
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("span", [_vm._v(_vm._s(errors[0]))])
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "form-group",
                          attrs: {
                            label: "Center Horizontal",
                            "label-for": "block-form1-name",
                            "label-cols-lg": "4"
                          }
                        },
                        [
                          _c("ValidationProvider", {
                            attrs: {
                              rules: "integer",
                              name: "Center Horizontal"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function({ classes, errors }) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "control",
                                        class: classes
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: { type: "text" },
                                          model: {
                                            value:
                                              _vm.singleModel2d
                                                .centerHorizontal,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.singleModel2d,
                                                "centerHorizontal",
                                                _vm._n($$v)
                                              )
                                            },
                                            expression:
                                              "singleModel2d.centerHorizontal"
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("span", [_vm._v(_vm._s(errors[0]))])
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "form-group",
                          attrs: {
                            label: "Center Vertical",
                            "label-for": "block-form1-name",
                            "label-cols-lg": "4"
                          }
                        },
                        [
                          _c("ValidationProvider", {
                            attrs: {
                              rules: "integer",
                              name: "Center Vertical"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function({ classes, errors }) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "control",
                                        class: classes
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: { type: "text" },
                                          model: {
                                            value:
                                              _vm.singleModel2d.centerVertical,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.singleModel2d,
                                                "centerVertical",
                                                _vm._n($$v)
                                              )
                                            },
                                            expression:
                                              "singleModel2d.centerVertical"
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("span", [_vm._v(_vm._s(errors[0]))])
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "form-group",
                          attrs: {
                            label: "PPI",
                            "label-for": "block-form1-name",
                            "label-cols-lg": "4"
                          }
                        },
                        [
                          _c("ValidationProvider", {
                            attrs: { rules: "integer", name: "PPI" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function({ classes, errors }) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "control",
                                        class: classes
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: { type: "text" },
                                          model: {
                                            value: _vm.singleModel2d.ppi,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.singleModel2d,
                                                "ppi",
                                                _vm._n($$v)
                                              )
                                            },
                                            expression: "singleModel2d.ppi"
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("span", [_vm._v(_vm._s(errors[0]))])
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Stack On Top?",
                            "label-class": "font-w600",
                            "label-cols-lg": "3"
                          }
                        },
                        [
                          _c("b-form-checkbox", {
                            staticClass: "cameraCheckbox",
                            attrs: {
                              switch: "",
                              size: "lg",
                              checked:
                                _vm.singleModel2d.stackOnTop === 1
                                  ? true
                                  : false
                            },
                            on: {
                              change: function($event) {
                                _vm.singleModel2d.stackOnTop = $event
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }