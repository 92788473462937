var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    { attrs: { id: "sidebar", "aria-label": "Main Navigation" } },
    [
      _vm._t("default", function() {
        return [
          _c(
            "div",
            { staticClass: "content-header bg-white-5" },
            [
              _c(
                "router-link",
                { staticClass: "font-w600 text-dual", attrs: { to: "/" } },
                [
                  _c("span", { staticClass: "smini-visible" }, [
                    _c("i", { staticClass: "fa fa-circle-notch text-primary" })
                  ]),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "smini-hide font-size-h5 tracking-wider py-2"
                    },
                    [
                      _vm._v("\n\t\t\t\t\t\tPRINTIKI"),
                      _c("span", { staticClass: "font-w400" })
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "b-dropdown",
                    {
                      ref: "oneDropdownOptions",
                      staticClass: "d-inline-block ml-2",
                      attrs: {
                        size: "sm",
                        variant: "dual",
                        "menu-class": "font-size-sm smini-hide border-0",
                        right: "",
                        "no-caret": ""
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "button-content",
                          fn: function() {
                            return [_c("i", { staticClass: "si si-drop" })]
                          },
                          proxy: true
                        }
                      ])
                    },
                    [
                      _vm._v(" "),
                      _c(
                        "li",
                        {
                          on: {
                            click: function($event) {
                              return _vm.$refs.oneDropdownOptions.hide(true)
                            }
                          }
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass:
                                "dropdown-item font-w500 d-flex align-items-center justify-content-between",
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.$store.commit("setColorTheme", {
                                    theme: ""
                                  })
                                }
                              }
                            },
                            [
                              _c("span", [_vm._v("Default")]),
                              _vm._v(" "),
                              _c("i", {
                                staticClass: "fa fa-circle text-default"
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass:
                                "dropdown-item font-w500 d-flex align-items-center justify-content-between",
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.$store.commit("setColorTheme", {
                                    theme: "amethyst"
                                  })
                                }
                              }
                            },
                            [
                              _c("span", [_vm._v("Amethyst")]),
                              _vm._v(" "),
                              _c("i", {
                                staticClass: "fa fa-circle text-amethyst"
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass:
                                "dropdown-item font-w500 d-flex align-items-center justify-content-between",
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.$store.commit("setColorTheme", {
                                    theme: "city"
                                  })
                                }
                              }
                            },
                            [
                              _c("span", [_vm._v("City")]),
                              _vm._v(" "),
                              _c("i", { staticClass: "fa fa-circle text-city" })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass:
                                "dropdown-item font-w500 d-flex align-items-center justify-content-between",
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.$store.commit("setColorTheme", {
                                    theme: "flat"
                                  })
                                }
                              }
                            },
                            [
                              _c("span", [_vm._v("Flat")]),
                              _vm._v(" "),
                              _c("i", { staticClass: "fa fa-circle text-flat" })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass:
                                "dropdown-item font-w500 d-flex align-items-center justify-content-between",
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.$store.commit("setColorTheme", {
                                    theme: "modern"
                                  })
                                }
                              }
                            },
                            [
                              _c("span", [_vm._v("Modern")]),
                              _vm._v(" "),
                              _c("i", {
                                staticClass: "fa fa-circle text-modern"
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass:
                                "dropdown-item font-w500 d-flex align-items-center justify-content-between",
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.$store.commit("setColorTheme", {
                                    theme: "smooth"
                                  })
                                }
                              }
                            },
                            [
                              _c("span", [_vm._v("Smooth")]),
                              _vm._v(" "),
                              _c("i", {
                                staticClass: "fa fa-circle text-smooth"
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", {
                            staticClass: "dropdown-divider",
                            attrs: { role: "separator" }
                          }),
                          _vm._v(" "),
                          _c(
                            "base-layout-modifier",
                            {
                              staticClass: "dropdown-item font-w500",
                              attrs: { tag: "a", action: "sidebarStyleLight" }
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\tSidebar Light\n\t\t\t\t\t\t\t"
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "base-layout-modifier",
                            {
                              staticClass: "dropdown-item font-w500",
                              attrs: { tag: "a", action: "sidebarStyleDark" }
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\tSidebar Dark\n\t\t\t\t\t\t\t"
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", {
                            staticClass: "dropdown-divider",
                            attrs: { role: "separator" }
                          }),
                          _vm._v(" "),
                          _c(
                            "base-layout-modifier",
                            {
                              staticClass: "dropdown-item font-w500",
                              attrs: { tag: "a", action: "headerStyleLight" }
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\tHeader Light\n\t\t\t\t\t\t\t"
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "base-layout-modifier",
                            {
                              staticClass: "dropdown-item font-w500",
                              attrs: { tag: "a", action: "headerStyleDark" }
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\tHeader Dark\n\t\t\t\t\t\t\t"
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "base-layout-modifier",
                    {
                      staticClass: "d-lg-none ml-1",
                      attrs: {
                        size: "sm",
                        variant: "dual",
                        action: "sidebarClose"
                      }
                    },
                    [_c("i", { staticClass: "fa fa-fw fa-times" })]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("simplebar", { staticClass: "js-sidebar-scroll" }, [
            _c(
              "div",
              { staticClass: "content-side" },
              [_c("base-navigation", { attrs: { nodes: _vm.navigation } })],
              1
            )
          ])
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }