import { Vue, Component, Ref } from 'vue-property-decorator';
import EditPickupStoresTable from 'components/PickupStoresTable/EditPickupStoresTable';
import Template from './template.vue';

@Component({
	components: {
		EditPickupStoresTable,
	},
})
export default class EditPickupStores extends Vue.extend(Template) {
	@Ref('editpickupstores')
	private readonly pickupStoresModal!: HTMLFormElement;

	private routeId = this.$route.params.id;

	protected updatePickupStoresProperties(): void {
		this.pickupStoresModal.editPickupStores();
	}
}
