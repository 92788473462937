import { Vue, Component } from 'vue-property-decorator';
import CategoryTable from 'components/Themes/CategoryTable';
import Template from './template.vue';

@Component({
	components: {
		CategoryTable,
	},
})
export default class Category extends Vue.extend(Template) {
	protected showModal(): void {
		this.$root.$emit('bv::show::modal',
			'category-modal',
			'#categoryShow');
	}

	protected showImport(): void {
		this.$root.$emit('bv::show::modal',
			'import-modal',
			'#importShow');
	}

	protected routeCategory(id: string): void {
		this.$router.push(`/themecategory/${id}`);
	}

	protected openInNewTab(id: string): void {
		const route = this.$router.resolve({ name: 'EditCategory', params: { id } });
		window.open(route.href,
			'_blank');
	}
}
