import { Vue, Component } from 'vue-property-decorator';
import HyperlinksTable from 'components/HyperlinksTable/template.vue';

@Component({
	components: {
		HyperlinksTable,
	},
})
export default class Hyperlinks extends Vue {
	showModal(): void {
		this.$root.$emit('bv::show::modal',
			'hyperlinks-modal',
			'#hyperlinksShow');
	}

	routeHyperlink(id: string): void {
		this.$router.push(`/hyperlink/${id}`);
	}
}
