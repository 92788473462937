import { Vue, Component, Watch } from 'vue-property-decorator';
import * as DB from 'interfaces/database';
import { httpClient } from '../../utils/http';

@Component({})
export default class ShowOrdersTable extends Vue {
	private name = 'ShowOrdersTable';

	private orders = {} as DB.OrderModel;

	private orderItems: DB.OrderItemModel[] = []

	private spinner = false;

	private get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	protected created(): void {
		this.fetchOrders();
	}

	@Watch('loggedIn')
	private async fetchOrders(): Promise<void> {
		if (this.loggedIn) {
			this.spinner = true;
			try {
				const response = await httpClient.get<DB.OrderModel>(`/api/order/${this.$route.params.orderid}`);
				this.orders = response.data;
				const response2 = await httpClient.get<DB.OrderItemModel[]>(`/api/order/${this.$route.params.orderid}/items`);
				this.orderItems = response2.data;
			} catch (err: any) {
				this.$bvToast.toast(`${err.message}`,
					{
						solid: true,
						variant: 'danger',
					});
			} finally {
				this.spinner = false;
			}
		}
	}
}
