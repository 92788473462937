import { DirectiveOptions } from 'vue';

const directive: DirectiveOptions = {
	bind(el) {
		el.addEventListener('keypress',
			(e: any) => {
			// evolved from https://stackoverflow.com/a/52685887/9614402
				const keyCode = (e.keyCode ? e.keyCode : e.which);
				const { value } = e.target;
				const i = value.indexOf('.');
				if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || i !== -1)) { // 46 is dot
				// only allow numbers and one dot
					e.preventDefault();
				} else if (e.target.selectionStart === e.target.selectionEnd && (i === -1 || e.target.selectionEnd > i) && value.split('.')[1]?.length > 1) {
				// restrict to 2 decimal places
					e.preventDefault();
				}
			});
		el.addEventListener('focusout',
			(e: any) => {
				if (e.target.value === '') return;
				e.target.value = (Number(e.target.value)).toFixed(2) || null;
			});
		// modify the initial value if it's not 2dp
		const inputElement = el.nodeName === 'INPUT' ? el : el.querySelector('input');
		if ((inputElement as HTMLInputElement).value === '') return;
		const newValue = (Number((inputElement as HTMLInputElement).value)).toFixed(2) || null;
		if (inputElement && newValue !== null) {
			(inputElement as HTMLInputElement).value = newValue.toString();
			Number((inputElement as HTMLInputElement).value);
			inputElement.dispatchEvent(new Event('input'));
		}
	},
};

export default directive;
