var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.params.data
    ? _c(
        "router-link",
        {
          attrs: {
            to: {
              name: "showOrder",
              params: { orderid: _vm.params.data.value }
            }
          }
        },
        [_vm._v("\n\t" + _vm._s(_vm.params.data.value) + "\n")]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }