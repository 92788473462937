import { Vue, Component, Ref } from 'vue-property-decorator';
import DiscountEditTable from 'components/Discounts/DiscountsTable/DiscountsEditTable';
import Template from './template.vue';

@Component({
	components: {
		DiscountEditTable,
	},
})
export default class DiscountEdit extends Vue.extend(Template) {
	@Ref('editdiscountedit')
	public readonly editproduct!: HTMLFormElement;

	private discountEditId = this.$route.params.id;

	protected updateDiscountEditProperties(): void {
		this.editproduct.singleDiscountEdit();
	}
}
