import {
	Component,
	Ref,
	Vue,
} from 'vue-property-decorator';
import * as DB from 'interfaces/database';
import mitt from 'mitt';
import { TabEvent } from 'interfaces/app';
import { CellComponentExtended, ColumnDefinitionExtended, TabulatorFull as Tabulator } from 'tabulator-tables';
import { httpClient } from 'utils/http';
import 'vue-loading-overlay/dist/vue-loading.css';
import PriceDetails from 'components/Tabulator/PriceDetails';
import { createInstance } from 'utils/vue';
import TabulatorBtn from 'components/Tabulator/TabulatorBtn';
import Template from './template.vue';

const eventBus = mitt<TabEvent<DB.OfferingModel>>();
@Component({
	components: {
		PriceDetails,
	},
})
export default class OfferingsTable extends Vue.extend(Template) {
	@Ref('offeringTable')
	private allTableReference!: HTMLDivElement;

	protected isLoading = false;

	private table?: Tabulator;

	private offeringModels: DB.OfferingModel[] = [];

	private productGroupModels: DB.ProductGroupModel[] = [];

	private rowData: DB.OfferingModel[] = [];

	private get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	protected mounted(): void {
		eventBus.on('editItem',
			this.editOffering);
		this.onTableBuilt();
	}

	private onTableBuilt() {
		this.isLoading = true;
		this.getData().then(() => {
			this.buildTable();
			return true;
		}).finally(() => {
			this.isLoading = false;
		}).catch((error) => {
			this.$bvToast.toast(
				`${error.message}`,
				{
					solid: true,
					variant: 'danger',
				},
			);
		});
	}

	protected beforeDestroy() {
		eventBus.off('editItem',
			this.editOffering);
		this.table?.off('tableBuilt',
			this.onTableBuilt);
		this?.table?.destroy();
	}

	private buildTable() {
		const groupNameFilterValues: Record<
		DB.ProductGroupModel['name'] | '',
		DB.ProductGroupModel['name'] | ''
		> = {
			'': '',
		};
		this.productGroupModels.forEach(
			(model) => {
				groupNameFilterValues[model.name] = model.name;
			},
		);
		this.table = new Tabulator(
			this.allTableReference,
			{
				data: this.rowData,
				height: '80vh',
				layout: 'fitColumns',
				// create a context menu to open row on a new tab
				rowContextMenu: [
					{
						label: 'Open in new tab',
						action: (e, row) => {
							const data = row.getData();
							this.$emit('openTab',
								data.id);
						},
					},
				],
				columns: [
					{
						title: 'offeringModel',
						field: 'offeringModel',
						visible: false,
						mutatorData: (val, data) => this.offeringModels.find(
							(model) => model.id === data.offeringid,
						),
					},
					{
						title: 'Group',
						field: 'groupName',
						cssClass: 'excelStyle',
						formatter: 'plaintext',
						mutatorData: (val, rowData) => {
							const productGroup = this.productGroupModels.find(
								(model) => model.id === rowData.groupid,
							);
							return productGroup?.name;
						},
						headerFilter: 'list',
						headerFilterParams: {
							values: groupNameFilterValues,
							clearable: true,
						},
					},
					{
						field: 'id',
						title: 'ID',
						headerFilter: true,
					},
					{

						title: 'Image',
						field: 'thumbnail',
						formatter: 'image',
						formatterParams: {
							height: '150px',
						},
					},
					{
						title: 'Info',
						field: 'externalid',
						headerFilter: true,
						formatter(cell: CellComponentExtended<DB.OfferingModel>) {
							const instance = createInstance({
								component: PriceDetails,
								props: {
									data: cell.getData(),
								},
							});
							instance.$mount();
							return (instance.$el as HTMLElement);
						},
					},
					{
						title: 'Actions',
						formatter: (cell: CellComponentExtended<DB.OfferingModel>) => {
							const instance = createInstance({
								component: TabulatorBtn,
								props: {
									data: cell.getData(),
									buttons: [
										{
											id: 'edit',
											eventName: 'editItem',
											className: 'fa-edit',
										},
									],
									eventBus,
								},
							});
							instance.$mount();
							return (instance.$el as HTMLElement);
						},
					},
				] as ColumnDefinitionExtended[],
			},
		);
	}

	private getData(): Promise<DB.OfferingModel[]> {
		const parameter = new URLSearchParams({
			limit: '0',
		});

		return Promise.all([
			httpClient.get<DB.OfferingModel[]>(`/api/offering?${parameter}`),
			httpClient.get<DB.ProductGroupModel[]>(`/api/productgroup?${parameter}`),
		]).then(([
			offeringResponse,
			productGroupResponse,
		]) => {
			this.productGroupModels = productGroupResponse.data;
			this.offeringModels = offeringResponse.data;
			this.rowData = this.offeringModels;

			return this.rowData;
		}).catch((error: any) => {
			this.$bvToast.toast(
				`${error.message}`,
				{
					solid: true,
					variant: 'danger',
				},
			);

			return [];
		});
	}

	private editOffering(data: TabEvent<DB.OfferingModel>['editItem']): void {
		this.$emit('routeOffering',
			data.id);
	}
}
