import { Vue, Component, Ref } from 'vue-property-decorator';
import * as DB from 'interfaces/database';
import { BModal } from 'bootstrap-vue';
import { httpClient } from 'utils/http';
import {
	CellComponent, CellComponentExtended, ColumnDefinitionExtended, TabulatorFull as Tabulator,
} from 'tabulator-tables';
import { createInstance } from 'utils/vue';
import Swal from 'sweetalert2';
import TabulatorBtn from 'components/Tabulator/TabulatorBtn';
import mitt from 'mitt';
import { TabEvent } from 'interfaces/app';
import Template from './template.vue';

const eventBus = mitt<TabEvent<DB.DiscountModel>>();
@Component({
	components: {
		TabulatorBtn,
	},
})
export default class DiscountTable extends Vue.extend(Template) {
	@Ref('addmodel2d-modal') readonly modal!: BModal;

	@Ref('DiscountTable')
	private readonly tableReference!: HTMLDivElement;

	private isLoaded = false;

	private table?: Tabulator;

	private columnDefs: ColumnDefinitionExtended[] = []

	private currencies = {} as Record<string, DB.CurrencyModel['prefix']>;

	private tracker = {} as Record<string, DB.AffiliateModel>;

	protected get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	protected beforeMount(): void {
		this.columnDefs = [
			{
				field: 'name',
				title: 'Description',
				headerFilter: true,
			},
			{
				title: 'Tracker',
				field: 'trackerid',
				headerFilter: true,
				formatter: (cell: CellComponent) => {
					let html = '';
					if (Object.keys(this.tracker).length) {
						if (this.tracker[cell.getValue()].id === cell.getValue()) {
							html += `<span>${this.tracker[cell.getValue()].name}</span>`;
						}
					}
					return html;
				},
			},
			{
				title: 'Vouchers',
				field: 'voucherCount',
				headerFilter: true,
			},
			{
				title: 'Issued',
				field: 'voucherIssued',
				headerFilter: true,
			},
			{
				title: 'Valid',
				field: 'voucherValidity',
				headerFilter: true,
			},
			{
				title: 'Used',
				field: 'voucherUsed',
				headerFilter: true,
			},
			{
				title: 'value',
				field: 'relative',
				formatter: (cell: CellComponent) => {
					const params = cell.getData() as DB.DiscountModel;
					if (Object.keys(this.currencies).length) {
						if (params.absolute) {
							return `${this.currencies[params.currency]} ${params.absolute / 100}`;
						}
						if (params.relative) {
							return `${params.relative}%`;
						}
					}
					return '';
				},
			},
			{
				title: 'Actions',
				formatter: (cell: CellComponentExtended<DB.DiscountModel>) => {
					const instance = createInstance({
						component: TabulatorBtn,
						props: {
							data: cell.getData(),
							buttons: [
								{
									id: 'edit',
									eventName: 'editItem',
									className: 'fa-edit',
								},
								{
									id: 'delete',
									eventName: 'deleteItem',
									className: 'fa-times',
								},
							],
							eventBus,
						},
					});
					instance.$mount();

					return (instance.$el as HTMLElement);
				},
			},
		];
	}

	protected mounted(): void {
		eventBus.on('deleteItem',
			this.deleteDiscountItem);
		eventBus.on('editItem',
			this.editDiscountItem);
		this.tableInitialization();
		this.table?.on('tableBuilt',
			this.onTableBuilt);
	}

	protected beforeDestroy(): void {
		eventBus.off('deleteItem',
			this.deleteDiscountItem);
		eventBus.off('editItem',
			this.editDiscountItem);
		this.table?.off('tableBuilt',
			this.onTableBuilt);
		this.table?.destroy();
	}

	private onTableBuilt(): void {
		this.getData();
	}

	private tableInitialization(): void {
		this.table = new Tabulator(this.tableReference,
			{
				height: '60vh',
				layout: 'fitColumns',
				columns: this.columnDefs,
			});
	}

	private async getData(): Promise<void> {
		const parameter = new URLSearchParams({
			limit: '0',
			fields: 'id,trackerid,voucherIssued,voucherCount,voucherUsed,voucherValidity,name,absolute,relative,currency,freeshipping,freehandling,description',
			orderby: 'datetime_created DESC',
		});
		this.table?.alert('Loading');
		try {
			await this.fetchData();
			const { data } = await httpClient.get<DB.DiscountModel[]>(`/api/discount?${parameter}`);
			this.table?.setData(data);
		} catch (err: any) {
			this.$bvToast.toast(`${err.message}`,
				{
					solid: true,
					variant: 'danger',
				});
		} finally {
			this.table?.clearAlert();
		}
	}

	private async fetchData(): Promise<void> {
		try {
			const [currencyResponse, affiliateResponse] = await Promise.all([
				httpClient.get<DB.CurrencyModel[]>('/api/currency'),
				httpClient.get<DB.AffiliateModel[]>('/api/affiliate'),
			]);
			currencyResponse.data.map((item) => {
				this.currencies[item.id] = item.prefix;
				return undefined;
			});
			affiliateResponse.data.map((item) => {
				this.tracker[item.id] = item;
				return undefined;
			});
		} catch (error) {
			this.$bvToast.toast('Error loading data',
				{
					variant: 'danger',
					solid: true,
				});
		}
	}

	private editDiscountItem(data: TabEvent<DB.DiscountModel>['editItem']): void {
		this.$emit('routeDiscount',
			data.id);
	}

	private deleteDiscountItem(data: TabEvent<DB.DiscountModel>['deleteItem']): void {
		Swal.fire({
			title: 'Are you sure?',
			text: 'You will not be able to recover this file!',
			icon: 'warning',
			showCancelButton: true,
			customClass: {
				confirmButton: 'btn btn-danger m-1',
				cancelButton: 'btn btn-secondary m-1',
			},
			confirmButtonText: 'Yes, delete it!',
			html: false,
		}).then((result) => {
			if (result.value) {
				this.table?.alert('Loading');
				// eslint-disable-next-line promise/no-nesting
				httpClient.delete(`/api/discount/${data.id}`).then(() => {
					this.$bvToast.toast('Item Deleted',
						{
							solid: true,
							variant: 'success',
						});
					this.table?.deleteRow(data.id);
					return null;
				}).finally(() => {
					this.table?.clearAlert();
				}).catch((err) => {
					this.$bvToast.toast(`${err.message}`,
						{
							solid: true,
							variant: 'danger',
						});
				});
			}
			return undefined;
		}).catch((err) => {
			this.$bvToast.toast(`${err.message}`,
				{
					solid: true,
					variant: 'danger',
				});
		});
	}
}
