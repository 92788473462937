import { Vue, Component } from 'vue-property-decorator';
import CurrencyTable from 'components/CurrencyTable';
import Template from './template.vue';

@Component({
	components: {
		CurrencyTable,
	},
})
export default class Currency extends Vue.extend(Template) {
	protected showModal(): void {
		this.$root.$emit('bv::show::modal',
			'currency-modal',
			'#currencyShow');
	}

	protected routeCurrency(id: string): void {
		this.$router.push(`/currency/${id}`);
	}
}
