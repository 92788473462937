var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _c("base-page-heading", {
        staticClass: "d-print-none",
        attrs: { title: "Templates", subtitle: "" },
        scopedSlots: _vm._u([
          {
            key: "extra",
            fn: function() {
              return [
                _c(
                  "b-button",
                  {
                    ref: "importShow",
                    attrs: { type: "submit", size: "md", variant: "secondary" },
                    on: { click: _vm.showImport }
                  },
                  [
                    _c("i", { staticClass: "fa fa-fw fa-plus" }),
                    _vm._v(" \tImport\n\t\t\t")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-button",
                  {
                    ref: "templateShow",
                    attrs: { type: "submit", size: "md", variant: "secondary" },
                    on: { click: _vm.showModal }
                  },
                  [
                    _c("i", { staticClass: "fa fa-fw fa-plus" }),
                    _vm._v(" \tCreate new\n\t\t\t")
                  ]
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row" },
        [
          _c("TemplateTable", {
            ref: "updateTemplate",
            on: {
              routeTemplate: _vm.routeTemplate,
              openInNewTab: _vm.openInNewTab
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }