var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-block", { attrs: { title: "PDPs", "header-bg": "" } }, [
    _c("div", { ref: "badgepdp" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }