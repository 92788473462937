import { Vue, Component } from 'vue-property-decorator';
import showOrdersTable from 'components/showOrdersTable/template.vue';

@Component({
	components: {
		showOrdersTable,
	},
})
export default class ShowOrder extends Vue {
	private name = 'ShowOrder';
}
