import {
	Vue, Component,
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { httpClient } from 'utils/http';
import Swal from 'sweetalert2';
import * as DB from 'interfaces/database';
import handlingRow from './handlingRow.vue';

const Regions = namespace('Regions');

@Component({
	components: {
		handlingRow,
	},
})
export default class Handling extends Vue {
	private arrScopes = ['Quantity', 'Item', 'Variant', 'Type', 'Group', 'Order'];

	private salesCurrency : DB.RegionCurrencyModel[] = [];

	private scope = '';

	private currency = '';

	private price = '';

	private footer = true;

	private isLoading = false;

	private HandlingPrice : DB.HandlingModel[] = [];

	@Regions.Getter
	private regionCurrencyForSales!: Array<DB.RegionCurrencyModel>;

	get currentCurrency(): DB.RegionCurrencyModel[] {
		this.salesCurrency = this.regionCurrencyForSales;
		return this.salesCurrency;
	}

	mounted(): void {
		this.getHandlingPrice();
	}

	add(): Promise<any> | void {
		this.isLoading = true;

		httpClient.post('/api/handling',
			{
				scope: this.scope,
				currency: this.currency,
				price: this.price,
				offeringid: this.$route.params.id,
			}).then((res) => {
			this.HandlingPrice.push(res.data);
			this.scope = '';
			this.currency = '';
			this.price = '';
			if (this.salesCurrency.length === 0) {
				this.footer = false;
			}
			return undefined;
		}).finally(() => {
			this.isLoading = false;
		}).catch((err) => {
			this.$bvToast.toast(`${err.message}`,
				{
					solid: true,
					variant: 'danger',
				});
		});
	}

	removePrice(id: number): Promise<void> | void {
		Swal.fire({
			title: 'Are you sure?',
			text: 'You will not be able to recover this imaginary file!',
			icon: 'warning',
			showCancelButton: true,
			customClass: {
				confirmButton: 'btn btn-danger m-1',
				cancelButton: 'btn btn-secondary m-1',
			},
			confirmButtonText: 'Yes, delete it!',
			html: false,
		}).then((result) => {
			if (result.value) {
				// eslint-disable-next-line promise/no-nesting
				httpClient.delete(`/api/handling/${id}`).then(() => {
					const indexOfUserToRemove = this.HandlingPrice.findIndex((u) => u.id === id);
					this.HandlingPrice.splice(indexOfUserToRemove,
						1);
					return null;
				}).catch((err) => {
					this.$bvToast.toast(`${err.message}`,
						{
							solid: true,
							variant: 'danger',
						});
				});
			}
			return undefined;
		}).catch((err) => {
			this.$bvToast.toast(`${err.message}`,
				{
					solid: true,
					variant: 'danger',
				});
		});
	}

	getHandlingPrice(): void {
		// get handling data and filter by offering id
		httpClient.get<DB.HandlingModel[]>('/api/handling').then((res) => {
			this.HandlingPrice = res.data;
			return undefined;
		}).catch((err) => {
			this.$bvToast.toast(`${err.message}`,
				{
					solid: true,
					variant: 'danger',
				});
		});
	}
}
