import { Vue, Component } from 'vue-property-decorator';
import { ICellRendererParams } from 'ag-grid-community';
import * as DB from 'interfaces/database';

@Component({})
export default class VatInput extends Vue {
	private params!: ICellRendererParams & { offeringGetter: () => any, };

	private	checkOffering = {} as Record<number, DB.RegionOfferingModel>;

	beforeMount(): void {
		this.updateImage();
	}

	updateImage(): void {
		this.checkOffering = this.params.offeringGetter();
	}

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	refresh(params: any) {
		this.params = params;
		this.updateImage();
	}
}
