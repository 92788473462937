import { Vue, Component, Ref } from 'vue-property-decorator';
import EditCrossSellTable from 'components/CrossSellTable/EditCrossSellTable';
import Template from './template.vue';

@Component({
	components: {
		EditCrossSellTable,
	},
})
export default class EditCrossSell extends Vue.extend(Template) {
	@Ref('editcrosssell') readonly crossSellModal!: HTMLFormElement;

	private routeId = this.$route.params.id;

	private isLoading = false;

	protected updateCrossSellProperties(): void {
		this.crossSellModal.editCrossSell();
	}
}
