var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._v(
      " " + _vm._s(_vm._f("convertUnixDate")(_vm.photodate, _vm.photodate))
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }