import {
	Vue, Component, Watch, Prop,
} from 'vue-property-decorator';
import * as DB from 'interfaces/database';
import { httpClient } from 'utils/http';
import { Event } from 'interfaces/app';
import Template from './template.vue';
import TemplatePosition from '../TemplatePosition';

@Component({
	components: {
		TemplatePosition,
	},
})
export default class EditTemplateStateEdit extends Vue.extend(Template) {
	private singleTemplateState = {} as DB.TemplatePositionStateModel;

	private templateId = this.$route.params.templateid;

	private loaded = false;

	private visible = false;

	private spinner = false;

	private index = 0;

	private isLoading = false;

	private options = ['photo', 'text'];

	@Prop({
		type: String,
		required: true,
	})
	public readonly templateStateId!: string

	private get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	protected mounted(): void {
		this.getSingleTemplate();
	}

	protected get isPriorityNull(): boolean {
		return this.singleTemplateState.priority == null;
	}

	protected get isCombineTrue(): boolean {
		return this.singleTemplateState.combine === 1;
	}

	protected setPriorityToZero(): void {
		this.singleTemplateState.priority = 0;
	}

	protected onChangeEvent(event: number): void {
		this.singleTemplateState.combine = event;
	}

	@Watch('loggedIn')
	private async getSingleTemplate(): Promise<DB.TemplatePositionStateModel | undefined> {
		if (this.loggedIn) {
			// set the loading state
			this.isLoading = true;
			try {
				const resp = await httpClient.get<DB.TemplatePositionStateModel>(`/api/templatestate/${this.templateStateId}`);
				this.singleTemplateState = resp.data;
				// eslint-disable-next-line consistent-return
				return this.singleTemplateState;
			} catch (error: any) {
				this.$bvToast.toast(`${error.message}`,
					{
						solid: true,
						variant: 'danger',
					});
			} finally {
				this.isLoading = false;
			}
		}
		return undefined;
	}

	protected async editTemplate(): Promise<DB.TemplatePositionStateModel | null> {
		if (this.loggedIn) {
			try {
				this.isLoading = true;
				const { data } = await httpClient.put<DB.TemplatePositionStateModel>(`/api/templatestate/${this.templateStateId}`,
					{ ...this.singleTemplateState });
				this.$bvToast.toast('Template edited successfully',
					{
						solid: true,
						variant: 'success',
					});
				return data;
			} catch (error: any) {
				this.$bvToast.toast(`${error.message}`,
					{
						solid: true,
						variant: 'danger',
					});
			} finally {
				this.isLoading = false;
			}
		}
		return Promise.resolve(null);
	}

	protected routeTemplateState(data: Event['editItem']): void {
		this.$emit('routeTemplateState',
			{
				data,
			});
	}
}
