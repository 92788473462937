import { Vue, Component } from 'vue-property-decorator';
import ApiPermissionsTable from 'components/ApiPermissionsTable/';
import Template from './template.vue';

@Component({
	components: {
		ApiPermissionsTable,
	},
})
export default class ApiPermissions extends Vue.extend(Template) {
	protected routeApiPermission(id: string): void {
		this.$router.push(`/application/${id}`);
	}
}
