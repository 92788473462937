var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    _vm._l(_vm.params.value, function(curr, index) {
      return _c("div", { key: index }, [
        _c("p", [_vm._v(_vm._s(curr.currency))])
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }