var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ml-5 mt-4", staticStyle: { width: "95%", height: "100%" } },
    [
      _c("loading-overlay", {
        attrs: { active: _vm.isLoading, "can-cancel": true }
      }),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "12", lg: "12", sm: "12" } },
            [
              _c(
                "b-tabs",
                { attrs: { card: "", "content-class": "mt-3", fill: "" } },
                [
                  _c(
                    "b-tab",
                    { attrs: { title: "Template properties", active: "" } },
                    [
                      _c(
                        "base-block",
                        {
                          attrs: {
                            title: "Template properties",
                            "header-bg": ""
                          }
                        },
                        [
                          _vm.spinner
                            ? _c("div", { staticClass: "text-center" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "spinner-grow text-primary m-5",
                                    attrs: { role: "status" }
                                  },
                                  [
                                    _c("span", { staticClass: "sr-only" }, [
                                      _vm._v("Loading...")
                                    ])
                                  ]
                                )
                              ])
                            : _c(
                                "div",
                                { staticClass: "d-block" },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "form-group",
                                      attrs: {
                                        label: "name",
                                        "label-for": "block-form1-name",
                                        "label-cols-lg": "4"
                                      }
                                    },
                                    [
                                      _c(
                                        "b-input-group",
                                        [
                                          _c("b-form-input", {
                                            attrs: { type: "text" },
                                            model: {
                                              value: _vm.singleTemplate.name,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.singleTemplate,
                                                  "name",
                                                  $$v
                                                )
                                              },
                                              expression: "singleTemplate.name"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-class": "font-w600",
                                        "label-cols-lg": "4"
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function() {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "d-flex flex-row"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\tTransformable\n\t\t\t\t\t\t\t\t\t\t"
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: {
                                                      id: "transformable"
                                                    }
                                                  })
                                                ]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ])
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "transformable",
                                            triggers: "hover",
                                            placement: "bottom"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\tCan new objects be freely dropped in this template\n\t\t\t\t\t\t\t\t"
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("b-form-checkbox", {
                                        attrs: {
                                          switch: "",
                                          size: "lg",
                                          checked: _vm.isChecked
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.onChangeCheckbox($event)
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    { attrs: { title: "Template State" } },
                    [
                      _c("TemplateState", {
                        attrs: { "template-id": _vm.templateId },
                        on: { routeTemplateEdit: _vm.routeTemplateEdit }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }