import {
	Vue, Component, Prop, Watch, Ref,
} from 'vue-property-decorator';
import * as DB from 'interfaces/database';
import mitt from 'mitt';
import Swal from 'sweetalert2';
import { httpClient } from 'utils/http';
import { TabEvent } from 'interfaces/app';
import { CellComponentExtended, ColumnDefinitionExtended, TabulatorFull as Tabulator } from 'tabulator-tables';
import { createInstance } from 'utils/vue';
import TabulatorBtn from 'components/Tabulator/TabulatorBtn';
import TabulatorSwitchBox from 'components/Tabulator/TabulatorSwitchBox';
import Template from './template.vue';

const eventBus = mitt<TabEvent<DB.LayoutTemplateLinkModel>>();
@Component({
	components: {
		TabulatorBtn,
		TabulatorSwitchBox,
	},
})
export default class LayoutTemplate extends Vue.extend(Template) {
	@Ref('layoutTemplateTable')
	private readonly tableReference!: HTMLDivElement;

	private isLoading = false;

	private columnDefs: ColumnDefinitionExtended[] = [];

	private showExistingPage = false;

	private showNewPage = false;

	private layoutdata = {} as DB.LayoutModel;

	private ExistingPagesData = {} as DB.LayoutModel

	private serialnumber: undefined | number = undefined;

	private layoutTemplate: DB.TemplateModel[] = [];

	private themepageDate: DB.LayoutTemplateLinkModel[] = [];

	private table?: Tabulator;

	@Prop({
		type: String,
		required: true,
	})
	public readonly routeId!: string;

	private get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	protected beforeMount(): void {
		this.columnDefs = [
			{
				field: 'name',
				title: 'Name',
			},
			{
				title: 'Serialnumber',
				field: 'serialnumber',
			},
			{
				title: 'Page repeat',
				formatter: (cell: CellComponentExtended<DB.LayoutTemplateLinkModel>) => {
					const data = cell.getData();
					const instance = createInstance({
						component: TabulatorSwitchBox,
						props: {
							data,
							eventBus,
							checked: Boolean(data && data.repeat),
						},
					});
					instance.$mount();

					return (instance.$el as HTMLElement);
				},
			},
			{
				title: 'Actions',
				formatter: (cell: CellComponentExtended<DB.LayoutTemplateLinkModel>) => {
					const instance = createInstance({
						component: TabulatorBtn,
						props: {
							data: cell.getData(),
							buttons: [
								{
									id: 'edit',
									eventName: 'editItem',
									className: 'fa-edit',
								},
								{
									id: 'delete',
									eventName: 'deleteItem',
									className: 'fa-times',
								},
							],
							eventBus,
						},
					});
					instance.$mount();

					return (instance.$el as HTMLElement);
				},
			},
		];
	}

	@Watch('loggedIn')
	private async getTemplate(): Promise<DB.TemplateModel[] | undefined> {
		if (this.loggedIn) {
			// set the loading state
			this.isLoading = true;
			try {
				const resp = await httpClient.get<DB.TemplateModel[]>('/api/template?limit=0');
				this.layoutTemplate = resp.data;
				return this.layoutTemplate;
			} catch (error: any) {
				this.$bvToast.toast(`${error.message}`,
					{
						solid: true,
						variant: 'danger',
					});
			} finally {
				this.isLoading = false;
			}
		}
		return undefined;
	}

	private switchBoxChanged(data: TabEvent<DB.LayoutTemplateLinkModel>['switchBoxChanged']): void {
		this.table?.alert('Loading..');
		if (data.event) {
			httpClient
				.put(`/api/themepage/${data.params.id}`,
					{
						repeat: 1,
					})
				.then(() => undefined)
				.finally(() => {
					this.table?.clearAlert();
				}).catch((err) => {
					this.$bvToast.toast(`${err.message}`,
						{
							solid: true,
							variant: 'danger',
						});
				});
		} else {
			httpClient
				.put(`/api/themepage/${data.params.id}`,
					{
						data: {
							repeat: 0,
						},
					})
				.then(() => undefined)
				.finally(() => {
					this.table?.clearAlert();
				}).catch((err) => {
					this.$bvToast.toast(`${err.message}`,
						{
							solid: true,
							variant: 'danger',
						});
				});
		}
	}

	protected mounted(): void {
		this.getTemplate();
		eventBus.on('switchBoxChanged',
			this.switchBoxChanged);
		eventBus.on('editItem',
			this.editLayoutTemplate);
		eventBus.on('deleteItem',
			this.deleteLayoutTemplate);
		this.tableInitialization();
		this.table?.on('tableBuilt',
			this.onTableBuilt);
	}

	protected beforeDestroy(): void {
		eventBus.off('switchBoxChanged',
			this.switchBoxChanged);
		eventBus.off('editItem',
			this.editLayoutTemplate);
		eventBus.off('deleteItem',
			this.deleteLayoutTemplate);
		this.table?.off('tableBuilt',
			this.onTableBuilt);
		this.table?.destroy();
	}

	private onTableBuilt(): void {
		this.getData();
	}

	private tableInitialization(): void {
		this.table = new Tabulator(this.tableReference,
			{
				height: '60vh',
				layout: 'fitColumns',
				columns: this.columnDefs,
			});
	}

	private async getData(): Promise<void> {
		const parameter = new URLSearchParams({
			limit: '0',
		});
		this.table?.alert('Loading');
		try {
			const { data } = await httpClient.get<DB.LayoutTemplateLinkModel[]>(`/api/layout/${this.routeId}/templates?${parameter}`);
			this.themepageDate = data;
			this.serialnumber = data.length;
			this.table?.setData(data);
		} catch (err: any) {
			this.$bvToast.toast(`${err.message}`,
				{
					solid: true,
					variant: 'danger',
				});
		} finally {
			this.table?.clearAlert();
		}
	}

	protected async addPage(): Promise<void> {
		this.table?.alert('Loading...');
		try {
			const firstResonse = await httpClient.post('/api/template',
				{
					name: this.layoutdata.name,
				});
			const secondResponse = await httpClient.post('/api/layouttemplate',
				{
					layoutid: parseInt(this.routeId,
						10),
					templateid: firstResonse.data.id,
					serialnumber: this.serialnumber,
				});
			this.table?.addData(secondResponse.data);
		} catch (error: any) {
			this.$bvToast.toast(`${error.message}`,
				{
					solid: true,
					variant: 'danger',
				});
		} finally {
			this.table?.clearAlert();
		}
	}

	protected async addPages(): Promise<void> {
		this.isLoading = true;
		try {
			await httpClient.post('/api/layouttemplate',
				{
					layoutid: parseInt(this.routeId,
						10),
					templateid: this.ExistingPagesData.id,
				});
		} catch (error: any) {
			this.$bvToast.toast(`${error.message}`,
				{
					solid: true,
					variant: 'danger',
				});
		} finally {
			this.isLoading = false;
		}
	}

	private editLayoutTemplate(data: TabEvent<DB.LayoutTemplateLinkModel>['editItem']): void {
		this.$emit('routeLayoutTemplate',
			data.id);
	}

	private deleteLayoutTemplate(data: TabEvent<DB.LayoutTemplateLinkModel>['deleteItem']): void {
		Swal.fire({
			title: 'Are you sure?',
			text: 'You will not be able to recover this file!',
			icon: 'warning',
			showCancelButton: true,
			customClass: {
				confirmButton: 'btn btn-danger m-1',
				cancelButton: 'btn btn-secondary m-1',
			},
			confirmButtonText: 'Yes, delete it!',
			html: false,
		}).then((result) => {
			if (result.value) {
				this.table?.alert('Loading..');
				// eslint-disable-next-line promise/no-nesting
				httpClient.delete(`/api/layouttemplate/${data.id}`).then(() => {
					this.$bvToast.toast('Item Deleted',
						{
							solid: true,
							variant: 'success',
						});
					this.table?.deleteRow(data.id);
					return null;
				}).finally(() => {
					this.table?.clearAlert();
				}).catch((err) => {
					this.$bvToast.toast(`${err.message}`,
						{
							solid: true,
							variant: 'danger',
						});
				});
			}
			return undefined;
		}).catch((err) => {
			this.$bvToast.toast(`${err.message}`,
				{
					solid: true,
					variant: 'danger',
				});
		});
	}
}
