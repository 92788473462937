import {
	Vue, Component, Ref, Prop,
} from 'vue-property-decorator';
import * as DB from 'interfaces/database';
import { BModal } from 'bootstrap-vue';
import mitt from 'mitt';
import Swal from 'sweetalert2';
import { TabEvent } from 'interfaces/app';
import {
	CellComponentExtended, ColumnDefinitionExtended, TabulatorFull as Tabulator,
} from 'tabulator-tables';
import TabulatorBtn from 'components/Tabulator/TabulatorBtn';
import TabulatorImage from 'components/Tabulator/TabulatorImage';
import { PickerOptions, PickerResponse } from 'filestack-js';
import { createInstance } from 'utils/vue';
import ImageComponent from '../Globals/ImageComponent.vue';
import EditAndDeleteBtn from '../Globals/AgGridBtn';
import { httpClient } from '../../utils/http';
import Template from './template.vue';

const eventBus = mitt<TabEvent<DB.ProductCategoryModel>>();
@Component({
	components: {
		ImageComponent,
		TabulatorImage,
		EditAndDeleteBtn,
	},
})
export default class ProductCategoriesTable extends Vue.extend(Template) {
	@Ref('addproductcategory-modal')
	private readonly modal!: BModal;

	@Ref('productCategory')
	private readonly tableReference!: HTMLDivElement;

	private name = '';

	private table?: Tabulator;

	private isLoaded = false;

	private columnDefs: ColumnDefinitionExtended[] = [];

	private rowData: DB.ProductCategoryModel[] = [];

	private image = '';

	@Prop({
		type: Boolean,
	})
	private readonly subcategory!: boolean;

	private get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	protected beforeMount(): void {
		this.columnDefs = [
			{
				title: '',
				rowHandle: true,
				formatter: 'handle',
				headerSort: false,
				frozen: true,
				width: 40,
			},
			{
				field: 'name',
				title: 'Name',
				headerFilter: true,
			},
			{
				title: 'Image',
				field: 'thumbnail',
				formatter: 'image',
				formatterParams: {
					height: '70px',
				},
			},
			{
				title: 'Actions',
				formatter: (cell: CellComponentExtended<DB.ProductCategoryModel>) => {
					const instance = createInstance({
						component: TabulatorBtn,
						props: {
							data: cell.getData(),
							buttons: [
								{
									id: 'edit',
									eventName: 'editItem',
									className: 'fa-edit',
								},
								{
									id: 'delete',
									eventName: 'deleteItem',
									className: 'fa-times',
								},
							],
							eventBus,
						},
					});
					instance.$mount();

					return (instance.$el as HTMLElement);
				},
			},
		];
	}

	protected mounted(): void {
		eventBus.on('deleteItem',
			this.deleteProductCategory);
		eventBus.on('editItem',
			this.editProductCategory);
		eventBus.on('editImage',
			this.editImageButton);
		this.tableInitialization();
		this.table?.on('tableBuilt',
			() => {
				this.getData();
			});
		this.table?.on('rowMoved',
			this.onRowDragEnd);
	}

	protected beforeDestroy(): void {
		eventBus.off('deleteItem',
			this.deleteProductCategory);
		eventBus.off('editItem',
			this.editProductCategory);
		eventBus.off('editImage',
			this.editImageButton);
		this.table?.off('rowMoved',
			this.onRowDragEnd);
		this.table?.off('tableBuilt',
			() => {
				this.getData();
			});
		this.table?.destroy();
	}

	private tableInitialization(): void {
		this.table = new Tabulator(this.tableReference,
			{
				height: '100%',
				layout: 'fitColumns',
				columns: this.columnDefs,
				movableRows: true,
			});
	}

	private async onRowDragEnd(): Promise<void> {
		try {
			const arr = this.table?.getData().map((element, index) => ({ id: element.id, serialnumber: index }));
			this?.table?.alert('Loading');
			await httpClient.put<void>('/api/productcategory',
				arr);
			this.showToast('Lister Items have been updated',
				'success');
		} catch (err: any) {
			this.showToast(err.message,
				'danger');
		} finally {
			this.table?.clearAlert();
		}
	}

	private async getData(): Promise<void> {
		const parameter = new URLSearchParams({
			limit: '0',
			orderby: 'serialnumber ASC',
		});
		if (this.subcategory === true) {
			parameter.set('where',
				JSON.stringify({ parentid: this.$route.params.id }));
		}

		this.table?.alert('Loading');
		try {
			const rowData = await httpClient.get<DB.ProductCategoryModel[]>(`/api/productcategory?${parameter}`);
			if (this.subcategory === true) {
				this.rowData = rowData.data.filter((i) => i.parentid !== null);
				this.table?.setData(this.rowData);
			} else {
				this.rowData = rowData.data.filter((i) => i.parentid === null);
				this.table?.setData(this.rowData);
			}
		} catch (err: any) {
			this.showToast(`${err.message}`,
				'danger');
		} finally {
			this.table?.clearAlert();
		}
	}

	protected async addProductCategory(): Promise<void> {
		this.isLoaded = true;
		try {
			const response = await httpClient.post('/api/productcategory',
				{ name: this.name });
			this.hideModal();
			this.name = '';
			this.showToast('Product Category Added',
				'success');
			this.$emit('routeProductCategory',
				response.data.id);
		} catch (err: any) {
			this.showToast(`${err.message}`,
				'danger');
		} finally {
			this.isLoaded = false;
		}
	}

	private hideModal(): void {
		this.modal.hide();
	}

	private showToast(message: string, variant: 'success' | 'danger'): void {
		this.$bvToast.toast(message,
			{ solid: true, variant });
	}

	private editImageButton(data: TabEvent<DB.ProductCategoryModel>['editImage']): void {
		const options: PickerOptions = {
			fromSources: ['local_file_system'],
			onUploadDone: async (files: PickerResponse) => {
				// send an edit request to the API
				this.table?.alert('Loading');
				httpClient.put<DB.ProductCategoryModel>(
					`/api/productcategory/${data.id}`,
					{
						thumbnail: files.filesUploaded[0].url,
					},
				)
					.then((res) => {
						this.table?.updateData([res.data]);
						return null;
					}).finally(() => this.table?.clearAlert())
					.catch((err) => {
						this.showToast(`${err.message}`,
							'danger');
					});
			},
		};
		this.$client.picker(options).open();
	}

	private editProductCategory(data: TabEvent<DB.ProductCategoryModel>['editItem']): void {
		this.$router.push(`/productcategory/${data.id}`);
	}

	private deleteProductCategory(data: TabEvent<DB.ProductCategoryModel>['deleteItem']): void {
		Swal.fire({
			title: 'Are you sure?',
			text: 'You will not be able to recover this file!',
			icon: 'warning',
			showCancelButton: true,
			customClass: {
				confirmButton: 'btn btn-danger m-1',
				cancelButton: 'btn btn-secondary m-1',
			},
			confirmButtonText: 'Yes, delete it!',
			html: false,
		}).then((result) => {
			if (result.value) {
				this.table?.alert('Loading');
				// eslint-disable-next-line promise/no-nesting
				httpClient.delete<DB.ProductCategoryModel>(`/api/productcategory/${data.id}`).then(() => {
					this.showToast('Item Deleted',
						'success');
					this.table?.deleteRow(data.id);
					return null;
				}).finally(() => {
					this.table?.clearAlert();
				}).catch((err) => {
					this.showToast(`${err.message}`,
						'danger');
				});
			}
			return undefined;
		}).catch((err) => {
			this.showToast(`${err.message}`,
				'danger');
		});
	}
}
