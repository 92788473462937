import { Vue, Component } from 'vue-property-decorator';
import UserPropertyTable from 'components/UserProperties/template.vue';

@Component({
	components: {
		UserPropertyTable,
	},
})
export default class UserProperties extends Vue {
	private name = 'UserProperties';
}
