/* eslint-disable promise/no-nesting */
/* eslint-disable implicit-arrow-linebreak */
import {
	Vue, Component, Watch, Prop,
} from 'vue-property-decorator';
import { httpClient } from 'utils/http';
import { namespace } from 'vuex-class';
import * as DB from 'interfaces/database';
import regionRow from './regionRow.vue';

const Regions = namespace('Regions');

@Component({
	components: {
		regionRow,
	},
})
export default class Region extends Vue {
	private regionoffering = {} as DB.RegionOfferingModel;

	private vatInput = false

	private regionInputHidden = false

	private isLoading = false

	private selected = false

	private vat = ''

	private componentLoaded = false;

	@Prop()
	edited!: DB.OfferingModel

	private isActive = false

	// getter for authenticated user
	get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	@Regions.Getter
	private regionsState!: Array<DB.RegionModel>;

	@Regions.Getter
	private checkregion!: Record<number, DB.RegionOfferingModel>;

	async created(): Promise<void> {
		try {
			this.isLoading = true;
			this.$store.dispatch('Regions/region');
			this.$store.dispatch('Regions/callRegions',
				true);
		} catch (error: any) {
			this.$bvToast.toast(`${error.message}`,
				{
					solid: true,
					variant: 'danger',
				});
		} finally {
			this.isLoading = false;
		}
	}

	// Update the Vat price when the input changes
	@Watch('loggedIn')
	updateVat(): void {
		if (this.loggedIn) {
			httpClient
				.put(`/api/regionoffering/${this.regionoffering.id}`,
					{
						vat: this.regionoffering.vat,
					}).then((response) => response.data)
				.catch((err) => {
					this.$bvToast.toast(`${err.message}`,
						{
							solid: true,
							variant: 'danger',
						});
				});
		}
	}
}
