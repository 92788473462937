import { Vue, Component, Watch } from 'vue-property-decorator';
import * as DB from 'interfaces/database';
import { PickerOptions, PickerResponse } from 'filestack-js';
import { httpClient } from '../../utils/http';

@Component({})
export default class EditModel3dTable extends Vue {
	private singleModel3d = {} as DB.Model3DModel;

	private loaded = false;

	private visible = false;

	private spinner = false;

	private glb = '';

	private hdr = '';

	private placeHolders = {
		hdr: 'Choose a file',
		glb: 'Choose a file',
	}

	private index = 0;

	private isLoading = false;

	private pageOptions = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50];

	get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	show(): void {
		this.visible = true;
	}

	handleHide(): void {
		this.visible = false;
	}

	showSingle(index: number): void {
		this.index = index;
		this.show();
	}

	mounted(): void {
		this.getSingleModel3d();
	}

	@Watch('loggedIn')
	async getSingleModel3d(): Promise<DB.Model3DModel | undefined> {
		if (this.loggedIn) {
			// set the loading state
			this.isLoading = true;
			try {
				const resp = await httpClient.get<DB.Model3DModel>(`/api/model3d/${this.$route.params.id}`);
				this.singleModel3d = resp.data;
				this.glb = resp.data.sceneUrl;
				this.hdr = resp.data.envTextureUrl !== null ? resp.data.envTextureUrl : '';
				// eslint-disable-next-line consistent-return
				return this.singleModel3d;
			} catch (error: any) {
				this.$bvToast.toast(`${error.message}`,
					{
						solid: true,
						variant: 'danger',
					});
			} finally {
				this.isLoading = false;
			}
		}
		return undefined;
	}

	editModel3dImage(): Promise<void> | undefined {
		if (this.loggedIn) {
			this.isLoading = true;
			httpClient.put<DB.Model3DModel>(`/api/model3d/${this.$route.params.id}`,
				{ ...this.singleModel3d }).then(() => {
				this.$bvToast.toast('3D Model Edited successfully',
					{
						solid: true,
						variant: 'success',
					});
				return undefined;
			})
				.finally(() => {
					this.isLoading = false;
				})
				.catch((error) => {
					this.$bvToast.toast(`${error.message}`,
						{
							solid: true,
							variant: 'danger',
						});
				});
		}
		return undefined;
	}

	//  update image preview
	async updateGlbPreview(): Promise<void> {
		const options: PickerOptions = {
			fromSources: ['local_file_system'],
			onUploadDone: async (files: PickerResponse) => {
				this.singleModel3d.sceneUrl = files.filesUploaded[0].url;
				this.placeHolders.glb = files.filesUploaded[0].filename;
				this.glb = files.filesUploaded[0].filename;
			},
		};
		this.$client.picker(options).open();
	}

	//  update image preview
	async updateHdrPreview(): Promise<void> {
		const options: PickerOptions = {
			fromSources: ['local_file_system'],
			onUploadDone: async (files: PickerResponse) => {
				this.singleModel3d.envTextureUrl = files.filesUploaded[0].url;
				this.placeHolders.hdr = files.filesUploaded[0].filename;
				this.hdr = files.filesUploaded[0].filename;
			},
		};
		this.$client.picker(options).open();
	}
}
