var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "footer",
    { attrs: { id: "page-footer" } },
    [
      _vm._t("default", function() {
        return [
          _c("div", { staticClass: "bg-white" }, [
            _c(
              "div",
              { staticClass: "content py-3" },
              [
                _c(
                  "b-row",
                  { staticClass: "font-size-sm text-muted font-w500" },
                  [
                    _c(
                      "b-col",
                      {
                        staticClass: "py-1 text-center text-sm-right",
                        attrs: { sm: "6", "order-sm": "2" }
                      },
                      [
                        _vm._v("\n\t\t\t\t\t\tCrafted with "),
                        _c("i", { staticClass: "fa fa-heart text-danger" }),
                        _vm._v(" by\n\t\t\t\t\t\t"),
                        _c(
                          "a",
                          {
                            staticClass: "font-w600",
                            attrs: {
                              href: "https://1.envato.market/5Noyb",
                              target: "_blank"
                            }
                          },
                          [_vm._v("pixelcave")]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-col",
                      {
                        staticClass: "py-1 text-center text-sm-left",
                        attrs: { sm: "6", "order-sm": "1" }
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "font-w600",
                            attrs: {
                              href: "https://1.envato.market/5Noyb",
                              target: "_blank"
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$store.getters.appName +
                                  " " +
                                  _vm.$store.getters.appVersion
                              )
                            )
                          ]
                        ),
                        _vm._v(
                          "\n\t\t\t\t\t\t© " +
                            _vm._s(_vm.$store.getters.appCopyright) +
                            "\n\t\t\t\t\t"
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }