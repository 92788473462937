import { Vue, Component } from 'vue-property-decorator';
import OrderItemTable from 'components/OrderItemTable/template.vue';

@Component({
	components: {
		OrderItemTable,
	},
})
export default class Orderitem extends Vue {
	private name = 'OrderItem'

	private orderItemId = this.$route.params.orderItemId
}
