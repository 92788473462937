import { Vue, Component } from 'vue-property-decorator';
import CountryTable from 'components/CountryTable/template.vue';

@Component({
	components: {
		CountryTable,
	},
})
export default class Country extends Vue {
	routeCountry(id: string): void {
		this.$router.push(`/country/${id}`);
	}
}
