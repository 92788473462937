import {
	Vue, Component, Watch, Prop, Ref,
} from 'vue-property-decorator';
import * as DB from 'interfaces/database';
import { PickerOptions, PickerResponse } from 'filestack-js';
import mitt from 'mitt';
import SwitchBox from 'components/Globals/SwitchBox';
import EditAndDeleteBtn from 'components/Globals/AgGridBtn';
import ImageComponent from 'components/Globals/ImageComponent.vue';
import { httpClient } from 'utils/http';
import { TabEvent } from 'interfaces/app';
import { CellComponentExtended, ColumnDefinitionExtended, TabulatorFull as Tabulator } from 'tabulator-tables';
import { createInstance } from 'utils/vue';
import TabulatorSwitchBox from 'components/Tabulator/TabulatorSwitchBox';
import ProductCategoriesTable from 'components/ProductCategoriesTable/';
import Template from './template.vue';

const eventBus = mitt<TabEvent<DB.OfferingModel>>();
@Component({
	components: {
		SwitchBox,
		ImageComponent,
		EditAndDeleteBtn,
		ProductCategoriesTable,
	},
})
export default class EditProductCategoriesTable extends Vue.extend(Template) {
	@Ref('offeringTable')
	private readonly tableReference!: HTMLDivElement;

	private spinner = false;

	private visible = false;

	private selected = false;

	private index = 0;

	private subCategory: DB.ProductCategoryModel[] = [];

	private subcategoryDisplay = false;

	private offeringDisplay = false;

	private existingCategoryDisplay = false;

	private pdp: DB.PDPModel[] = [];

	private langid = '';

	private totalRows = 1;

	private currentPage = 1;

	private perPage = 10;

	private table?: Tabulator;

	private language : DB.LanguageModel[] = [];

	private productcategory: DB.ProductCategoryModel[] = [];

	private singleProductCategory = {} as DB.ProductCategoryModel;

	private offerings:	DB.OfferingModel[] = [];

	private checkProductCategory = {} as Record<string, DB.ProductCategoryModel>;

	private columnDefs: ColumnDefinitionExtended[] = [];

	private rowData: DB.OfferingModel[] = []

	private placeHolders = {
		thumbnailPlaceholder: 'Choose a file',
	}

	private filterSubCatergory = null;

	private categorySearch = '';

	private isLoading = false;

	private pageOptions = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50];

	@Prop({
		type: String,
		required: true,
	})
	public readonly routeId!: string

	private get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	public beforeMount(): void {
		this.columnDefs = [
			{
				field: 'id',
				title: 'Offering ID',
				headerFilter: true,
			},
			{
				title: 'Title',
				field: 'name',
				visible: true,
				mutatorData: (val, data) => {
					let label = val;
					if (data.description) {
						label += ` | ${data.description}`;
					}
					if (data.variantname) {
						label += ` | ${data.variantname}`;
					}
					if (data.size) {
						label += ` | ${data.size}`;
					}
					return label;
				},
				headerFilter: true,
			},
			{
				title: 'Available In Product Category',
				formatter: (cell: CellComponentExtended<DB.OfferingModel>) => {
					const data = cell.getData();
					const instance = createInstance({
						component: TabulatorSwitchBox,
						props: {
							checked: Boolean(data && this.checkProductCategory[data.id]),
							data,
							eventBus,
						},
					});

					instance.$mount();
					return (instance.$el as HTMLElement);
				},
			},
		];
	}

	public mounted(): void {
		eventBus.on('switchBoxChanged',
			this.switchBoxChanged);
		this.tableInitialization();
		this.fetchData();
		this.onGridReadyProductCategory();
		this.$nextTick(() => {
			this.getProductCategoryOffering();
		});
	}

	private tableInitialization(): void {
		this.table = new Tabulator(this.tableReference,
			{
				height: '60vh',
				layout: 'fitColumns',
				columns: this.columnDefs,
			});
	}

	private switchBoxChanged(data: TabEvent<DB.OfferingModel>['switchBoxChanged']): void {
		this.table?.alert('Loading');
		if (data.event) {
			httpClient
				.post('/api/productcategoryoffering',
					{
						productcategoryid: parseInt(this.routeId,
							10),
						offeringid: data.params.id,
					})
				.then((response) => {
					this.checkProductCategory[response.data.offeringid] = response.data;
					return undefined;
				})
				.finally(() => {
					this.table?.clearAlert();
				}).catch((err) => {
					this.$bvToast.toast(`${err.message}`,
						{
							solid: true,
							variant: 'danger',
						});
				});
		} else {
			httpClient
				.delete(`/api/productcategoryoffering/${this.checkProductCategory[data.params.id].id}`)
				.then(() => {
					delete this.checkProductCategory[data.params.id];
					return undefined;
				})
				.finally(() => {
					this.table?.clearAlert();
				}).catch((err) => {
					this.$bvToast.toast(`${err.message}`,
						{
							solid: true,
							variant: 'danger',
						});
				});
		}
	}

	public beforeDestroy() {
		eventBus.off('switchBoxChanged',
			this.switchBoxChanged);
		this.table?.destroy();
	}

	private onGridReadyProductCategory(): void {
		const parameter = new URLSearchParams({
			limit: '0',
		});

		this.table?.alert('Loading');
		httpClient.get<DB.OfferingModel[]>(`/api/offering?${parameter}`)
			.then((res) => {
				this.rowData = res.data;
				this.table?.setData(this.rowData);
				return undefined;
			})
			.finally(() => this.table?.clearAlert())
			.catch((error) => {
				this.$bvToast.toast(`${error.message}`,
					{
						solid: true,
						variant: 'danger',
					});
			});
	}

	// eslint-disable-next-line class-methods-use-this
	private convertArrayToObject(array: (DB.ProductCategoryOfferingModel)[]): Record<string, DB.ProductCategoryModel> {
		return array.reduce((obj, item) => ({
			...obj,
			[item.offeringid]: item,
		}),
		{});
	}

	@Watch('loggedIn')
	private async getProductCategoryOffering(): Promise<void> {
		if (this.loggedIn) {
			const params = new URLSearchParams({
				where: JSON.stringify({ productcategoryid: this.routeId }),
				limit: '0',
			});

			try {
				const resp = await httpClient.get<DB.ProductCategoryOfferingModel[]>(`/api/productcategoryoffering?${params}`);
				this.checkProductCategory = this.convertArrayToObject(resp.data);
			} catch (error: any) {
				this.$bvToast.toast(`${error.message}`,
					{
						solid: true,
						variant: 'danger',
					});
			}
		}
		return undefined;
	}

	protected editSingleProductCategory(): Promise<void> | undefined {
		if (this.loggedIn) {
			this.spinner = true;
			httpClient.put<DB.ProductCategoryModel>(`/api/productcategory/${this.routeId}`,
				{
					...this.singleProductCategory,
				}).then(() => {
				this.$bvToast.toast('Product category Edited successfully',
					{
						solid: true,
						variant: 'success',
					});
				return undefined;
			})
				.finally(() => {
					this.spinner = false;
				})
				.catch((error) => {
					this.$bvToast.toast(`${error.message}`,
						{
							solid: true,
							variant: 'danger',
						});
				});
		}
		return undefined;
	}

	@Watch('loggedIn')
	private fetchData(): void {
		if (this.loggedIn) {
			const params = new URLSearchParams({
				limit: '0',
			});
			// set the loading state
			this.spinner = true;
			Promise.all([
				httpClient.get<DB.ProductCategoryModel>(`/api/productcategory/${this.routeId}`),
				httpClient.get<DB.PDPModel[]>(`/api/pdp?${params}`),
				httpClient.get<DB.LanguageModel[]>(`/api/language?${params}`),
				httpClient.get<DB.ProductCategoryModel[]>(`/api/productcategory?${params}`),
			])
				.then(([productCategoryResponse, pdpResponse, languageResponse, ProductResponse]) => {
					this.singleProductCategory = productCategoryResponse.data;
					this.pdp = pdpResponse.data;
					this.language = languageResponse.data;
					this.productcategory = ProductResponse.data.map((i) => i);
					return null;
				}).finally(() => {
					this.spinner = false;
				})
				.catch((error) => {
					this.$bvToast.toast(`${error.message}`,
						{
							solid: true,
							variant: 'danger',
						});
				});
		}
		return undefined;
	}

	//  update image preview
	protected async updateThumbnailPreview(): Promise<void> {
		const options: PickerOptions = {
			fromSources: ['local_file_system'],
			onUploadDone: async (files: PickerResponse) => {
				this.singleProductCategory.thumbnail = files.filesUploaded[0].url;
				this.placeHolders.thumbnailPlaceholder = files.filesUploaded[0].filename;
			},
		};
		this.$client.picker(options).open();
	}

	// remove the glb preview
	protected removeThumbnail(): void {
		this.singleProductCategory.thumbnail = '';
	}
}
