import {
	Vue, Component, Ref,
} from 'vue-property-decorator';
import mitt from 'mitt';
import { TabEvent } from 'interfaces/app';
import { httpClient } from 'utils/http';
import { CellComponentExtended, ColumnDefinitionExtended, TabulatorFull as Tabulator } from 'tabulator-tables';
import TabulatorBtn from 'components/Tabulator/TabulatorBtn';
import { createInstance } from 'utils/vue';
import * as DB from 'interfaces/database';
import Template from './template.vue';

const eventBus = mitt<TabEvent<DB.ApplicationModel>>();
@Component({
	components: {
		TabulatorBtn,
	},
})
export default class ApiPermissionsTable extends Vue.extend(Template) {
	@Ref('apiPermissionsTable')
	private readonly tableReference!: HTMLDivElement;

	private columnDefs: ColumnDefinitionExtended[] = [];

	private table?: Tabulator;

	private isLoading = false;

	private get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	protected beforeMount(): void {
		this.columnDefs = [
			{
				field: 'partner',
				title: 'Partner',
				headerFilter: true,
			},
			{
				title: 'IP address',
				field: 'ipaddress',
				headerFilter: true,
			},
			{
				title: 'Actions',
				formatter: (cell: CellComponentExtended<DB.ApplicationModel>) => {
					const instance = createInstance({
						component: TabulatorBtn,
						props: {
							data: cell.getData(),
							buttons: [
								{
									id: 'edit',
									eventName: 'editItem',
									className: 'fa-edit',
								},
							],
							eventBus,
						},
					});
					instance.$mount();

					return (instance.$el as HTMLElement);
				},
			},
		];
	}

	protected mounted(): void {
		eventBus.on('editItem',
			this.editApiPermission);
		this.tableInitialization();
		this.table?.on('tableBuilt',
			this.onTableBuilt);
	}

	protected beforeDestroy(): void {
		eventBus.off('editItem',
			this.editApiPermission);
		this.table?.off('tableBuilt',
			this.onTableBuilt);
		this.table?.destroy();
	}

	private onTableBuilt(): void {
		this.getData();
	}

	private tableInitialization(): void {
		this.table = new Tabulator(this.tableReference,
			{
				height: '60vh',
				layout: 'fitColumns',
				columns: this.columnDefs,
			});
	}

	private async getData(): Promise<void> {
		const parameter = new URLSearchParams({
			limit: '0',
		});
		this.table?.alert('Loading');
		try {
			const { data } = await httpClient.get<DB.ApplicationModel[]>(`/api/application?${parameter}`);
			this.table?.setData(data);
		} catch (err: any) {
			this.$bvToast.toast(`${err.message}`,
				{
					solid: true,
					variant: 'danger',
				});
		} finally {
			this.table?.clearAlert();
		}
	}

	private editApiPermission(data: TabEvent<DB.ApplicationModel>['editItem']): void {
		this.$emit('routeApiPermission',
			data.id);
	}
}
