import {
	Vue, Component, Watch, Prop, Ref,
} from 'vue-property-decorator';
import { AgGridVue } from 'ag-grid-vue';
import * as DB from 'interfaces/database';
import mitt from 'mitt';
import SwitchBox from 'components/Globals/SwitchBox';
import { httpClient } from 'utils/http';
import { TabEvent } from 'interfaces/app';
import { TabulatorFull as Tabulator, CellComponentExtended, ColumnDefinitionExtended } from 'tabulator-tables';
import TabulatorSwitchBox from 'components/Tabulator/TabulatorSwitchBox';
import { createInstance } from 'utils/vue';
import Template from './template.vue';

const eventBus = mitt<TabEvent<DB.ProductCategoryModel>>();
@Component({
	components: {
		AgGridVue,
		SwitchBox,
	},
})
export default class BadgeProductCategoryTable extends Vue.extend(Template) {
	@Ref('badgeProductCategories')
	private readonly tableReference!: HTMLDivElement;

	private pageOptions = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50];

	private checkProductCategory: Record<string, DB.BadgeProductCategoryModel> = {};

	private columnDefs: ColumnDefinitionExtended[] = [];

	private table?: Tabulator;

	private perPage = 20;

	@Prop()
	private readonly routeId!: string;

	private get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	beforeMount(): void {
		this.columnDefs = [
			{ field: 'id', title: 'ID' },
			{
				title: 'Name',
				field: 'name',
				headerFilter: true,
			},
			{
				title: 'Available In region?',
				formatter: (cell: CellComponentExtended<DB.ProductCategoryModel>) => {
					const data = cell.getData();
					const instance = createInstance({
						component: TabulatorSwitchBox,
						props: {
							data,
							eventBus,
							checked: Boolean(data && this.checkProductCategory[data.id]),
						},
					});
					instance.$mount();
					return (instance.$el as HTMLElement);
				},
			},
		];
	}

	mounted(): void {
		this.getBadgeProductCategory();
		eventBus.on('switchBoxChanged',
			this.switchBoxChanged);
		this.table = new Tabulator(this.tableReference,
			{
				height: '60vh',
				layout: 'fitColumns',
				columns: this.columnDefs,
			});
		this.getData();
	}

	private async getData(): Promise<void> {
		const parameter = new URLSearchParams({
			limit: '0',
		});
		await this.getBadgeProductCategory();
		this.table?.alert('Loading');
		try {
			const { data } = await httpClient.get<DB.ProductCategoryModel[]>(`/api/productcategory?${parameter}`);
			// set table data
			this.table?.setData(data);
		} catch (error: any) {
			this.$bvToast.toast(`${error.message}`,
				{
					solid: true,
					variant: 'danger',
				});
		} finally {
			this.table?.clearAlert();
		}
	}

	switchBoxChanged(data: TabEvent<DB.ProductCategoryModel>['switchBoxChanged']): void {
		this.table?.alert('Loading');
		if (data.event) {
			httpClient
				.post('/api/badgeproductcategory',
					{
						productcategoryid: data.params.id,
						badgeid: parseInt(this.routeId,
							10),
					})
				.then((response) => {
					this.checkProductCategory[response.data.productcategoryid] = response.data;
					this.table?.redraw();
					return undefined;
				})
				.finally(() => {
					this.table?.clearAlert();
				}).catch((err) => {
					this.$bvToast.toast(`${err.message}`,
						{
							solid: true,
							variant: 'danger',
						});
				});
		} else {
			httpClient
				.delete(`/api/badgeproductcategory/${this.checkProductCategory[data.params.id].id}`)
				.then(() => {
					delete this.checkProductCategory[data.params.id];
					this.table?.redraw();
					return undefined;
				})
				.finally(() => {
					this.table?.clearAlert();
				}).catch((err) => {
					this.$bvToast.toast(`${err.message}`,
						{
							solid: true,
							variant: 'danger',
						});
				});
		}
	}

	beforeDestroy() {
		eventBus.off('switchBoxChanged',
			this.switchBoxChanged);
		this.table?.destroy();
	}

	@Watch('loggedIn')
	async getBadgeProductCategory(): Promise<void> {
		if (this.loggedIn) {
			const params = new URLSearchParams({
				where: JSON.stringify({ badgeid: this.routeId }),
				limit: '0',
			});

			try {
				const resp = await httpClient.get<DB.BadgeProductCategoryModel[]>(`/api/badgeproductcategory?${params}`);
				this.checkProductCategory = resp.data.reduce((obj, item) => ({
					...obj,
					[item.productcategoryid]: item,
				}),
				{});
			} catch (error: any) {
				this.$bvToast.toast(`${error.message}`,
					{
						solid: true,
						variant: 'danger',
					});
			}
		}
		return undefined;
	}
}
