var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-block",
    {
      staticClass: "mt-4",
      attrs: { title: "External Sources", "header-bg": "" }
    },
    [_c("div", { ref: "externalSourcesTable" })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }