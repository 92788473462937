import { ICellRendererParams } from 'ag-grid-community';
import { PickerOptions, PickerResponse } from 'filestack-js';
import { httpClient } from 'utils/http';
import { Vue, Component } from 'vue-property-decorator';

@Component({})
export default class ImageComponent extends Vue {
	private params!: ICellRendererParams & { url: string, size: number, offering: boolean, pagination: boolean };

	renderImage = ''

	value = 0;

	size = 0;

	beforeMount(): void {
		this.updateImage();
	}

	updateImage(): void {
		this.value = this.params.value;
		this.size = this.params.size || 200;
	}

	editImage():void {
		const options: PickerOptions = {
			fromSources: ['local_file_system'],
			onUploadDone: async (files: PickerResponse) => {
				// send an edit request to the API
				this.params.api.showLoadingOverlay();
				httpClient.put(
					`/api/${this.params.url}/${this.params.data.id}`,
					{
						thumbnail: files.filesUploaded[0].url,
					},
				)
					.then(() => {
						if (this.params.pagination === true) {
							this.params.api.refreshInfiniteCache();
						} else {
							const selectedNode = this.params.node;
							const selectedData = selectedNode.data;
							this.params.api.applyTransaction({ update: [selectedData] });
							this.params.api.redrawRows();
						}
						this.params.api.hideOverlay();
						return null;
					})
					.catch((err) => {
						this.$bvToast.toast(`${err.message}`,
							{
								solid: true,
								variant: 'danger',
							});
					});
			},
		};
		this.$client.picker(options).open();
	}
}
