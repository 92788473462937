import { Vue, Component, Ref } from 'vue-property-decorator';
import EditPhotoframeTable from 'components/Themes/PhotoframeTable/EditPhotoframeTable';
import Template from './template.vue';

@Component({
	components: {
		EditPhotoframeTable,
	},
})
export default class EditPhotoframe extends Vue.extend(Template) {
	@Ref('editphotoframe')
	private readonly photoframe!: HTMLFormElement;

	private routeId = this.$route.params.id;

	protected updatePhotoframeProperties(): void {
		this.photoframe.editPhotoframe();
	}
}
