var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-button",
    {
      staticClass: "m-2",
      attrs: { variant: "outline-secondary" },
      on: {
        click: function($event) {
          return _vm.showCreditHistory(_vm.params, _vm.value)
        }
      }
    },
    [
      _c("i", { staticClass: "fa fa-fw fa-history" }),
      _vm._v("\n\tshow credit history\n")
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }