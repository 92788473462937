var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ml-5", staticStyle: { width: "95%" } },
    [
      _c("loading-overlay", {
        attrs: { active: _vm.isLoaded, "can-cancel": true }
      }),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "template-modal",
          attrs: { id: "template-modal", "hide-footer": "" },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function() {
                return [_vm._v("\n\t\t\tCreate New Template\n\t\t")]
              },
              proxy: true
            }
          ])
        },
        [
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-block text-center" },
            [
              _c(
                "b-form-group",
                {
                  staticClass: "form-group",
                  attrs: { label: "Name: ", "label-for": "block-form1-name" }
                },
                [
                  _c("b-form-input", {
                    attrs: { type: "text" },
                    model: {
                      value: _vm.addTemplateData.name,
                      callback: function($$v) {
                        _vm.$set(_vm.addTemplateData, "name", $$v)
                      },
                      expression: "addTemplateData.name"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { block: "" },
              on: {
                click: function($event) {
                  return _vm.addTemplate()
                }
              }
            },
            [_vm._v("\n\t\t\tSave\n\t\t")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "import-modal",
          attrs: { id: "import-modal", "hide-footer": "" },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function() {
                return [_vm._v("\n\t\t\tImport Template\n\t\t")]
              },
              proxy: true
            }
          ])
        },
        [
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-block text-center" },
            [
              _c(
                "b-form-group",
                { attrs: { "label-for": "thumbnail", "label-cols-lg": "4" } },
                [
                  _c("b-form-file", {
                    ref: "fileimport",
                    attrs: {
                      id: "thumbnail",
                      placeholder: _vm.placeHolders.icon
                    },
                    nativeOn: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.uploadFile.apply(null, arguments)
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "base-block",
        { attrs: { title: "Existing Template Categories", "header-bg": "" } },
        [
          _c(
            "b-row",
            { staticClass: "d-flex flex-column justify-content-left" },
            [
              _c(
                "b-col",
                { staticClass: "my-1", attrs: { sm: "5", md: "9" } },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "mt-3",
                      attrs: { disabled: _vm.selectedRows.length == 0 },
                      on: {
                        click: function($event) {
                          return _vm.table.deselectRow()
                        }
                      }
                    },
                    [_vm._v("\n\t\t\t\t\tDeselect all\n\t\t\t\t")]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      staticClass: "mt-3",
                      attrs: { disabled: _vm.selectedRows.length == 0 },
                      on: {
                        click: function($event) {
                          return _vm.exportSelected()
                        }
                      }
                    },
                    [_vm._v("\n\t\t\t\t\tExport Selected\n\t\t\t\t")]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      staticClass: "mt-3",
                      on: {
                        click: function($event) {
                          return _vm.exportAll()
                        }
                      }
                    },
                    [_vm._v("\n\t\t\t\t\tExport all\n\t\t\t\t")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { ref: "templateTable" })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }