import {
	Component,
	Ref,
	Vue,
} from 'vue-property-decorator';
import { BModal } from 'bootstrap-vue';
import mitt from 'mitt';
import Swal from 'sweetalert2';
import { TabEvent } from 'interfaces/app';
import { httpClient } from 'utils/http';
import * as DB from 'interfaces/database';
import 'vue-loading-overlay/dist/vue-loading.css';
import { CellComponentExtended, ColumnDefinitionExtended, TabulatorFull as Tabulator } from 'tabulator-tables';
import { createInstance } from 'utils/vue';
import TabulatorBtn from 'components/Tabulator/TabulatorBtn';
import Template from './template.vue';

const eventBus = mitt<TabEvent<DB.BorderImageModel>>();
@Component({
	components: {
		TabulatorBtn,
	},
})
export default class PhotoframeTable extends Vue.extend(Template) {
	@Ref('photoframe-modal')
	private readonly modal!: BModal;

	@Ref('photoframeTable')
	private readonly tableReference!: HTMLDivElement;

	protected isLoaded = false;

	private columnDefs: ColumnDefinitionExtended[] = [];

	private table?: Tabulator;

	private addPhotoframeData = {} as Pick<DB.BorderImageModel, 'name'>;

	private get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	protected beforeMount(): void {
		this.columnDefs = [
			{
				title: 'Source',
				field: 'source',
				formatter: 'image',
				formatterParams: {
					height: 150,
					width: 150,
				},
			},
			{
				field: 'name',
				title: 'Name',
				headerFilter: true,
			},
			{
				title: 'Actions',
				formatter: (cell: CellComponentExtended<DB.BorderImageModel>) => {
					const instance = createInstance({
						component: TabulatorBtn,
						props: {
							data: cell.getData(),
							buttons: [
								{
									id: 'edit',
									eventName: 'editItem',
									className: 'fa-edit',
								},
								{
									id: 'delete',
									eventName: 'deleteItem',
									className: 'fa-times',
								},
							],
							eventBus,
						},
					});
					instance.$mount();

					return (instance.$el as HTMLElement);
				},
			},
		];
	}

	protected mounted(): void {
		eventBus.on('deleteItem',
			this.deletePhotoframe);
		eventBus.on('editItem',
			this.editPhotoframe);
		this.tableInitialization();
		this.table?.on('tableBuilt',
			this.onTableBuilt);
	}

	protected beforeDestroy(): void {
		eventBus.off('deleteItem',
			this.deletePhotoframe);
		eventBus.off('editItem',
			this.editPhotoframe);
		this.table?.off('tableBuilt',
			this.onTableBuilt);
		this.table?.destroy();
	}

	private onTableBuilt(): void {
		this.getData();
	}

	private tableInitialization(): void {
		this.table = new Tabulator(this.tableReference,
			{
				height: '60vh',
				layout: 'fitColumns',
				columns: this.columnDefs,
				// create a context menu to open row on a new tab
				rowContextMenu: [
					{
						label: 'Open in new tab',
						action: (e, row) => {
							const data = row.getData();
							this.$emit('openInNewTab',
								data.id);
						},
					},
				],
			});
	}

	private async getData(): Promise<void> {
		const parameter = new URLSearchParams({
			limit: '0',
		});
		this.table?.alert('Loading');
		try {
			const { data } = await httpClient.get<DB.BorderImageModel[]>(`/api/borderimage?${parameter}`);
			this.table?.setData(data);
		} catch (err: any) {
			this.$bvToast.toast(`${err.message}`,
				{
					solid: true,
					variant: 'danger',
				});
		} finally {
			this.table?.clearAlert();
		}
	}

	protected addPhotoframe(): void {
		this.table?.alert('Loading..');
		httpClient.post('/api/borderimage',
			{
				name: this.addPhotoframeData.name,
			}).then((res) => {
			this.$bvToast.toast('Photo frame Added',
				{
					solid: true,
					variant: 'success',
				});
			this.$emit('routePhotoframe',
				res.data.id);

			return undefined;
		})
			.finally(() => {
				this.table?.clearAlert();
			})
			.catch((err) => {
				this.$bvToast.toast(`${err.message}`,
					{
						solid: true,
						variant: 'danger',
					});
			});
	}

	private editPhotoframe(data: TabEvent<DB.BorderImageModel>['editItem']): void {
		this.$emit('routePhotoframe',
			data.id);
	}

	private deletePhotoframe(data: TabEvent<DB.BorderImageModel>['deleteItem']): void {
		Swal.fire({
			title: 'Are you sure?',
			text: 'You will not be able to recover this file!',
			icon: 'warning',
			showCancelButton: true,
			customClass: {
				confirmButton: 'btn btn-danger m-1',
				cancelButton: 'btn btn-secondary m-1',
			},
			confirmButtonText: 'Yes, delete it!',
			html: false,
		}).then((result) => {
			if (result.value) {
				this.table?.alert('Deleting..');
				// eslint-disable-next-line promise/no-nesting
				return httpClient.delete(`/api/photoframe/${data.id}`).then(() => {
					this.$bvToast.toast('Item Deleted',
						{
							solid: true,
							variant: 'success',
						});
					this.table?.deleteRow(data.id);
					return null;
				}).finally(() => {
					this.table?.clearAlert();
				}).catch((err) => {
					this.$bvToast.toast(`${err.message}`,
						{
							solid: true,
							variant: 'danger',
						});
				});
			}
			return undefined;
		}).catch((err) => {
			this.$bvToast.toast(`${err.message}`,
				{
					solid: true,
					variant: 'danger',
				});
		});
	}
}
