import { Vue, Component, Ref } from 'vue-property-decorator';
import * as DB from 'interfaces/database';
import { BModal } from 'bootstrap-vue';
import mitt from 'mitt';
import Swal from 'sweetalert2';
import { TabEvent } from 'interfaces/app';
import { httpClient } from 'utils/http';
import { CellComponentExtended, ColumnDefinitionExtended, TabulatorFull as Tabulator } from 'tabulator-tables';
import { createInstance } from 'utils/vue';
import TabulatorBtn from 'components/Tabulator/TabulatorBtn';

const eventBus = mitt<TabEvent<DB.PDPModel>>();
@Component({
	components: {
		TabulatorBtn,
	},
})
export default class PdpTable extends Vue {
	@Ref('addpdp-modal') readonly modal!: BModal;

	@Ref('pdpTable')
	private readonly tableReference!: HTMLDivElement;

	private name = '';

	private columnDefs: ColumnDefinitionExtended[] = [];

	private data = [];

	private table?: Tabulator;

	private isLoaded = false;

	private get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	protected beforeMount(): void {
		this.columnDefs = [
			{
				field: 'id',
				title: 'ID',
				headerFilter: true,
			},
			{
				title: 'Name',
				field: 'name',
				headerFilter: true,
			},
			{
				title: 'Actions',
				formatter: (cell: CellComponentExtended<DB.BadgeModel>) => {
					const instance = createInstance({
						component: TabulatorBtn,
						props: {
							data: cell.getData(),
							buttons: [
								{
									id: 'edit',
									eventName: 'editItem',
									className: 'fa-edit',
								},
								{
									id: 'delete',
									eventName: 'deleteItem',
									className: 'fa-times',
								},
							],
							eventBus,
						},
					});
					instance.$mount();

					return (instance.$el as HTMLElement);
				},
			},
		];
	}

	protected mounted(): void {
		eventBus.on('deleteItem',
			this.deletePdpItem);
		eventBus.on('editItem',
			this.editPdpItem);
		this.tableInitialization();
		this.table?.on('tableBuilt',
			this.onTableBuilt);
	}

	protected beforeDestroy(): void {
		eventBus.off('deleteItem',
			this.deletePdpItem);
		eventBus.off('editItem',
			this.editPdpItem);
		this.table?.off('tableBuilt',
			this.onTableBuilt);
		this.table?.destroy();
	}

	private tableInitialization(): void {
		this.table = new Tabulator(this.tableReference,
			{
				height: '60vh',
				layout: 'fitColumns',
				columns: this.columnDefs,
			});
	}

	private onTableBuilt(): void {
		this.getData();
	}

	private async getData(): Promise<void> {
		const parameter = new URLSearchParams({
			limit: '0',
		});
		this.table?.alert('Loading');
		try {
			const { data } = await httpClient.get<DB.PDPModel[]>(`/api/pdp?${parameter}`);
			this.table?.setData(data);
		} catch (err: any) {
			this.$bvToast.toast(`${err.message}`,
				{
					solid: true,
					variant: 'danger',
				});
		} finally {
			this.table?.clearAlert();
		}
	}

	protected addPdp(): void {
		// add a Pdp
		this.isLoaded = true;
		httpClient.post('/api/pdp',
			{ name: this.name }).then((res) => {
			this.modal.hide();
			this.$emit('routePdp',
				res.data.id);
			this.name = '';

			return undefined;
		}).finally(() => {
			this.isLoaded = false;
		})
			.catch((err) => {
				this.$bvToast.toast(`${err.message}`,
					{
						solid: true,
						variant: 'danger',
					});
			});
	}

	private editPdpItem(data: TabEvent<DB.PDPModel>['editItem']): void {
		this.$emit('routePdp',
			data.id);
	}

	private deletePdpItem(data: TabEvent<DB.PDPModel>['deleteItem']): void {
		Swal.fire({
			title: 'Are you sure?',
			text: 'You will not be able to recover this file!',
			icon: 'warning',
			showCancelButton: true,
			customClass: {
				confirmButton: 'btn btn-danger m-1',
				cancelButton: 'btn btn-secondary m-1',
			},
			confirmButtonText: 'Yes, delete it!',
			html: false,
		}).then((result) => {
			if (result.value) {
				this.table?.alert('Loading');
				// eslint-disable-next-line promise/no-nesting
				httpClient.delete(`/api/pdp/${data.id}`).then(() => {
					this.$bvToast.toast('Item Deleted',
						{
							solid: true,
							variant: 'success',
						});
					this.table?.deleteRow(data.id);
					return null;
				}).finally(() => {
					this.table?.clearAlert();
				}).catch((err) => {
					this.$bvToast.toast(`${err.message}`,
						{
							solid: true,
							variant: 'danger',
						});
				});
			}
			return undefined;
		}).catch((err) => {
			this.$bvToast.toast(`${err.message}`,
				{
					solid: true,
					variant: 'danger',
				});
		});
	}
}
