import { httpClient } from 'utils/http';
import {
	Vue, Component, Prop,
} from 'vue-property-decorator';
import * as DB from 'interfaces/database';
import Template from './template.vue';

@Component({
	components: {},
})
export default class ThemesCategories extends Vue.extend(Template) {
	private isLoading = false;

	private selectedCategories: DB.ThemeCategoryModel[] = []

	private availableCategories: DB.ThemeCategoryModel[] = []

	private currentItem = {} as DB.ThemeCategoryModel;

	private availableCategoryActiveIndex: null | number = null;

	private selectedCategoryActiveIndex: null | number = null;

	@Prop({
		type: Object,
		required: true,
	})
	public readonly singleTheme!: DB.ThemeModel

	get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	public mounted(): void {
		this.getThemeCategories();
	}

	protected selectedItem(data: DB.ThemeCategoryModel, index: number): void {
		this.availableCategoryActiveIndex = index;
		this.currentItem = data;
	}

	protected availableItem(data: DB.ThemeCategoryModel, index: number): void {
		this.selectedCategoryActiveIndex = index;
		this.currentItem = data;
	}

	private getThemeCategories(): void {
		this.isLoading = true;
		const params = new URLSearchParams({
			limit: '0',
		});

		const data = {} as Record<string, string | number>;
		// if the offering data is not empty, set the groupid and typeid to the selected language
		if (Object.keys(this.singleTheme).length !== 0) {
			data.groupid = this.singleTheme.groupid;
			data.typeid = this.singleTheme.typeid;
		}
		// append data to params
		params.append('where',
			JSON.stringify(data));
		Promise.all([
			httpClient.get<DB.ThemeCategoryModel[]>(`/api/theme/${this.$route.params.id}/categories`),
			httpClient.get<DB.ThemeCategoryModel[]>(`/api/themecategory?${params}`),
		])
			.then(([selectedTheme, AvailableTheme]) => {
				this.selectedCategories = selectedTheme.data;
				const combinedArray = [...AvailableTheme.data, ...selectedTheme.data];
				this.availableCategories = [...new Set(combinedArray)];
				return null;
			}).finally(() => {
				this.isLoading = false;
			})
			.catch((error) => {
				this.$bvToast.toast(`${error.message}`,
					{
						solid: true,
						variant: 'danger',
					});
			});
	}

	protected async addToSelected(): Promise<void> {
		this.isLoading = true;
		try {
			const themeLink = await httpClient.post('/api/themecategorylink',
				{
					themeid: parseInt(this.$route.params.id,
						10),
					categoryid: this.currentItem.id,
				});
			this.selectedCategories.push(this.currentItem);
			const findAndDelete = this.availableCategories.findIndex((obj) => obj.id === themeLink.data.categoryid);
			this.availableCategories.splice(findAndDelete,
				1);
		} catch (error: any) {
			this.$bvToast.toast(`${error.message}`,
				{
					solid: true,
					variant: 'danger',
				});
		} finally {
			this.isLoading = false;
		}
	}

	protected async removeFromSelected(): Promise<void> {
		this.isLoading = true;
		try {
			const themeLink = await httpClient.delete('/api/themeborderlink',
				{
					data: {
						themeid: parseInt(this.$route.params.id,
							10),
						categoryid: this.currentItem.id,
					},
				});
			this.availableCategories.push(this.currentItem);
			const findAndDelete = this.selectedCategories.findIndex((obj) => obj.id === themeLink.data.categoryid);
			this.selectedCategories.splice(findAndDelete,
				1);
		} catch (error: any) {
			this.$bvToast.toast(`${error.message}`,
				{
					solid: true,
					variant: 'danger',
				});
		} finally {
			this.isLoading = false;
		}
	}
}
