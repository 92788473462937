<template>
	<div
		id="page-container"
		:class="classContainer"
	>
		<!-- Page Loader -->
		<div
			id="page-loader"
			:class="{ show: $store.state.settings.pageLoader }"
		/>
		<!-- END Page Loader -->

		<!-- Page Overlay -->
		<div
			v-if="$store.state.layout.sideOverlay && $store.state.settings.pageOverlay"
			id="page-overlay"
			@click="() => $store.commit('sideOverlay', { mode: 'close' })"
		/>
		<!-- END Page Overlay -->

		<!-- Side Overlay -->
		<base-side-overlay
			v-if="$store.state.layout.sideOverlay"
			:class="layoutClasses.sideOverlay"
		>
			<slot name="side-overlay" />
		</base-side-overlay>
		<!-- END Side Overlay -->

		<!-- Sidebar -->
		<base-sidebar
			v-if="$store.state.layout.sidebar"
			:class="layoutClasses.sidebar"
		>
			<slot name="sidebar" />
		</base-sidebar>
		<!-- END Sidebar -->

		<!-- Header -->
		<base-header
			v-if="$store.state.layout.header"
			:class="layoutClasses.header"
		>
			<slot name="header" />
		</base-header>
		<!-- END Header -->

		<!-- Main Container -->
		<div id="main-container">
			<slot name="content" />
			<transition
				name="fade"
				mode="out-in"
			>
				<router-view />
			</transition>
		</div>
		<!-- END Main Container -->

		<!-- Footer -->
		<base-footer
			v-if="$store.state.layout.footer"
			:class="layoutClasses.footer || 'bg-body-light'"
		>
			<slot name="footer" />
		</base-footer>
		<!-- END Footer -->
	</div>
</template>

<script>
// Import main layout components
import BaseHeader from './partials/Header.vue';
import BaseSidebar from './partials/Sidebar.vue';
import BaseSideOverlay from './partials/SideOverlay.vue';
import BaseFooter from './partials/Footer.vue';

export default {
	name: 'BaseLayout',
	components: {
		BaseHeader,
		BaseSidebar,
		BaseSideOverlay,
		BaseFooter,
	},
	props: {
		layoutClasses: Object,
	},
	computed: {
		classContainer() {
			return {
				'sidebar-r':
     this.$store.state.layout.sidebar
     && !this.$store.state.settings.sidebarLeft,
				'sidebar-mini':
     this.$store.state.layout.sidebar && this.$store.state.settings.sidebarMini,
				'sidebar-o':
     this.$store.state.layout.sidebar
     && this.$store.state.settings.sidebarVisibleDesktop,
				'sidebar-o-xs':
     this.$store.state.layout.sidebar
     && this.$store.state.settings.sidebarVisibleMobile,
				'sidebar-dark':
     this.$store.state.layout.sidebar && this.$store.state.settings.sidebarDark,
				'side-overlay-o':
     this.$store.state.layout.sideOverlay
     && this.$store.state.settings.sideOverlayVisible,
				'side-overlay-hover':
     this.$store.state.layout.sideOverlay
     && this.$store.state.settings.sideOverlayHoverable,
				'enable-page-overlay':
     this.$store.state.layout.sideOverlay
     && this.$store.state.settings.pageOverlay,
				'page-header-fixed':
     this.$store.state.layout.header && this.$store.state.settings.headerFixed,
				'page-header-dark':
     this.$store.state.layout.header && this.$store.state.settings.headerDark,
				'main-content-boxed': this.$store.state.settings.mainContent === 'boxed',
				'main-content-narrow': this.$store.state.settings.mainContent === 'narrow',
				'rtl-support': this.$store.state.settings.rtlSupport,
				'side-trans-enabled': this.$store.state.settings.sideTransitions,
				'side-scroll': true,
			};
		},
	},
	created() {
		// Set default color theme
		this.$store.commit('setColorTheme',
			{
				theme: this.$store.getters.appColorTheme,
			});
	},
	mounted() {
		let winResize = false;

		// Remove side transitions on window resizing
		window.addEventListener('resize',
			() => {
				clearTimeout(winResize);

				this.$store.commit('setSideTransitions',
					{ transitions: false });

				winResize = setTimeout(() => {
					this.$store.commit('setSideTransitions',
						{ transitions: true });
				},
				500);
			});
	},
};
</script>

<style lang="scss">
// Custom router view transition
.fade-enter-active,
.fade-leave-active {
 transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
 opacity: 0;
}
</style>
