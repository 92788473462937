var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "base-block",
    { attrs: { rounded: "" } },
    [
      _vm.isLoading
        ? _c("div", { staticClass: "text-center" }, [
            _c(
              "div",
              {
                staticClass: "spinner-grow text-primary m-5",
                attrs: { role: "status" }
              },
              [_c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-table-simple",
        { attrs: { responsive: "", "table-class": "table-vcenter" } },
        [
          _c(
            "b-thead",
            { attrs: { "head-variant": "dark" } },
            [
              _c(
                "b-tr",
                [
                  _c("b-th", [_vm._v("Region")]),
                  _vm._v(" "),
                  _c("b-th", [_vm._v("Offering available")]),
                  _vm._v(" "),
                  _c("b-th", { staticClass: "text-center" }, [
                    _vm._v("\n\t\t\t\t\tVat\n\t\t\t\t")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm._l(_vm.regionsState, function(reg, index) {
            return _c(
              "b-tbody",
              { key: index },
              [
                _c("regionRow", {
                  attrs: {
                    reg: reg,
                    checkregion: _vm.checkregion,
                    edited: _vm.edited
                  }
                })
              ],
              1
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }