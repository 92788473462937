var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex align-items-center" },
    [
      _vm.text
        ? _c("span", { staticClass: "mr-2" }, [
            _vm._v("\n\t\t" + _vm._s(_vm.text) + "\n\t")
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("b-form-checkbox", {
        attrs: { switch: "", size: "lg", checked: _vm.checked },
        on: {
          change: function($event) {
            return _vm.onChange($event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }