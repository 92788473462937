var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _c("base-page-heading", {
        staticClass: "d-print-none",
        attrs: { title: "Layouts", subtitle: "" }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row" },
        [
          _c("LayoutTable", {
            on: { routeLayout: _vm.routeLayout, openInNewTab: _vm.openInNewTab }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }