var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "base-block",
    {
      attrs: { rounded: "", title: "Upsell items", "header-bg": "" },
      scopedSlots: _vm._u([
        {
          key: "options",
          fn: function() {
            return [
              _c(
                "b-button",
                {
                  attrs: { type: "submit", size: "sm", variant: "primary" },
                  on: {
                    click: function($event) {
                      _vm.isHidden = !_vm.isHidden
                    }
                  }
                },
                [_vm._v("\n\t\t\tAdd Upsell\n\t\t")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("loading-overlay", {
        attrs: { active: _vm.isLoading, "can-cancel": true }
      }),
      _vm._v(" "),
      _vm.spinner
        ? _c("div", { staticClass: "text-center" }, [
            _c(
              "div",
              {
                staticClass: "spinner-grow text-primary m-5",
                attrs: { role: "status" }
              },
              [_c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])]
            )
          ])
        : _c(
            "b-col",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isHidden,
                  expression: "isHidden"
                }
              ],
              attrs: { lg: "12" }
            },
            [
              _c(
                "b-form-group",
                { attrs: { label: "Name", "label-for": "example-text-input" } },
                [
                  _c("b-form-input", {
                    attrs: { placeholder: "Name" },
                    model: {
                      value: _vm.data.name,
                      callback: function($$v) {
                        _vm.$set(_vm.data, "name", $$v)
                      },
                      expression: "data.name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Description",
                    "label-for": "example-text-input"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: { placeholder: "Description" },
                    model: {
                      value: _vm.data.description,
                      callback: function($$v) {
                        _vm.$set(_vm.data, "description", $$v)
                      },
                      expression: "data.description"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Upsell Id",
                    "label-for": "example-text-input"
                  }
                },
                [
                  _c(
                    "b-form-select",
                    {
                      attrs: { id: "example-select" },
                      model: {
                        value: _vm.data.upsellid,
                        callback: function($$v) {
                          _vm.$set(_vm.data, "upsellid", $$v)
                        },
                        expression: "data.upsellid"
                      }
                    },
                    _vm._l(_vm.offerings, function(Off) {
                      return _c(
                        "b-form-select-option",
                        { key: Off.id, attrs: { value: Off.id } },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t" +
                              _vm._s(Off.name) +
                              " - " +
                              _vm._s(Off.description) +
                              "\n\t\t\t\t"
                          )
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-group",
                { attrs: { label: "Optional" } },
                [
                  _c("b-form-checkbox", {
                    attrs: { switch: "", size: "lg" },
                    model: {
                      value: _vm.data.optional,
                      callback: function($$v) {
                        _vm.$set(_vm.data, "optional", $$v)
                      },
                      expression: "data.optional"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-group",
                { attrs: { label: "Per item" } },
                [
                  _c("b-form-checkbox", {
                    attrs: { switch: "", size: "lg" },
                    model: {
                      value: _vm.data.peritem,
                      callback: function($$v) {
                        _vm.$set(_vm.data, "peritem", $$v)
                      },
                      expression: "data.peritem"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-group",
                { attrs: { label: "Auto Include" } },
                [
                  _c("b-form-checkbox", {
                    attrs: { switch: "", size: "lg" },
                    model: {
                      value: _vm.data.autoinclude,
                      callback: function($$v) {
                        _vm.$set(_vm.data, "autoinclude", $$v)
                      },
                      expression: "data.autoinclude"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: { type: "submit", variant: "primary" },
                  on: { click: _vm.addUpsell }
                },
                [_vm._v("\n\t\t\tSave\n\t\t")]
              )
            ],
            1
          ),
      _vm._v(" "),
      _vm._v(" "),
      _c(
        "b-table-simple",
        {
          staticClass: "mt-3",
          attrs: { responsive: "", "table-class": "table-vcenter" }
        },
        [
          _c(
            "b-thead",
            { attrs: { "head-variant": "dark" } },
            [
              _c(
                "b-tr",
                [
                  _c("b-th", { staticStyle: { "font-size": "10px" } }, [
                    _vm._v("\n\t\t\t\t\tOffering ID\n\t\t\t\t")
                  ]),
                  _vm._v(" "),
                  _c("b-th", { staticStyle: { "font-size": "10px" } }, [
                    _vm._v("\n\t\t\t\t\tDescription\n\t\t\t\t")
                  ]),
                  _vm._v(" "),
                  _c("b-th", { staticStyle: { "font-size": "10px" } }, [
                    _vm._v("\n\t\t\t\t\tOptional?\n\t\t\t\t")
                  ]),
                  _vm._v(" "),
                  _c("b-th", { staticStyle: { "font-size": "10px" } }, [
                    _vm._v("\n\t\t\t\t\tPer item?\n\t\t\t\t")
                  ]),
                  _vm._v(" "),
                  _c("b-th", { staticStyle: { "font-size": "10px" } }, [
                    _vm._v("\n\t\t\t\t\tAuto Include?\n\t\t\t\t")
                  ]),
                  _vm._v(" "),
                  _c("b-th", { staticStyle: { "font-size": "10px" } }, [
                    _vm._v("\n\t\t\t\t\tActions?\n\t\t\t\t")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm._l(_vm.upsell, function(up) {
            return _c(
              "b-tbody",
              { key: up.id },
              [
                _c("tableData", {
                  attrs: { up: up },
                  on: { deleteUpsell: _vm.deleteUpsellData }
                })
              ],
              1
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }