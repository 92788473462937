import { Vue, Component } from 'vue-property-decorator';
import { Event } from 'interfaces/app';
import { ICellRendererParams } from 'ag-grid-community';
import { Emitter } from 'mitt';
import Template from './template.vue';

export interface ButtonType {
	eventName: string,
	id: string,
	className: string,
}
@Component({})
export default class EditAndDeleteBtn extends Vue.extend(Template) {
	private params!: (ICellRendererParams & {
		buttons: ButtonType[],
		eventBus: Emitter<Event>
	}
	)

	onButtonClick(eventName: string): void {
		this.params.eventBus.emit(eventName as keyof Event,
			{
				id: this.params.data.id,
				params: this.params,
			});
	}
}
