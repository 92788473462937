import {
	Vue, Component, Watch, Prop, Ref,
} from 'vue-property-decorator';
import * as DB from 'interfaces/database';
import mitt from 'mitt';
import TabulatorSwitchBox from 'components/Tabulator/TabulatorSwitchBox';
import {
	CellComponentExtended,
	ColumnDefinitionExtended,
	TabulatorFull as Tabulator,
} from 'tabulator-tables';
import VatInput from 'components/Globals/VatInput.vue';
import { httpClient } from 'utils/http';
import { createInstance } from 'utils/vue';
import { TabEvent } from 'interfaces/app';
import Template from './template.vue';

const eventBus = mitt<TabEvent<DB.PDPModel>>();

@Component({
	components: {
		TabulatorSwitchBox,
		VatInput,
	},
})
export default class BadgePdpTable extends Vue.extend(Template) {
	@Prop()
	public readonly routeId!: string;

	private get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	@Ref('badgepdp')
	private readonly tableReference!: HTMLDivElement;

	private perPage = 20;

	private table?: Tabulator;

	private pageOptions = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50];

	private checkPdp: Record<string, DB.BadgePDPModel> = {};

	private columnDefs: ColumnDefinitionExtended[] = [];

	protected beforeMount(): void {
		this.columnDefs = [
			{
				field: 'id',
				title: 'ID',
			},
			{
				title: 'Name',
				field: 'name',
				headerFilter: true,
			},
			{
				title: 'Available In region?',
				formatter: (cell: CellComponentExtended<DB.PDPModel>) => {
					const data = cell.getData();
					const instance = createInstance({
						component: TabulatorSwitchBox,
						props: {
							data,
							eventBus,
							checked: Boolean(data && this.checkPdp[data.id]),
						},
					});
					instance.$mount();

					return (instance.$el as HTMLElement);
				},
			},
		];
	}

	protected mounted(): void {
		this.getBadgePdp();
		eventBus.on(
			'switchBoxChanged',
			this.switchBoxChanged,
		);
		this.table = new Tabulator(this.tableReference,
			{
				height: '60vh',
				layout: 'fitColumns',
				columns: this.columnDefs,
			});
		this.getData();
	}

	private async getData(): Promise<void> {
		const parameter = new URLSearchParams({
			limit: '0',
		});
		await this.getBadgePdp();
		this.table?.alert('Loading');
		try {
			const { data } = await httpClient.get<DB.PDPModel[]>(`/api/pdp?${parameter}`);
			// set table data
			this.table?.setData(data);
		} catch (error: any) {
			this.$bvToast.toast(`${error.message}`,
				{
					solid: true,
					variant: 'danger',
				});
		} finally {
			this.table?.clearAlert();
		}
	}

	private switchBoxChanged(data: TabEvent<DB.PDPModel>['switchBoxChanged']): void {
		this.table?.alert('Loading');
		const method = data.event ? 'post' : 'delete';
		const url = data.event ? '/api/badgepdp' : `/api/badgepdp/${this.checkPdp[data.params.id].id}`;
		const postData = (
			data.event
				? {
					pdpid: data.params.id,
					badgeid: parseInt(
						this.routeId,
						10,
					),
				}
				: undefined
		);
		httpClient[method](url,
			postData)
			.then((response) => {
				if (data.event) {
					this.checkPdp[response.data.pdpid] = response.data;
				} else {
					delete this.checkPdp[data.params.id];
				}
				this.table?.redraw();
				return undefined;
			})
			.finally(() => {
				this.table?.clearAlert();
			})
			.catch((err) => {
				this.$bvToast.toast(`${err.message}`,
					{ solid: true, variant: 'danger' });
			});
	}

	protected beforeDestroy() {
		eventBus.off(
			'switchBoxChanged',
			this.switchBoxChanged,
		);
		this.table?.destroy();
	}

	@Watch('loggedIn')
	private async getBadgePdp(): Promise<void> {
		if (this.loggedIn) {
			const params = new URLSearchParams({
				where: JSON.stringify({ badgeid: this.routeId }),
				limit: '0',
			});

			try {
				const resp = await httpClient.get<DB.BadgePDPModel[]>(`/api/badgepdp?${params}`);
				this.checkPdp = resp.data.reduce((obj, item) => ({
					...obj,
					[item.pdpid]: item,
				}),
				{});
			} catch (error: any) {
				this.$bvToast.toast(`${error.message}`,
					{
						solid: true,
						variant: 'danger',
					});
			}
		}
		return undefined;
	}
}
