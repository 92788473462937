var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "base-block",
    {
      attrs: { rounded: "", title: "Handling price" },
      scopedSlots: _vm._u([
        {
          key: "options",
          fn: function() {
            return [_c("div", { staticClass: "block-options-item" })]
          },
          proxy: true
        }
      ])
    },
    [
      _c("loading-overlay", {
        attrs: { active: _vm.isLoading, "can-cancel": true }
      }),
      _vm._v(" "),
      _vm._v(" "),
      _c(
        "b-table-simple",
        { attrs: { responsive: "", "table-class": "table-vcenter" } },
        [
          _c(
            "b-thead",
            { attrs: { "head-variant": "dark" } },
            [
              _c(
                "b-tr",
                [
                  _c("b-th", [_vm._v("Currency")]),
                  _vm._v(" "),
                  _c("b-th", [_vm._v("Price(in cents)")]),
                  _vm._v(" "),
                  _c(
                    "b-th",
                    {
                      staticClass: "text-center",
                      staticStyle: { width: "100px" }
                    },
                    [_vm._v("\n\t\t\t\t\tScope\n\t\t\t\t")]
                  ),
                  _vm._v(" "),
                  _c("b-th", [_vm._v("#")])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm._l(_vm.HandlingPrice, function(item, index) {
            return _c(
              "b-tbody",
              { key: index },
              [
                _c("handlingRow", {
                  attrs: { item: item },
                  on: { removeItem: _vm.removePrice }
                })
              ],
              1
            )
          }),
          _vm._v(" "),
          _c(
            "b-tfoot",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.footer,
                  expression: "footer"
                }
              ]
            },
            [
              _c(
                "b-tr",
                [
                  _c(
                    "b-td",
                    [
                      _c(
                        "b-form-group",
                        [
                          _c(
                            "b-form-select",
                            {
                              attrs: { id: "example-select", plain: "" },
                              model: {
                                value: _vm.currency,
                                callback: function($$v) {
                                  _vm.currency = $$v
                                },
                                expression: "currency"
                              }
                            },
                            _vm._l(_vm.currentCurrency, function(
                              currency,
                              index
                            ) {
                              return _c(
                                "b-form-select-option",
                                {
                                  key: index,
                                  attrs: { value: currency.currency }
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t" +
                                      _vm._s(currency.currency) +
                                      "\n\t\t\t\t\t\t\t"
                                  )
                                ]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-td",
                    [
                      _c(
                        "b-form-group",
                        { attrs: { "label-for": "example-text-input" } },
                        [
                          _c("b-form-input", {
                            attrs: { placeholder: "", value: _vm.price },
                            on: {
                              change: function($event) {
                                _vm.price = $event
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-td",
                    [
                      _c(
                        "b-form-group",
                        [
                          _c(
                            "b-form-select",
                            {
                              attrs: { id: "example-select" },
                              model: {
                                value: _vm.scope,
                                callback: function($$v) {
                                  _vm.scope = $$v
                                },
                                expression: "scope"
                              }
                            },
                            _vm._l(_vm.arrScopes, function(scope, index) {
                              return _c(
                                "b-form-select-option",
                                { key: index, attrs: { value: scope } },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t" +
                                      _vm._s(scope) +
                                      "\n\t\t\t\t\t\t\t"
                                  )
                                ]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-td",
                    [
                      _c(
                        "b-btn-group",
                        { staticClass: "mb-4" },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: { size: "sm", variant: "alt-primary" },
                              on: { click: _vm.add }
                            },
                            [_c("i", { staticClass: "fa fa-fw fa-save" })]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }