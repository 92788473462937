var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {},
    [
      _c("base-page-heading", {
        staticClass: "d-print-none",
        attrs: { title: "Country", subtitle: "" }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row" },
        [_c("CountryTable", { on: { routeCountry: _vm.routeCountry } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }