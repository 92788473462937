import { Vue, Component, Ref } from 'vue-property-decorator';
import EditCurrencyTable from 'components/CurrencyTable/EditCurrencyTable';
import Template from './template.vue';

@Component({
	components: {
		EditCurrencyTable,
	},
})
export default class EditCurrency extends Vue.extend(Template) {
	@Ref('editcurrency') readonly currencyModal!: HTMLFormElement;

	private isLoading = false;

	protected updateCurrencyProperties(): void {
		this.currencyModal.editCurrency();
	}
}
