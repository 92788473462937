var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _c(
        "div",
        { staticClass: "back-arrow pl-6 pt-2" },
        [
          _c("router-link", { attrs: { to: "/crosssell" } }, [
            _c("i", { staticClass: "fa fa-fw fa-arrow-left" }),
            _vm._v(" "),
            _c("span", [_vm._v(" Back to cross sell ")])
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c("base-page-heading", {
        staticClass: "d-print-none",
        attrs: { title: "Cross Sell Edit", subtitle: "" },
        scopedSlots: _vm._u([
          {
            key: "extra",
            fn: function() {
              return [
                _c(
                  "b-button",
                  {
                    ref: "btnShow",
                    attrs: { type: "submit", size: "lg", variant: "secondary" },
                    on: { click: _vm.updateCrossSellProperties }
                  },
                  [_vm._v("\n\t\t\t\tSave changes\n\t\t\t")]
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _c("loading-overlay", {
        attrs: { active: _vm.isLoading, "can-cancel": true }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row content" },
        [
          _c("EditCrossSellTable", {
            ref: "editcrosssell",
            attrs: { "route-id": _vm.routeId }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }