import { Vue, Component } from 'vue-property-decorator';
import ThemesTable from 'components/Themes/ThemesTable';
import Template from './template.vue';

@Component({
	components: {
		ThemesTable,
	},
})
export default class Themes extends Vue.extend(Template) {
	protected showModal(): void {
		this.$root.$emit('bv::show::modal',
			'theme-modal',
			'#themeShow');
	}

	protected showImport(): void {
		this.$root.$emit('bv::show::modal',
			'themeimport-modal',
			'#themeimportShow');
	}

	protected routeTheme(id: string): void {
		this.$router.push(`/theme/${id}`);
	}

	protected cloneTheme(id: string): void {
		this.$router.push(`/theme/${id}`);
	}

	protected openNewTab(id: string): void {
		const route = this.$router.resolve({ name: 'EditTheme', params: { id } });
		window.open(route.href,
			'_blank');
	}
}
