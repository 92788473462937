var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.type === "catalogue_base_price"
      ? _c(
          "div",
          { staticClass: "mt-2 mb-2" },
          _vm._l(_vm.data.currencies, function(curr, index) {
            return _c(
              "div",
              { key: index, staticClass: "baseinput mb-2" },
              [
                _c("b-form-input", {
                  directives: [
                    { name: "floatingPoint", rawName: "v-floatingPoint" }
                  ],
                  attrs: {
                    placeholder: "",
                    value: curr.price_base_from
                      ? parseFloat(curr.price_base_from)
                      : null
                  },
                  on: {
                    input: function($event) {
                      return _vm.updateValue(
                        _vm.data.currencies,
                        $event,
                        index,
                        "price_base_from"
                      )
                    },
                    blur: function($event) {
                      return _vm.onDataChange(_vm.data.currencies)
                    }
                  }
                })
              ],
              1
            )
          }),
          0
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.type === "catalogue_extra_pages"
      ? _c(
          "div",
          { staticClass: "mt-2 mb-2" },
          _vm._l(_vm.data.currencies, function(curr, index) {
            return _c(
              "div",
              { key: index, staticClass: "baseinput mb-2" },
              [
                _vm.data.maxpages > _vm.data.minprintpages
                  ? _c("b-form-input", {
                      directives: [
                        { name: "floatingPoint", rawName: "v-floatingPoint" }
                      ],
                      attrs: {
                        placeholder: "",
                        value: curr.price_page_from
                          ? curr.price_page_from
                          : null
                      },
                      on: {
                        input: function($event) {
                          return _vm.updateValue(
                            _vm.data.currencies,
                            $event,
                            index,
                            "price_page_from"
                          )
                        },
                        blur: function($event) {
                          return _vm.onDataChange(_vm.data.currencies)
                        }
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          }),
          0
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.type === "discounted_base_price"
      ? _c(
          "div",
          { staticClass: "mt-2 mb-2" },
          _vm._l(_vm.data.currencies, function(curr, index) {
            return _c(
              "div",
              { key: index, staticClass: "baseinput mb-2" },
              [
                _c("b-form-input", {
                  directives: [
                    { name: "floatingPoint", rawName: "v-floatingPoint" }
                  ],
                  attrs: { value: curr.price_base, placeholder: "" },
                  on: {
                    input: function($event) {
                      return _vm.updateValue(
                        _vm.data.currencies,
                        $event,
                        index,
                        "price_base"
                      )
                    },
                    blur: function($event) {
                      return _vm.onDataChange(_vm.data.currencies)
                    }
                  }
                })
              ],
              1
            )
          }),
          0
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.type === "discounted_extra_pages"
      ? _c(
          "div",
          { staticClass: "mt-2 mb-2" },
          _vm._l(_vm.data.currencies, function(curr, index) {
            return _c(
              "div",
              { key: index, staticClass: "baseinput mb-2" },
              [
                _vm.data.maxpages > _vm.data.minprintpages
                  ? _c("b-form-input", {
                      directives: [
                        { name: "floatingPoint", rawName: "v-floatingPoint" }
                      ],
                      attrs: { value: curr.price_page, placeholder: "" },
                      on: {
                        input: function($event) {
                          return _vm.updateValue(
                            _vm.data.currencies,
                            $event,
                            index,
                            "price_page"
                          )
                        },
                        blur: function($event) {
                          return _vm.onDataChange(_vm.data.currencies)
                        }
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          }),
          0
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }