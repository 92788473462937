import {
	Vue, Component, Watch, Prop,
} from 'vue-property-decorator';
import * as DB from 'interfaces/database';
import { PickerOptions, PickerResponse } from 'filestack-js';
import { httpClient } from 'utils/http';
import Template from './template.vue';

@Component({
	components: {},
})
export default class EditCategoryTable extends Vue.extend(Template) {
	@Prop({
		type: String,
		required: true,
	})
	public readonly themeCategoryId!: string;

	private singleCategory = {} as DB.ThemeCategoryModel;

	private language: DB.LanguageModel[] = [];

	private tracker: DB.AffiliateModel[] = [];

	private themeData: DB.ThemeModel[] = [];

	private loaded = false;

	private placeHolders: { icon: string } = {
		icon: 'Choose a file',
	}

	private visible = false;

	private spinner = false;

	private index = 0;

	private rowHeight = 60;

	private isLoading = false;

	private get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	protected mounted(): void {
		this.getSingleCategory();
		Promise
			.all([
				httpClient.get<DB.LanguageModel[]>('/api/language?limit=0'),
				httpClient.get<DB.AffiliateModel[]>('/api/affiliate?limit=0'),
				httpClient.get<DB.ThemeModel[]>(`/api/themecategory/${this.themeCategoryId}/themes`),
			])
			.then(([languageResponse, trackerResponse, themeResponse]) => {
				this.language = languageResponse.data;
				this.tracker = trackerResponse.data;
				this.themeData = themeResponse.data;
				return null;
			}).catch((error) => {
				this.$bvToast.toast(`${error.message}`,
					{
						solid: true,
						variant: 'danger',
					});
			});
	}

	@Watch('loggedIn')
	private async getSingleCategory(): Promise<DB.ThemeCategoryModel | undefined> {
		if (this.loggedIn) {
			// set the loading state
			this.isLoading = true;
			try {
				const resp = await httpClient.get<DB.ThemeCategoryModel>(`/api/themecategory/${this.themeCategoryId}`);
				this.singleCategory = resp.data;
				// eslint-disable-next-line consistent-return
				return this.singleCategory;
			} catch (error: any) {
				this.$bvToast.toast(`${error.message}`,
					{
						solid: true,
						variant: 'danger',
					});
			} finally {
				this.isLoading = false;
			}
		}
		return undefined;
	}

	protected async editCategory(): Promise<void> {
		if (this.loggedIn) {
			this.isLoading = true;
			try {
				await httpClient.put<DB.ThemeCategoryModel>(`/api/themecategory/${this.themeCategoryId}`,
					{ ...this.singleCategory });
				this.$bvToast.toast('Theme Category Edited successfully',
					{
						solid: true,
						variant: 'success',
					});
			} catch (error: any) {
				this.$bvToast.toast(`${error.message}`,
					{
						solid: true,
						variant: 'danger',
					});
			} finally {
				this.isLoading = false;
			}
		}
	}

	protected updateIcon(): void {
		const options: PickerOptions = {
			fromSources: ['local_file_system'],
			onUploadDone: async (files: PickerResponse) => {
				this.singleCategory.icon = files.filesUploaded[0].url;
				this.placeHolders.icon = files.filesUploaded[0].filename;
			},
		};
		this.$client.picker(options).open();
	}

	protected removeIcon(): void {
		this.singleCategory.icon = '';
	}
}
