import { httpClient } from 'utils/http';
import {
	Vue, Component, Prop,
} from 'vue-property-decorator';
import * as DB from 'interfaces/database';
import Template from './template.vue';

@Component({
	components: {},
})
export default class PageTemplate extends Vue.extend(Template) {
	private isLoading = false;

	private template: DB.TemplateModel[] = [];

	private templateData = {} as DB.TemplateModel;

	@Prop({
		type: String,
		required: true,
	})
	public readonly pageId!: string;

	private get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	protected mounted(): void {
		this.getTemplate();
	}

	private async getTemplate(): Promise<void> {
		this.isLoading = true;
		const params = new URLSearchParams({
			fields: 'id,name',
			limit: '0',
		});
		try {
			const { data } = await httpClient.get<DB.TemplateModel[]>(`/api/template?${params}`);
			this.template = data;
		} catch (error: any) {
			this.$bvToast.toast(`${error.message}`,
				{
					solid: true,
					variant: 'danger',
				});
		} finally {
			this.isLoading = false;
		}
	}

	protected async onPageTemplateChange(): Promise<void> {
		this.isLoading = true;
		try {
			await httpClient.put(`/api/page/${this.pageId}`,
				{
					template: this.templateData.id,
				});
			this.$bvToast.toast('Page Template Updated',
				{
					solid: true,
					variant: 'success',
				});
		} catch (error: any) {
			this.$bvToast.toast(`${error.message}`,
				{
					solid: true,
					variant: 'danger',
				});
		} finally {
			this.isLoading = false;
		}
	}

	protected editPageTemplate(): void {
		this.$emit('routeTemplate',
			this.templateData.id);
	}

	protected async deletePageTemplate(): Promise<void> {
		this.isLoading = true;
		try {
			await httpClient.put(`/api/page/${this.pageId}`,
				{
					template: null,
				});
			this.templateData = {} as DB.TemplateModel;
			this.$bvToast.toast('Page Template Deleted',
				{
					solid: true,
					variant: 'success',
				});
		} catch (error: any) {
			this.$bvToast.toast(`${error.message}`,
				{
					solid: true,
					variant: 'danger',
				});
		} finally {
			this.isLoading = false;
		}
	}
}
