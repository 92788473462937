import { Vue, Component, Prop } from 'vue-property-decorator';
import * as DB from 'interfaces/database';
import Template from './template.vue';

@Component({})
export default class CurrencySalesTextLink extends Vue.extend(Template) {
	@Prop({
		type: Object,
		required: true,
	})
	public readonly data!: DB.OrderItemModel;

	@Prop({
		type: Boolean,
	})
	public readonly salesvalue!: boolean;

	@Prop({
		type: Boolean,
	})
	public readonly discount_voucher!: boolean;
}
