import {
	Vue, Component, Watch, Prop, Ref,
} from 'vue-property-decorator';
import * as DB from 'interfaces/database';
import mitt from 'mitt';
import { httpClient } from 'utils/http';
import { TabEvent } from 'interfaces/app';
import { CellComponentExtended, ColumnDefinitionExtended, TabulatorFull as Tabulator } from 'tabulator-tables';
import { createInstance } from 'utils/vue';
import { saveAs } from 'file-saver';
import TabulatorBtn from 'components/Tabulator/TabulatorBtn';
import { BModal } from 'bootstrap-vue';
import Template from './template.vue';

const eventBus = mitt<TabEvent<DB.DiscountVoucherModel>>();
@Component({})
export default class DiscountEditTable extends Vue.extend(Template) {
	@Ref('discountVouchersTable')
	private readonly tableReference!: HTMLDivElement;

	@Ref('remark-modal')
	private readonly modal!: BModal;

	private spinner = false;

	private addRemarkData = '';

	private visible = false;

	private selected = false;

	private index = 0;

	private subCategory: DB.ProductCategoryModel[] = [];

	private subcategoryDisplay = false;

	private offeringDisplay = false;

	private existingCategoryDisplay = false;

	private singleTheme = {} as Record<string, DB.ThemeModel | number | string | null>;

	private shippingOptions =
	[
		'No free shipping',
		'Free economy shipping',
		'Free shipping with tracking',
		'Free express shipping',
	];

	private table?: Tabulator;

	private discountVoucherData = {} as DB.DiscountVoucherModel;

	private singleDiscountData = {} as DB.DiscountModel;

	private selectedOfferings: string[] = [];

	private trackerData: DB.AffiliateModel[] = [];

	private productGroup: DB.ProductGroupModel[] = [];

	private referral = [];

	private showDiscountVoucher = false;

	private offering:	DB.OfferingModel[] = [];

	private checkProductCategory = {} as Record<string, DB.ProductCategoryModel>;

	private selectedRows: DB.DiscountVoucherModel[] = [];

	private columnDefs: ColumnDefinitionExtended[] = [];

	private selectedCategories: DB.ThemeCategoryModel[] = []

	private availableCategories: DB.ThemeCategoryModel[] = []

	private currentItem = {} as DB.ThemeCategoryModel;

	private availableCategoryActiveIndex: null | number = null;

	private selectedCategoryActiveIndex: null | number = null;

	private voucherLength = 0;

	private rowData: DB.OfferingModel[] = []

	private filterSubCatergory = null;

	private categorySearch = '';

	private isLoading = false;

	private pageOptions = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50];

	@Prop({
		type: String,
		required: true,
	})
	public readonly discountEditId!: string

	private get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	protected beforeMount(): void {
		this.columnDefs = [
			{
				field: 'id',
				title: 'ID',
				headerFilter: true,
			},
			{
				title: 'info',
				formatter: (cell: CellComponentExtended<DB.DiscountModel>) => {
					const instance = createInstance({
						component: TabulatorBtn,
						props: {
							data: cell.getData(),
							buttons: [
								{
									id: 'info',
									eventName: 'infoItem',
									className: 'fa-info',
								},
							],
							eventBus,
						},
					});
					instance.$mount();

					return (instance.$el as HTMLElement);
				},
			},
			{
				title: 'Remark',
				formatter: (cell: CellComponentExtended<DB.DiscountModel>) => {
					const instance = createInstance({
						component: TabulatorBtn,
						props: {
							data: cell.getData(),
							buttons: [
								{
									id: 'remark',
									eventName: 'remarkItem',
									className: 'fa-comment',
								},
							],
							eventBus,
						},
					});
					instance.$mount();

					return (instance.$el as HTMLElement);
				},
			},
			{
				title: 'Issue',
				formatter: (cell: CellComponentExtended<DB.DiscountVoucherModel>) => {
					const claimed = document.createElement('div');
					claimed.classList.add('text-center');
					claimed.innerText = `Claimed: ${cell.getData().claimed}`;

					const used = document.createElement('div');
					used.classList.add('text-center');
					used.innerText = `Used: ${cell.getData().used}`;

					const container = document.createElement('div');
					container.appendChild(claimed);
					container.appendChild(used);

					return container;
				},
			},
			{
				title: 'Voucher Code',
				field: 'vouchercode',
			},
			{
				title: 'Valid',
				formatter: (cell: CellComponentExtended<DB.DiscountVoucherModel>) => {
					const validity = document.createElement('div');
					validity.classList.add('text-center');
					const icon = document.createElement('i');
					if (cell.getData().validity) {
						icon.classList.add('fa',
							'fa-check-circle',
							'text-success');
					} else {
						icon.classList.add('fa',
							'fa-times-circle',
							'text-danger');
					}
					validity.appendChild(icon);
					return validity;
				},
			},
			{
				title: 'Limit',
				field: 'limit',
				formatter: (cell: CellComponentExtended<DB.DiscountVoucherModel>) => {
					const input = document.createElement('input');
					input.setAttribute('type',
						'number');
					input.setAttribute('disabled',
						'true');
					input.setAttribute('value',
						cell.getData().limit.toString());
					input.classList.add('text-center');
					return input;
				},
			},
			{
				title: 'Start',
				field: 'startdate',
				formatter: (cell: CellComponentExtended<DB.DiscountVoucherModel>) => {
					const input = document.createElement('input');
					input.setAttribute('type',
						'date');
					input.setAttribute('disabled',
						'true');
					input.setAttribute('value',
						cell.getValue().toString());
					input.classList.add('text-center');
					return input;
				},
			},
			{
				title: 'End',
				field: 'enddate',
				formatter: (cell: CellComponentExtended<DB.DiscountVoucherModel>) => {
					const input = document.createElement('input');
					input.setAttribute('type',
						'date');
					input.setAttribute('disabled',
						'true');
					input.setAttribute('value',
						cell.getData().enddate.toString());
					input.classList.add('text-center');
					return input;
				},
			},
		];
	}

	protected mounted(): void {
		eventBus.on('editItem',
			this.editItem);
		this.tableInitialization();
		this.fetchData();
		this.getThemeCategories();
		this.onGridReadyDiscount();
		this.getSingleCategory();
		this.table?.on('tableBuilt',
			this.onTableBuilt);
		this.$nextTick(() => {
			this.getProductCategoryOffering();
		});
	}

	private tableInitialization(): void {
		this.table = new Tabulator(this.tableReference,
			{
				height: '60vh',
				layout: 'fitColumns',
				columns: this.columnDefs,
				selectable: true,
			});
	}

	protected beforeDestroy() {
		eventBus.off('editItem',
			this.editItem);
		this.table?.off('tableBuilt',
			this.onTableBuilt);
		this.table?.off('rowSelectionChanged',
			this.onSelectionChanged);
		this.table?.destroy();
	}

	private onTableBuilt(): void {
		this.onGridReadyDiscount().then(() => this.table?.on('rowSelectionChanged',
			this.onSelectionChanged)).catch((err) => {
			this.$bvToast.toast(`${err.message}`,
				{
					solid: true,
					variant: 'danger',
				});
		});
	}

	private async onGridReadyDiscount(): Promise<void> {
		const parameter = new URLSearchParams({
			where: JSON.stringify({
				discountid: this.discountEditId,
			}),
			limit: '0',
		});

		this.table?.alert('Loading');
		try {
			const { data } = await httpClient.get<DB.DiscountVoucherModel[]>(`/api/discountvoucher?${parameter}`);
			this.table?.setData(data);
		} catch (err: any) {
			this.$bvToast.toast(`${err.message}`,
				{
					solid: true,
					variant: 'danger',
				});
		} finally {
			this.table?.clearAlert();
		}
	}

	protected toggleDiscountVoucher(): void {
		this.showDiscountVoucher = !this.showDiscountVoucher;
	}

	// eslint-disable-next-line class-methods-use-this
	private convertArrayToObject(array: (DB.ProductCategoryOfferingModel)[]): Record<string, DB.ProductCategoryModel> {
		return array.reduce((obj, item) => ({
			...obj,
			[item.offeringid]: item,
		}),
		{});
	}

	@Watch('loggedIn')
	private async getProductCategoryOffering(): Promise<void> {
		if (this.loggedIn) {
			const params = new URLSearchParams({
				where: JSON.stringify({ productcategoryid: this.discountEditId }),
				limit: '0',
			});

			try {
				const resp = await httpClient.get<DB.ProductCategoryOfferingModel[]>(`/api/productcategoryoffering?${params}`);
				this.checkProductCategory = this.convertArrayToObject(resp.data);
			} catch (error: any) {
				this.$bvToast.toast(`${error.message}`,
					{
						solid: true,
						variant: 'danger',
					});
			}
		}
		return undefined;
	}

	protected exportSelected(): void {
		const jsonString = JSON.stringify(this.selectedRows);
		const blob = new Blob([jsonString],
			{ type: 'application/json' });
		saveAs(blob,
			'Data.json');
	}

	protected async exportAll(): Promise<void> {
		const parameter = new URLSearchParams({
			where: JSON.stringify({
				discountid: this.discountEditId,
			}),
			limit: '0',
		});
		this.table?.alert('Loading');
		try {
			const { data } = await httpClient.get(`/api/discountvoucher?${parameter}`);
			const jsonString = JSON.stringify(data);
			const blob = new Blob([jsonString],
				{ type: 'application/json' });
			saveAs(blob,
				'Data.json');
		} catch (error: any) {
			this.$bvToast.toast(`${error.message}`,
				{
					solid: true,
					variant: 'danger',
				});
		} finally {
			this.table?.clearAlert();
		}
	}

	private onSelectionChanged(): void {
		if (this.table) {
			this.selectedRows = this.table.getSelectedData();
		}
	}

	protected saveDiscountVoucher(): void {
		this.isLoading = true;
		httpClient.post('/api/discountvoucher',
			{
				...this.discountVoucherData,

			}).then((res) => {
			this.$bvToast.toast('Template Added',
				{
					solid: true,
					variant: 'success',
				});
			this.$emit('routeTemplate',
				res.data.id);

			return undefined;
		})
			.finally(() => {
				this.isLoading = false;
			})
			.catch((err) => {
				this.$bvToast.toast(`${err.message}`,
					{
						solid: true,
						variant: 'danger',
					});
			});
	}

	protected singleDiscountEdit(): Promise<void> | undefined {
		if (this.loggedIn) {
			this.spinner = true;
			httpClient.put<DB.DiscountModel>(`/api/discount/${this.discountEditId}`,
				{
					...this.singleDiscountData,
				}).then(() => {
				this.$bvToast.toast('Discount Edited successfully',
					{
						solid: true,
						variant: 'success',
					});
				return undefined;
			})
				.finally(() => {
					this.spinner = false;
				})
				.catch((error) => {
					this.$bvToast.toast(`${error.message}`,
						{
							solid: true,
							variant: 'danger',
						});
				});
		}
		return undefined;
	}

	@Watch('loggedIn')
	private async getSingleCategory(): Promise<Record<string, DB.ThemeModel | number | string | null> | undefined> {
		if (this.loggedIn) {
			// set the loading state
			this.isLoading = true;
			try {
				const resp = await httpClient.get<Record<string, DB.ThemeModel>>(`/api/theme/${this.$route.params.id}`);
				this.singleTheme = resp.data;
				// eslint-disable-next-line consistent-return
				return this.singleTheme;
			} catch (error: any) {
				this.$bvToast.toast(`${error.message}`,
					{
						solid: true,
						variant: 'danger',
					});
			} finally {
				this.isLoading = false;
			}
		}
		return undefined;
	}

	@Watch('loggedIn')
	private fetchData(): void {
		if (this.loggedIn) {
			const params = new URLSearchParams({
				limit: '0',
			});
			// set the loading state
			this.spinner = true;
			Promise.all([
				httpClient.get<DB.DiscountModel>(`/api/discount/${this.discountEditId}`),
				httpClient.get<DB.AffiliateModel[]>('/api/affiliate'),
				httpClient.get(`/api/referralprogram?${params}&fields=id,name`),
				httpClient.get<DB.ProductGroupModel[]>(`/api/productgroup?${params}`),
				httpClient.get<DB.OfferingModel[]>(`/api/offering?${params}`),
			])
				.then(([discountResponse, AffiateResponse, ReferrelResponse, ProductResponse, offeringResponse]) => {
					this.singleDiscountData = discountResponse.data;
					this.trackerData = AffiateResponse.data;
					this.referral = ReferrelResponse.data;
					this.productGroup = ProductResponse.data;
					this.offering = offeringResponse.data;
					return null;
				}).finally(() => {
					this.spinner = false;
				})
				.catch((error) => {
					this.$bvToast.toast(`${error.message}`,
						{
							solid: true,
							variant: 'danger',
						});
				});
		}
		return undefined;
	}

	protected selectedItem(data: DB.ThemeCategoryModel, index: number): void {
		this.availableCategoryActiveIndex = index;
		this.currentItem = data;
	}

	protected availableItem(data: DB.ThemeCategoryModel, index: number): void {
		this.selectedCategoryActiveIndex = index;
		this.currentItem = data;
	}

	protected isChecked(offering: DB.OfferingModel): boolean {
		return this.selectedOfferings.some((selectedOffering) => selectedOffering === offering.groupid.toString());
	}

	private getThemeCategories(): void {
		this.isLoading = true;
		const params = new URLSearchParams({
			limit: '0',
		});

		Promise.all([
			httpClient.get<DB.ThemeCategoryModel[]>(`/api/theme/${this.discountEditId
			}/categories`),
			httpClient.get<DB.ThemeCategoryModel[]>(`/api/themecategory?${params}`),
		])
			.then(([selectedTheme, AvailableTheme]) => {
				this.selectedCategories = selectedTheme.data;
				const combinedArray = [...AvailableTheme.data, ...selectedTheme.data];
				this.availableCategories = [...new Set(combinedArray)];
				return null;
			}).finally(() => {
				this.isLoading = false;
			})
			.catch((error) => {
				this.$bvToast.toast(`${error.message}`,
					{
						solid: true,
						variant: 'danger',
					});
			});
	}

	protected async addToSelected(): Promise<void> {
		this.isLoading = true;
		try {
			const themeLink = await httpClient.post('/api/themecategorylink',
				{
					themeid: parseInt(this.$route.params.id,
						10),
					categoryid: this.currentItem.id,
				});
			this.selectedCategories.push(this.currentItem);
			const findAndDelete = this.availableCategories.findIndex((obj) => obj.id === themeLink.data.categoryid);
			this.availableCategories.splice(findAndDelete,
				1);
		} catch (error: any) {
			this.$bvToast.toast(`${error.message}`,
				{
					solid: true,
					variant: 'danger',
				});
		} finally {
			this.isLoading = false;
		}
	}

	protected async removeFromSelected(): Promise<void> {
		this.isLoading = true;
		try {
			const themeLink = await httpClient.delete('/api/themeborderlink',
				{
					data: {
						themeid: parseInt(this.$route.params.id,
							10),
						categoryid: this.currentItem.id,
					},
				});
			this.availableCategories.push(this.currentItem);
			const findAndDelete = this.selectedCategories.findIndex((obj) => obj.id === themeLink.data.categoryid);
			this.selectedCategories.splice(findAndDelete,
				1);
		} catch (error: any) {
			this.$bvToast.toast(`${error.message}`,
				{
					solid: true,
					variant: 'danger',
				});
		} finally {
			this.isLoading = false;
		}
	}

	private editItem(): void {
		this.modal.show();
	}
}
