var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "color d-flex justify-content-center align-items-center",
      style: { backgroundColor: _vm.data.color }
    },
    [_vm._v("\n\t" + _vm._s(_vm.data.color) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }