import { Vue, Component } from 'vue-property-decorator';
import CountryFlag from 'vue-country-flag';
import UserPropertiesBox from './UserPropertiesBox.vue';
import ExternalSources from './ExternalSources';
import PaidOrders from './PaidOrders';
import ShoppingCartItems from './ShoppingCartItems';
import Products from './Products';
import Photos from './Photos';
import UserCredit from './UserCredit';
import UserEmailLog from './UserEmailLog';
import UserReferrals from './UserReferrals';

@Component({
	components: {
		CountryFlag,
		UserPropertiesBox,
		ExternalSources,
		PaidOrders,
		ShoppingCartItems,
		Products,
		Photos,
		UserCredit,
		UserEmailLog,
		UserReferrals,
	},
})
export default class UserPropertyTable extends Vue {
	private name = 'UserPropertyTable';

	private routeId = this.$route.params.id;

	private sendUserRequest = {
		shoppingCart: false,
		products: false,
		photos: false,
		userCredit: false,
		userEmailLog: false,
		userReferrals: false,
	}
}
