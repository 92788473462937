import {
	Vue, Component, Prop, Watch,
} from 'vue-property-decorator';
import * as DB from 'interfaces/database';
import Swal from 'sweetalert2';
import { httpClient } from 'utils/http';
import Template from './template.vue';

@Component({})
export default class EditBulkDiscountTable extends Vue.extend(Template) {
	private spinner = false;

	private visible = false;

	private selected = false;

	private BulkQuantityData = {} as DB.BulkQuantityModel;

	private BulkDiscountQuantities: DB.BulkQuantityModel[] = [];

	private selectedOfferings: string[] = [];

	private subcategoryDisplay = false;

	private offeringDisplay = false;

	private existingCategoryDisplay = false;

	private pdp: DB.PDPModel[] = [];

	private count = ['pages', 'products'];

	private productGroup: DB.ProductGroupModel[] = [];

	private offering: DB.OfferingModel[] = [];

	private singleBulkDiscount = {} as DB.BulkModel;

	private isLoading = false;

	@Prop({
		type: String,
		required: true,
	})
	public readonly bulkDiscountId!: string

	private get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	protected mounted(): void {
		this.fetchData();
		this.getSingleBulkDiscount();
		this.fetchBulkDiscountQuantities();
	}

	protected async addBulkQuantity(): Promise<void> {
		this.isLoading = true;

		try {
			const response = await httpClient.post('/api/bulkquantity',
				{
					...this.BulkQuantityData,
					bulkid: this.bulkDiscountId,
				});
			this.BulkDiscountQuantities.push(response.data);
			this.BulkQuantityData = {
				from: null,
				to: null,
				relative: null,
				absolute: null,
				absolute_q: null,
				id: null,
				bulkid: null,
			};
		} catch (err: any) {
			this.$bvToast.toast(`${err.message}`,
				{
					solid: true,
					variant: 'danger',
				});
		} finally {
			this.isLoading = false;
		}
	}

	@Watch('loggedIn')
	private async getSingleBulkDiscount(): Promise<DB.BulkModel | undefined> {
		if (this.loggedIn) {
			// set the loading state
			this.spinner = true;
			try {
				const resp = await httpClient.get<DB.BulkModel>(`/api/bulk/${this.bulkDiscountId}`);
				this.singleBulkDiscount = resp.data;
				// eslint-disable-next-line consistent-return
				return this.singleBulkDiscount;
			} catch (error: any) {
				this.$bvToast.toast(`${error.message}`,
					{
						solid: true,
						variant: 'danger',
					});
			} finally {
				this.spinner = false;
			}
		}
		return undefined;
	}

	protected isChecked(offering: DB.OfferingModel): boolean {
		return this.selectedOfferings.some((selectedOffering) => selectedOffering === offering.groupid.toString());
	}

	private fetchBulkDiscountQuantities(): void {
		const Params = new URLSearchParams({
			limit: '0',
		});
		Promise
			.all([
				httpClient.get<DB.BulkQuantityModel[]>(`/api/bulk/${this.bulkDiscountId}/quantities?${Params}`),
			])
			.then(([bulkQuantityResponse]) => {
				this.BulkDiscountQuantities = bulkQuantityResponse.data;
				return null;
			})
			.catch((err) => {
				this.$bvToast.toast(`${err.message}`,
					{
						solid: true,
						variant: 'danger',
					});
			});
	}

	protected async saveBulkDiscountQuantity(item: DB.BulkQuantityModel): Promise<void> {
		this.isLoading = true;
		try {
			await httpClient.post(`/api/bulkquantity/${item.id}`,
				{
					...item,
					bulkid: this.bulkDiscountId,
				});
		} catch (err: any) {
			this.$bvToast.toast(`${err.message}`,
				{
					solid: true,
					variant: 'danger',
				});
		} finally {
			this.isLoading = false;
		}
	}

	protected removeBulkDiscountQuanitity(id: number): Promise<void> {
		return Swal.fire({
			title: 'Are you sure?',
			text: 'You will not be able to recover this imaginary file!',
			icon: 'warning',
			showCancelButton: true,
			customClass: {
				confirmButton: 'btn btn-danger m-1',
				cancelButton: 'btn btn-secondary m-1',
			},
			confirmButtonText: 'Yes, delete it!',
			html: false,
		}).then((result) => {
			if (result.value) {
				// eslint-disable-next-line promise/no-nesting
				httpClient.delete(`/api/bulkquantity/${id}`).then(() => {
					const indexOfUserToRemove = this.BulkDiscountQuantities.findIndex((u) => u.id === id);
					this.BulkDiscountQuantities.splice(indexOfUserToRemove,
						1);
					return null;
				}).catch((err) => {
					this.$bvToast.toast(`${err.message}`,
						{
							solid: true,
							variant: 'danger',
						});
				});
			}
			return undefined;
		}).catch((err) => {
			this.$bvToast.toast(`${err.message}`,
				{
					solid: true,
					variant: 'danger',
				});
		});
	}

	protected editSingleBulkDiscount(): void {
		if (this.loggedIn) {
			this.spinner = true;
			httpClient.put<DB.BulkQuantityModel>(`/api/bulk/${this.bulkDiscountId}`,
				{
					...this.singleBulkDiscount,
				}).then(() => {
				this.$bvToast.toast('Bulk Discount Edited successfully',
					{
						solid: true,
						variant: 'success',
					});
				return undefined;
			})
				.finally(() => {
					this.spinner = false;
				})
				.catch((error) => {
					this.$bvToast.toast(`${error.message}`,
						{
							solid: true,
							variant: 'danger',
						});
				});
		}
	}

	private fetchData(): void {
		const Params = new URLSearchParams({
			limit: '0',
		});
		Promise
			.all([
				httpClient.get<DB.ProductGroupModel[]>(`/api/productgroup?${Params}`),
				httpClient.get<DB.OfferingModel[]>(`/api/offering?${Params}`),
			])
			.then(([productGroupResponse, offeringResponse]) => {
				this.productGroup = productGroupResponse.data;
				this.offering = offeringResponse.data.map((off: DB.OfferingModel) => ({
					...off,
					switchStatus: false, // Add switchStatus property with initial value
				}));
				return null;
			}).catch((error) => {
				this.$bvToast.toast(`${error.message}`,
					{
						solid: true,
						variant: 'danger',
					});
			});
	}
}
