import {
	Component,
	Prop,
	Vue,
} from 'vue-property-decorator';
import { TabEvent } from 'interfaces/app';
import DB from 'interfaces/database';
import { Emitter } from 'mitt';
import Template from './template.vue';

@Component({})
export default class TabulatorSwitchBox extends Vue.extend(Template) {
	@Prop({
		type: Boolean,
		required: true,
	})
	public readonly checked!: boolean;

	@Prop({
		type: Object,
	})
	public readonly data!: DB;

	@Prop({
		type: String,
	})
	public readonly text!: string;

	@Prop({
		type: Object,
		required: true,
	})
	public readonly eventBus!: Emitter<TabEvent<any>>;

	@Prop({
		type: String,
	})
	public readonly eventName!: keyof TabEvent<DB>;

	protected onChange(event: boolean): void {
		this.eventBus.emit(
			this.eventName as keyof TabEvent<DB> || 'switchBoxChanged',
			{
				event,
				params: this.data,
			},
		);
	}
}
