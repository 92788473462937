import { Vue, Component, Ref } from 'vue-property-decorator';
import EditHyperlinksTable from 'components/HyperlinksTable/EditHyperlinksTable.vue';

@Component({
	components: {
		EditHyperlinksTable,
	},
})
export default class EditHyperlinks extends Vue {
	@Ref('edithyperlinks') readonly hyperlinksModal!: HTMLFormElement;

	private isLoading = false;

	get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	updateHyperlinksProperties(): void {
		this.hyperlinksModal.editHyperlink();
	}
}
