var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("button", { staticClass: "small ui button" }, [
      _c("i", { staticClass: "fa fa-fw fa-download" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }