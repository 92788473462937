import { Vue, Component, Watch } from 'vue-property-decorator';
import * as DB from 'interfaces/database';
import { PickerOptions, PickerResponse } from 'filestack-js';
import { httpClient } from 'utils/http';
import ThemesPagesTable from '../ThemesPagesTable';
import ThemesCategories from '../ThemeCategories';
import ThemeLayout from '../ThemeLayout';
import ThemeBorderImage from '../ThemeBorderImage';
import ThemesVariant from '../ThemeVariant';
import Template from './template.vue';

@Component({
	components: {
		ThemesPagesTable,
		ThemesCategories,
		ThemeLayout,
		ThemeBorderImage,
		ThemesVariant,
	},
})
export default class ThemesEditPageTable extends Vue.extend(Template) {
	private singleTheme = {} as Record<string, DB.ThemeModel | number | string | null>;

	private routeId = this.$route.params.id;

	private pageId = this.$route.params.pageid;

	private layout: DB.LayoutModel[] = [];

	private loaded = false;

	private placeHolders = {
		icon: 'Choose a file',
	}

	private visible = false;

	private spinner = false;

	private display = {
		themepage: false,
		themecategory: false,
		themelayout: false,
		themeborderimage: false,
		themevariant: false,
	} as Record<string, boolean>

	private index = 0;

	private rowHeight = 60;

	private isLoading = false;

	private get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	public mounted(): void {
		this.getSingleCategory();
		this.getLayout();
	}

	@Watch('loggedIn')
	private async getSingleCategory(): Promise<Record<string, DB.ThemeModel | number | string | null> | undefined> {
		if (this.loggedIn) {
			// set the loading state
			this.isLoading = true;
			try {
				const resp = await httpClient.get<Record<string, DB.ThemeModel>>(`/api/theme/${this.$route.params.id}`);
				this.singleTheme = resp.data;
				// eslint-disable-next-line consistent-return
				return this.singleTheme;
			} catch (error: any) {
				this.$bvToast.toast(`${error.message}`,
					{
						solid: true,
						variant: 'danger',
					});
			} finally {
				this.isLoading = false;
			}
		}
		return undefined;
	}

	@Watch('loggedIn')
	private async getLayout(): Promise<DB.LayoutModel[] | undefined> {
		if (this.loggedIn) {
			// set the loading state
			this.isLoading = true;
			try {
				const resp = await httpClient.get<DB.LayoutModel[]>(`/api/theme/${this.$route.params.id}/layouts`);
				this.layout = resp.data;
				// eslint-disable-next-line consistent-return
				return this.layout;
			} catch (error: any) {
				this.$bvToast.toast(`${error.message}`,
					{
						solid: true,
						variant: 'danger',
					});
			} finally {
				this.isLoading = false;
			}
		}
		return undefined;
	}

	protected displayPages(property: string): void {
		this.display[property] = true;
	}

	protected setPropertyNull(property: string): void {
		this.singleTheme[property] = null;
	}

	protected checkPropertyNull(property: string): boolean {
		return this.singleTheme[property] === null;
	}

	protected checkPropertyTrue(property: string): boolean {
		return this.singleTheme[property] === 1;
	}

	protected updateProperty(property: string, event: number): void {
		this.singleTheme[property] = event;
	}

	protected editTheme(): Promise<void> | undefined {
		if (this.loggedIn) {
			this.isLoading = true;
			return httpClient.put<DB.ThemeModel>(`/api/theme/${this.$route.params.id}`,
				{ ...this.singleTheme }).then(() => {
				this.$bvToast.toast('Theme Edited successfully',
					{
						solid: true,
						variant: 'success',
					});
				return undefined;
			})
				.finally(() => {
					this.isLoading = false;
				})
				.catch((error) => {
					this.$bvToast.toast(`${error.message}`,
						{
							solid: true,
							variant: 'danger',
						});
				});
		}
		return Promise.resolve(undefined);
	}

	protected routeLayout(id: string): void {
		this.$emit('routeLayout',
			`${id}`);
	}

	protected routeEditTheme(id: string): void {
		this.$emit('routeEditTheme',
			`${id}`);
	}

	protected routeThemePages(id: string): void {
		this.$emit('routeThemePages',
			`${id}`);
	}

	protected updateIcon(): void {
		const options: PickerOptions = {
			fromSources: ['local_file_system'],
			onUploadDone: async (files: PickerResponse) => {
				this.singleTheme.icon = files.filesUploaded[0].url;
				this.placeHolders.icon = files.filesUploaded[0].filename;
			},
		};
		this.$client.picker(options).open();
	}

	protected removeIcon(): void {
		this.singleTheme.icon = '';
	}
}
