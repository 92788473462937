import { Vue, Component, Ref } from 'vue-property-decorator';
import EditPageBgTable from 'components/Themes/PageBgTable/EditPageBgTable';
import Template from './template.vue';

@Component({
	components: {
		EditPageBgTable,
	},
})
export default class EditPageBg extends Vue.extend(Template) {
	@Ref('editpagebg') private readonly editPagebg!: HTMLFormElement;

	private routeId = this.$route.params.id

	protected updatePageBgProperties(): void {
		this.editPagebg.editPageBg();
	}
}
