var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "ml-5", staticStyle: { width: "95%" } },
    [
      _c("loading-overlay", {
        attrs: { active: _vm.isLoading, "can-cancel": true }
      }),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "image-modal",
          attrs: { id: "bv-modal", "hide-footer": "" },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function() {
                return [_vm._v("\n\t\t\tCreate New PDP Image\n\t\t")]
              },
              proxy: true
            }
          ])
        },
        [
          _vm._v(" "),
          _vm.spinner
            ? _c("div", { staticClass: "text-center" }, [
                _c(
                  "div",
                  {
                    staticClass: "spinner-grow text-primary m-5",
                    attrs: { role: "status" }
                  },
                  [
                    _c("span", { staticClass: "sr-only" }, [
                      _vm._v("Loading...")
                    ])
                  ]
                )
              ])
            : _c(
                "div",
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Language",
                        "label-for": "example-select",
                        "label-cols-lg": "3"
                      }
                    },
                    [
                      _c(
                        "b-input-group",
                        {
                          scopedSlots: _vm._u([
                            {
                              key: "append",
                              fn: function() {
                                return [
                                  _c(
                                    "b-input-group-text",
                                    {
                                      staticClass: "input-group-text-alt",
                                      staticStyle: { cursor: "pointer" }
                                    },
                                    [
                                      _c("b-form-checkbox", {
                                        attrs: {
                                          id: "checkbox-2",
                                          name: "checkbox-1",
                                          checked:
                                            _vm.langid == null ? true : false,
                                          "unchecked-value": "not_accepted"
                                        },
                                        on: {
                                          change: () => (_vm.langid = null)
                                        }
                                      }),
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t"
                                      )
                                    ],
                                    1
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c(
                            "b-form-select",
                            {
                              attrs: { id: "example-select", plain: "" },
                              model: {
                                value: _vm.langid,
                                callback: function($$v) {
                                  _vm.langid = $$v
                                },
                                expression: "langid"
                              }
                            },
                            [
                              _c(
                                "b-form-select-option",
                                { attrs: { value: null } },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\tPlease select an option\n\t\t\t\t\t\t"
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _vm._l(_vm.language, function(lang, id) {
                                return _c(
                                  "b-form-select-option",
                                  { key: id, attrs: { value: lang.id } },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t" +
                                        _vm._s(lang.id) +
                                        " | " +
                                        _vm._s(lang.name) +
                                        "\n\t\t\t\t\t\t"
                                    )
                                  ]
                                )
                              })
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-group",
                    { attrs: { label: "Image", "label-cols-lg": "3" } },
                    [
                      _c("ValidationProvider", {
                        attrs: { rules: "image", name: "Image" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function({ classes, errors }) {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "control", class: classes },
                                  [
                                    _c("b-form-file", {
                                      ref: "image",
                                      attrs: {
                                        id: "image",
                                        placeholder:
                                          _vm.placeHolders.thumbnailPlaceholder
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.updateImagePreview.apply(
                                            null,
                                            arguments
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("span", [_vm._v(_vm._s(errors[0]))])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _vm.imagePreview
                        ? _c("div", { staticClass: "thumbnail" }, [
                            _c("img", {
                              attrs: {
                                src: _vm.imagePreview,
                                alt: "",
                                srcset: "",
                                height: "100",
                                width: "100"
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticStyle: { cursor: "pointer" },
                                on: { click: _vm.removeImage }
                              },
                              [_vm._v("\n\t\t\t\t\t\t✖ Remove\n\t\t\t\t\t")]
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-group",
                    {
                      staticClass: "form-group",
                      attrs: {
                        label: "width ",
                        "label-for": "block-form1-name",
                        "label-cols-lg": "3"
                      }
                    },
                    [
                      _c("ValidationProvider", {
                        attrs: { rules: "integer", name: "Width" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function({ classes, errors }) {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "control", class: classes },
                                  [
                                    _c("b-form-input", {
                                      ref: "pdpImageName",
                                      attrs: {
                                        type: "text",
                                        disabled:
                                          _vm.width === null ? true : false
                                      },
                                      model: {
                                        value: _vm.width,
                                        callback: function($$v) {
                                          _vm.width = $$v
                                        },
                                        expression: "width"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("span", [_vm._v(_vm._s(errors[0]))])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-group",
                    {
                      staticClass: "form-group",
                      attrs: {
                        label: "height: ",
                        "label-for": "block-form1-name",
                        "label-cols-lg": "3"
                      }
                    },
                    [
                      _c("ValidationProvider", {
                        attrs: { rules: "integer", name: "Height" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function({ classes, errors }) {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "control", class: classes },
                                  [
                                    _c("b-form-input", {
                                      ref: "pdpImageName",
                                      attrs: {
                                        type: "text",
                                        disabled:
                                          _vm.height === null ? true : false
                                      },
                                      model: {
                                        value: _vm.height,
                                        callback: function($$v) {
                                          _vm.height = $$v
                                        },
                                        expression: "height"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("span", [_vm._v(_vm._s(errors[0]))])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      staticClass: "mt-3",
                      attrs: { block: "" },
                      on: { click: _vm.addPdpImage }
                    },
                    [_vm._v("\n\t\t\t\tSave\n\t\t\t")]
                  )
                ],
                1
              )
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "image-edit-modal",
          attrs: { id: "bv-edit-modal", "hide-footer": "" },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function() {
                return [_vm._v("\n\t\t\tEdit PDP Image\n\t\t")]
              },
              proxy: true
            }
          ])
        },
        [
          _vm._v(" "),
          _vm.spinner
            ? _c("div", { staticClass: "text-center" }, [
                _c(
                  "div",
                  {
                    staticClass: "spinner-grow text-primary m-5",
                    attrs: { role: "status" }
                  },
                  [
                    _c("span", { staticClass: "sr-only" }, [
                      _vm._v("Loading...")
                    ])
                  ]
                )
              ])
            : _c(
                "div",
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Language",
                        "label-for": "example-select",
                        "label-cols-lg": "5"
                      }
                    },
                    [
                      _c(
                        "b-input-group",
                        {
                          scopedSlots: _vm._u([
                            {
                              key: "append",
                              fn: function() {
                                return [
                                  _c(
                                    "b-input-group-text",
                                    {
                                      staticClass: "input-group-text-alt",
                                      staticStyle: { cursor: "pointer" }
                                    },
                                    [
                                      _c("b-form-checkbox", {
                                        attrs: {
                                          id: "checkbox-2",
                                          name: "checkbox-1",
                                          checked:
                                            _vm.editModalItems.languageid ==
                                            null
                                              ? true
                                              : false,
                                          "unchecked-value": "not_accepted"
                                        },
                                        on: {
                                          change: () =>
                                            (_vm.editModalItems.languageid = null)
                                        }
                                      }),
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t"
                                      )
                                    ],
                                    1
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c(
                            "b-form-select",
                            {
                              attrs: { id: "example-select", plain: "" },
                              model: {
                                value: _vm.editModalItems.languageid,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.editModalItems,
                                    "languageid",
                                    $$v
                                  )
                                },
                                expression: "editModalItems.languageid"
                              }
                            },
                            _vm._l(_vm.language, function(lang, id) {
                              return _c(
                                "b-form-select-option",
                                { key: id, attrs: { value: lang.id } },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(lang.id) +
                                      " | " +
                                      _vm._s(lang.name) +
                                      "\n\t\t\t\t\t\t"
                                  )
                                ]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-group",
                    { attrs: { label: "Image", "label-cols-lg": "5" } },
                    [
                      _c("ValidationProvider", {
                        attrs: { rules: "integer", name: "Height" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function({ classes, errors }) {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "control", class: classes },
                                  [
                                    _c("b-form-file", {
                                      ref: "editImage",
                                      attrs: {
                                        id: "editImage",
                                        placeholder:
                                          _vm.placeHolders.editImagePlaceholder
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.editImagePreview.apply(
                                            null,
                                            arguments
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("span", [_vm._v(_vm._s(errors[0]))])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _vm.editModalItems.url
                        ? _c("div", { staticClass: "thumbnail" }, [
                            _c("img", {
                              attrs: {
                                src: _vm.editModalItems.url,
                                alt: "",
                                srcset: "",
                                height: "100",
                                width: "100"
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticStyle: { cursor: "pointer" },
                                on: { click: _vm.removeEditImage }
                              },
                              [_vm._v("\n\t\t\t\t\t\t✖ Remove\n\t\t\t\t\t")]
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-group",
                    {
                      staticClass: "form-group",
                      attrs: {
                        label: "width ",
                        "label-for": "block-form1-name",
                        "label-cols-lg": "5"
                      }
                    },
                    [
                      _c("ValidationProvider", {
                        attrs: { rules: "integer", name: "width" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function({ classes, errors }) {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "control", class: classes },
                                  [
                                    _c("b-form-input", {
                                      ref: "pdpImageName",
                                      attrs: {
                                        type: "text",
                                        disabled:
                                          _vm.editModalItems.width === null
                                            ? true
                                            : false
                                      },
                                      model: {
                                        value: _vm.editModalItems.width,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.editModalItems,
                                            "width",
                                            $$v
                                          )
                                        },
                                        expression: "editModalItems.width"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("span", [_vm._v(_vm._s(errors[0]))])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-group",
                    {
                      staticClass: "form-group",
                      attrs: {
                        label: "height: ",
                        "label-for": "block-form1-name",
                        "label-cols-lg": "5"
                      }
                    },
                    [
                      _c("ValidationProvider", {
                        attrs: { rules: "integer", name: "Height" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function({ classes, errors }) {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "control", class: classes },
                                  [
                                    _c("b-form-input", {
                                      ref: "pdpImageName",
                                      attrs: {
                                        type: "text",
                                        disabled:
                                          _vm.editModalItems.height === null
                                            ? true
                                            : false
                                      },
                                      model: {
                                        value: _vm.editModalItems.height,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.editModalItems,
                                            "height",
                                            $$v
                                          )
                                        },
                                        expression: "editModalItems.height"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("span", [_vm._v(_vm._s(errors[0]))])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      staticClass: "mt-3",
                      attrs: { block: "" },
                      on: {
                        click: function($event) {
                          return _vm.editPdpImage()
                        }
                      }
                    },
                    [_vm._v("\n\t\t\t\tSave\n\t\t\t")]
                  )
                ],
                1
              )
        ]
      ),
      _vm._v(" "),
      _c(
        "b-row",
        { staticClass: "ml-5", staticStyle: { width: "95%" } },
        [
          _c(
            "b-col",
            { attrs: { md: "5", lg: "5", sm: "12" } },
            [
              _c(
                "base-block",
                { attrs: { title: "PDP properties", "header-bg": "" } },
                [
                  _vm.loaded
                    ? _c("div", { staticClass: "text-center" }, [
                        _c(
                          "div",
                          {
                            staticClass: "spinner-grow text-primary m-5",
                            attrs: { role: "status" }
                          },
                          [
                            _c("span", { staticClass: "sr-only" }, [
                              _vm._v("Loading...")
                            ])
                          ]
                        )
                      ])
                    : _c(
                        "div",
                        { staticClass: "d-block text-center" },
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "form-group",
                              attrs: {
                                label: "Name: ",
                                "label-for": "block-form1-name",
                                "label-cols-lg": "3"
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "control" },
                                [
                                  _c("b-form-input", {
                                    ref: "pdpImageName",
                                    attrs: { type: "text" },
                                    model: {
                                      value: _vm.singlePdp.name,
                                      callback: function($$v) {
                                        _vm.$set(_vm.singlePdp, "name", $$v)
                                      },
                                      expression: "singlePdp.name"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Add offering Image:",
                                "label-class": "font-w600",
                                "label-cols-lg": "5"
                              }
                            },
                            [
                              _c("b-form-checkbox", {
                                ref: "pdpImageOfferingImage",
                                attrs: {
                                  switch: "",
                                  size: "lg",
                                  checked:
                                    _vm.singlePdp.addOfferingImage === 1
                                      ? true
                                      : false
                                },
                                on: {
                                  change: function($event) {
                                    _vm.singlePdp.addOfferingImage = $event
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { md: "7", lg: "7", sm: "12" } },
            [
              _c(
                "base-block",
                { attrs: { title: "PDP Images", "header-bg": "" } },
                [
                  _c(
                    "b-row",
                    { staticClass: "d-flex justify-content-end" },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass: "d-flex justify-content-end mb-2",
                          attrs: { md: "6" }
                        },
                        [
                          _c(
                            "router-link",
                            {
                              attrs: { to: "#" },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.showImageModal.apply(
                                    null,
                                    arguments
                                  )
                                }
                              }
                            },
                            [_vm._v("\n\t\t\t\t\t\t\tAdd Image\n\t\t\t\t\t\t")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { ref: "pdpImage" })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }