import { Vue, Component } from 'vue-property-decorator';
import { Event, AgGridSwitchBoxParams } from 'interfaces/app';
import Template from './template.vue';

@Component({})
export default class SwitchBox extends Vue.extend(Template) {
	private params: AgGridSwitchBoxParams = {} as any;

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	refresh(params: any) {
		this.params = params;
	}

	onChange(event: boolean): void {
		this.params.eventBus.emit(this.params.eventName as keyof Event || 'switchBoxChanged',
			{
				event,
				params: this.params,
			});
	}
}
