import {
	Vue, Component, Prop, Ref,
} from 'vue-property-decorator';
import * as DB from 'interfaces/database';
import mitt from 'mitt';
import Swal from 'sweetalert2';
import { TabEvent } from 'interfaces/app';
import { httpClient } from 'utils/http';
import {
	CellComponent, CellComponentExtended, ColumnDefinitionExtended, TabulatorFull as Tabulator,
} from 'tabulator-tables';
import TabulatorSwitchBox from 'components/Tabulator/TabulatorSwitchBox';
import { createInstance } from 'utils/vue';
import TabulatorBtn from 'components/Tabulator/TabulatorBtn';
import Template from './template.vue';

const eventBus = mitt<TabEvent<DB.ThemeVariantModel>>();
@Component({
	components: {
		TabulatorBtn,
		TabulatorSwitchBox,
	},
})
export default class ThemesVariant extends Vue.extend(Template) {
	@Ref('themevariantTable')
	private readonly tableReference!: HTMLDivElement;

	@Prop({
		type: String,
		required: true,
	})
	private readonly routeId!: string;

	private loaded = false;

	private columnDefs: ColumnDefinitionExtended[] = [];

	private table?: Tabulator;

	private showExistingPage = false;

	private showNewPage = false;

	private themevariant = {} as DB.ThemeVariantModel;

	private ExistingPagesData = {} as DB.ThemeVariantModel;

	private themepageDate: DB.ThemeVariantModel[] = [];

	private get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	protected beforeMount(): void {
		this.columnDefs = [
			{
				title: 'Input',
				formatter: (cell: CellComponentExtended<DB.ThemeVariantModel>) => {
					const data = cell.getData();
					const instance = createInstance({
						component: TabulatorSwitchBox,
						props: {
							data,
							eventBus,
							checked: Boolean(data && data.input),
						},
					});
					instance.$mount();

					return (instance.$el as HTMLElement);
				},
			},
			{
				field: 'tag',
				title: 'Tag',
				editor: 'input',
				cellEdited: async (cell: CellComponent) => {
					const rowData = cell.getData() as DB.ThemeVariantModel;
					const cellValue = cell.getValue();
					this.table?.alert('Loading...');
					try {
						await httpClient.put(`/api/themevariant/${rowData.id}`,
							{
								tag: cellValue,
							});
					} catch (error: any) {
						this.$bvToast.toast(`${error.message}`,
							{
								solid: true,
								variant: 'danger',
							});
					} finally {
						this.table?.clearAlert();
					}
				},
			},
			{
				title: 'Value',
				field: 'value',
				editor: 'input',
				cellEdited: async (cell: CellComponent) => {
					const rowData = cell.getData() as DB.ThemeVariantModel;
					const cellValue = cell.getValue();
					this.table?.alert('Loading...');
					try {
						await httpClient.put(`/api/themevariant/${rowData.id}`,
							{
								value: cellValue,
							});
					} catch (error: any) {
						this.$bvToast.toast(`${error.message}`,
							{
								solid: true,
								variant: 'danger',
							});
					} finally {
						this.table?.clearAlert();
					}
				},
			},
			{
				title: 'Label',
				field: 'label',
				editor: 'input',
				cellEdited: async (cell: CellComponent) => {
					const rowData = cell.getData() as DB.ThemeVariantModel;
					const cellValue = cell.getValue();
					this.table?.alert('Loading...');
					try {
						await httpClient.put(`/api/themevariant/${rowData.id}`,
							{
								label: cellValue,
							});
					} catch (error: any) {
						this.$bvToast.toast(`${error.message}`,
							{
								solid: true,
								variant: 'danger',
							});
					} finally {
						this.table?.clearAlert();
					}
				},
			},
			{
				title: 'Actions',
				formatter: (cell: CellComponentExtended<DB.ThemeVariantModel>) => {
					const instance = createInstance({
						component: TabulatorBtn,
						props: {
							data: cell.getData(),
							buttons: [
								{
									id: 'delete',
									eventName: 'deleteItem',
									className: 'fa-times',
								},
							],
							eventBus,
						},
					});
					instance.$mount();

					return (instance.$el as HTMLElement);
				},
			},
		];
	}

	private switchBoxChanged(data: TabEvent<DB.ThemeVariantModel>['switchBoxChanged']): void {
		this.table?.alert('Loading...');
		if (data.event) {
			httpClient
				.put(`/api/themevariant/${data.params.id}`,
					{
						input: 1,
					})
				.then(() => undefined)
				.finally(() => {
					this.table?.clearAlert();
				}).catch((err) => {
					this.$bvToast.toast(`${err.message}`,
						{
							solid: true,
							variant: 'danger',
						});
				});
		} else {
			httpClient
				.put(`/api/themevariant/${data.params.id}`,
					{
						data: {
							input: 0,
						},
					})
				.then(() => undefined)
				.finally(() => {
					this.table?.clearAlert();
				}).catch((err) => {
					this.$bvToast.toast(`${err.message}`,
						{
							solid: true,
							variant: 'danger',
						});
				});
		}
	}

	protected toggleShowNewPage(): void {
		this.showNewPage = !this.showNewPage;
	}

	protected get isCheckedTrue(): boolean {
		return this.themevariant.input === 1;
	}

	protected setChangeEvent(event: number): void {
		this.themevariant.input = event;
	}

	protected mounted(): void {
		eventBus.on('switchBoxChanged',
			this.switchBoxChanged);
		eventBus.on('deleteItem',
			this.deleteThemeVariant);
		this.tableInitialization();
		this.table?.on('tableBuilt',
			this.onTableBuilt);
	}

	protected beforeDestroy(): void {
		eventBus.off('switchBoxChanged',
			this.switchBoxChanged);
		eventBus.off('deleteItem',
			this.deleteThemeVariant);
		this.table?.off('tableBuilt',
			this.onTableBuilt);
		this.table?.destroy();
	}

	private onTableBuilt(): void {
		this.getData();
	}

	private tableInitialization(): void {
		this.table = new Tabulator(this.tableReference,
			{
				height: '60vh',
				layout: 'fitColumns',
				columns: this.columnDefs,
			});
	}

	private async getData(): Promise<void> {
		const route = this.routeId;

		const parameter = new URLSearchParams({
			where: JSON.stringify({ themeid: route }),
			limit: '5',
		});
		this.table?.alert('Loading');
		try {
			const { data } = await httpClient.get<DB.ThemeVariantModel[]>(`/api/themevariant?${parameter}`);
			this.table?.setData(data);
		} catch (err: any) {
			this.$bvToast.toast(`${err.message}`,
				{
					solid: true,
					variant: 'danger',
				});
		} finally {
			this.table?.clearAlert();
		}
	}

	protected async addThemeVariant(): Promise<void> {
		this.table?.alert('Loading');
		const route = this.routeId;
		const parameter = new URLSearchParams({
			where: JSON.stringify({ themeid: route }),
		});
		try {
			const { data } = await httpClient
				.post(`/api/themevariant?${parameter}`,
					{
						...this.themevariant,
					});
			this.table?.addData(data,
				true);
		} catch (error: any) {
			this.$bvToast.toast(`${error.message}`,
				{
					solid: true,
					variant: 'danger',
				});
		} finally {
			this.table?.clearAlert();
		}
	}

	private deleteThemeVariant(data: TabEvent<DB.ThemeVariantModel>['deleteItem']): void {
		Swal.fire({
			title: 'Are you sure?',
			text: 'You will not be able to recover this file!',
			icon: 'warning',
			showCancelButton: true,
			customClass: {
				confirmButton: 'btn btn-danger m-1',
				cancelButton: 'btn btn-secondary m-1',
			},
			confirmButtonText: 'Yes, delete it!',
			html: false,
		}).then((result) => {
			if (result.value) {
				this.table?.alert('Loading');
				// eslint-disable-next-line promise/no-nesting
				return httpClient.delete(`/api/themevariant/${data.id}`).then(() => {
					this.$bvToast.toast('Item Deleted',
						{
							solid: true,
							variant: 'success',
						});
					this.table?.deleteRow(data.id);
					return null;
				}).finally(() => {
					this.table?.clearAlert();
				}).catch((err) => {
					this.$bvToast.toast(`${err.message}`,
						{
							solid: true,
							variant: 'danger',
						});
				});
			}
			return undefined;
		}).catch((err) => {
			this.$bvToast.toast(`${err.message}`,
				{
					solid: true,
					variant: 'danger',
				});
		});
	}
}
