import {
	Vue, Component, Ref,
} from 'vue-property-decorator';
import * as DB from 'interfaces/database';
import {
	CellComponent, CellComponentExtended, ColumnDefinitionExtended, TabulatorFull as Tabulator,
} from 'tabulator-tables';
import { createInstance } from 'utils/vue';
import CurrencySalesTextLink from 'components/Tabulator/CurrencySalesTextLink';
import PrintFile from 'components/Globals/PrintFile.vue';
import TextLink from 'components/Globals/TextLink.vue';
import { httpClient, creatorDomain } from 'utils/http';
import TabulatorBtn from 'components/Tabulator/TabulatorBtn';
import mitt from 'mitt';
import { TabEvent } from 'interfaces/app';
import { BvModal } from 'bootstrap-vue';

const eventBus = mitt<TabEvent<DB.OrderItemModel>>();
@Component({
	components: {
		CurrencySalesTextLink,
		PrintFile,
		TextLink,
	},
})
export default class ProductOrderedTable extends Vue {
	@Ref('productOrderedTable')
	private allTableReference!: HTMLDivElement;

	@Ref('productOrdered-modal')
	private modalReference!: BvModal;

	private items: DB.OrderItemModel[] = [];

	private pageOptions = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50];

	private isLoading = false;

	private description = {};

	private showFilter = false;

	private domain = creatorDomain;

	private table?: Tabulator;

	private printUrls = [];

	private columnDefs: ColumnDefinitionExtended[] = [];

	private offeringModels: DB.OfferingModel[] = [];

	private productGroupModels: DB.ProductGroupModel[] = [];

	private rowData: DB.OrderItemModel[] = [];

	protected beforeMount(): void {
		eventBus.on('printFile',
			this.printFile);
	}

	protected mounted() {
		this.onTableBuilt();
	}

	private onTableBuilt() {
		this.isLoading = true;
		this.getData().then(() => {
			this.buildTable();
			return true;
		}).finally(() => {
			this.isLoading = false;
		}).catch((error) => {
			this.$bvToast.toast(
				`${error.message}`,
				{
					solid: true,
					variant: 'danger',
				},
			);
		});
	}

	protected beforeDestroy() {
		eventBus.off('printFile',
			this.printFile);
		this.table?.off('tableBuilt',
			this.onTableBuilt);
		this?.table?.destroy();
	}

	private buildTable() {
		const groupNameFilterValues: Record<
		DB.ProductGroupModel['name'] | '',
		DB.ProductGroupModel['name'] | ''
		> = {
			'': '',
		};
		this.productGroupModels.forEach(
			(model) => {
				groupNameFilterValues[model.name] = model.name;
			},
		);
		this.table = new Tabulator(
			this.allTableReference,
			{
				data: this.rowData,
				height: '80vh',
				rowHeight: 40,
				layout: 'fitColumns',
				columns: [
					{
						title: 'offeringModel',
						field: 'offeringModel',
						visible: false,
						mutatorData: (val, data) => this.offeringModels.find(
							(model) => model.id === data.offeringid,
						),
					},
					{
						title: 'Group',
						field: 'groupName',
						cssClass: 'excelStyle',
						formatter: 'plaintext',
						mutatorData: (val, rowData) => {
							const productGroup = this.productGroupModels.find(
								(model) => model.id === rowData.groupid,
							);
							return productGroup?.name;
						},
						headerFilter: 'list',
						headerFilterParams: {
							values: groupNameFilterValues,
							clearable: true,
						},
					},
					{
						field: 'orderID',
						title: 'Order ID',
						formatter: (cell) => {
							const route = {
								name: 'showOrder',
								params: { orderid: cell.getValue() },
							};
							const link = document.createElement('a');
							link.href = this.$router.resolve(route).href;
							link.innerText = cell.getValue();
							link.addEventListener('click',
								(e) => {
									e.preventDefault();
									this.$router.push(route);
								});
							return link;
						},
						headerFilter: true,
					},
					{
						title: 'OrdeItem ID',
						field: 'id',
						formatter: (cell) => {
							const route = {
								name: 'orderItem',
								params: { orderItemId: cell.getValue() },
							};
							const link = document.createElement('a');
							link.href = this.$router.resolve(route).href;
							link.innerText = cell.getValue();
							link.addEventListener('click',
								(e) => {
									e.preventDefault();
									this.$router.push(route);
								});
							return link;
						},
						headerFilter: true,
					},
					{
						title: 'User ID',
						field: 'userid',
						formatter: (cell) => {
							const route = {
								name: 'UserProperties',
								params: { id: cell.getValue() },
							};
							const link = document.createElement('a');
							link.href = this.$router.resolve(route).href;
							link.innerText = cell.getValue();
							link.addEventListener('click',
								(e) => {
									e.preventDefault();
									this.$router.push(route);
								});
							return link;
						},
						headerFilter: true,
					},
					{
						title: 'Product ID',
						field: 'productid',
						formatter: (cell: CellComponent) => {
							const rowData = cell.getData() as DB.OrderItemModel;
							const link = document.createElement('a');
							link.href = `${this.domain}/app/showcase/${cell.getValue()}/${rowData.read_token}`;
							link.innerText = cell.getValue();
							link.addEventListener('click',
								(e) => {
									e.preventDefault();
									window.location.href = `${this.domain}/app/showcase/${cell.getValue()}/${rowData.read_token}`;
								});
							return link;
						},
						headerFilter: true,
					},
					{
						title: 'External Order ID',
						field: 'externalOrderId',
						headerFilter: true,
					},
					{
						title: 'Quantity',
						field: 'quantity',
						headerFilter: true,
					},
					{
						title: 'Pages',
						field: 'pages',
					},
					{
						title: 'Value',
						field: 'value',
						formatter: (cell: CellComponentExtended<DB.OrderItemModel>) => {
							const data = cell.getData();
							const instance = createInstance({
								component: CurrencySalesTextLink,
								props: {
									data,
								},
							});
							instance.$mount();
							return (instance.$el as HTMLElement);
						},
					},
					{
						title: 'Product Description',
						field: 'offeringDescription',
						headerFilter: true,
					},
					{
						title: 'Date',
						field: 'orderdate',
					},
					{
						title: 'Print File',
						formatter: (cell: CellComponentExtended<DB.OrderItemModel>) => {
							const instance = createInstance({
								component: TabulatorBtn,
								props: {
									data: cell.getData(),
									buttons: [
										{
											id: 'print',
											eventName: 'printFile',
											className: 'fa fa-fw fa-download',
										},
									],
									eventBus,
								},
							});
							instance.$mount();
							return (instance.$el as HTMLElement);
						},
					},
				] as ColumnDefinitionExtended[],
			},
		);
	}

	private get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	private getData(): Promise<DB.OrderItemModel[]> {
		const orderParameters = new URLSearchParams({
			limit: '0',
			orderby: 'orderdate DESC',
		});
		const offeringParameters = new URLSearchParams({
			limit: '0',
		});

		return Promise.all([
			httpClient.get<DB.OfferingModel[]>(`/api/offering?${offeringParameters}`),
			httpClient.get<DB.OrderItemModel[]>(`/api/ordersummary?${orderParameters}`),
			httpClient.get<DB.ProductGroupModel[]>('/api/productgroup?limit=0'),
		]).then(([
			offeringResponse,
			orderSummaryResponse,
			productGroupResponse,
		]) => {
			this.productGroupModels = productGroupResponse.data;
			this.offeringModels = offeringResponse.data;
			this.rowData = orderSummaryResponse.data;

			return this.rowData;
		}).catch((error: any) => {
			this.$bvToast.toast(
				`${error.message}`,
				{
					solid: true,
					variant: 'danger',
				},
			);

			return [];
		});
	}

	private async printFile(data: TabEvent<DB.OrderItemModel>['printFile']): Promise<void> {
		this.modalReference.show('bv-modal');
		this.isLoading = true;
		try {
			const response = await httpClient.get(`/api/orderitem/${data.id}/print`);
			this.printUrls = response.data;
		} catch (error: any) {
			this.$bvToast.toast(
				`${error.message}`,
				{
					solid: true,
					variant: 'danger',
				},
			);
		} finally {
			this.isLoading = false;
		}
	}
}
