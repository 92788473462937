var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "simplebar",
    { attrs: { id: "side-overlay" } },
    [
      _vm._t("default", function() {
        return [
          _c(
            "div",
            { staticClass: "content-header border-bottom" },
            [
              _c(
                "a",
                {
                  staticClass: "img-link mr-1",
                  attrs: { href: "javascript:void(0)" }
                },
                [
                  _c("img", {
                    staticClass: "img-avatar img-avatar32",
                    attrs: { alt: "Avatar" }
                  })
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "ml-2" }, [
                _c(
                  "a",
                  {
                    staticClass: "text-dark font-w600 font-size-sm",
                    attrs: { href: "javascript:void(0)" }
                  },
                  [_vm._v("Adam McCoy")]
                )
              ]),
              _vm._v(" "),
              _c(
                "base-layout-modifier",
                {
                  staticClass: "ml-auto",
                  attrs: {
                    action: "sideOverlayClose",
                    variant: "alt-danger",
                    size: "sm"
                  }
                },
                [_c("i", { staticClass: "fa fa-fw fa-times" })]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "content-side" },
            [
              _c(
                "b-tabs",
                {
                  staticClass: "block block-transparent pull-x pull-t",
                  attrs: {
                    "nav-class": "nav-tabs-alt",
                    "content-class": "block-content",
                    justified: ""
                  }
                },
                [
                  _c(
                    "b-tab",
                    {
                      staticClass: "fade-right pull-x",
                      attrs: { active: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function() {
                            return [
                              _c("i", {
                                staticClass: "fa fa-fw fa-coffee text-gray mr-1"
                              }),
                              _vm._v(" Overview\n\t\t\t\t\t")
                            ]
                          },
                          proxy: true
                        }
                      ])
                    },
                    [
                      _vm._v(" "),
                      _c(
                        "base-block",
                        {
                          attrs: {
                            title: "Recent Activity",
                            "header-bg": "",
                            "btn-option-content": ""
                          }
                        },
                        [
                          _c(
                            "ul",
                            { staticClass: "nav-items mb-0" },
                            _vm._l(_vm.activity, function(appEvent, index) {
                              return _c("li", { key: `event-${index}` }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "text-dark media py-2",
                                    attrs: { href: `${appEvent.href}` }
                                  },
                                  [
                                    _c("div", { staticClass: "mr-3 ml-2" }, [
                                      _c("i", {
                                        class: `${appEvent.icon} text-${appEvent.color}`
                                      })
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "media-body" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "font-size-sm font-w600"
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(appEvent.title) +
                                              "\n\t\t\t\t\t\t\t\t\t\t"
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { class: `text-${appEvent.color}` },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(appEvent.subtitle) +
                                              "\n\t\t\t\t\t\t\t\t\t\t"
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "small",
                                        {
                                          staticClass: "font-size-sm text-muted"
                                        },
                                        [_vm._v(_vm._s(appEvent.time))]
                                      )
                                    ])
                                  ]
                                )
                              ])
                            }),
                            0
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "base-block",
                        {
                          attrs: {
                            title: "Online Friends",
                            "header-bg": "",
                            "btn-option-content": ""
                          }
                        },
                        [
                          _c(
                            "ul",
                            { staticClass: "nav-items mb-0" },
                            _vm._l(_vm.userList, function(user, index) {
                              return _c("li", { key: `userlist-${index}` }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "media py-2",
                                    attrs: { href: `${user.href}` }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "mr-3 ml-2 overlay-container overlay-bottom"
                                      },
                                      [
                                        _c("img", {
                                          staticClass:
                                            "img-avatar img-avatar48",
                                          attrs: { alt: "Avatar" }
                                        }),
                                        _vm._v(" "),
                                        _c("span", {
                                          class: `overlay-item item item-tiny item-circle border border-2x border-white bg-${user.statusColor}`
                                        })
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "media-body" }, [
                                      _c("div", { staticClass: "font-w600" }, [
                                        _vm._v(_vm._s(user.name))
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "font-size-sm text-muted"
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(user.profession) +
                                              "\n\t\t\t\t\t\t\t\t\t\t"
                                          )
                                        ]
                                      )
                                    ])
                                  ]
                                )
                              ])
                            }),
                            0
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "base-block",
                        {
                          staticClass: "mb-0",
                          attrs: {
                            title: "Quick Settings",
                            "header-bg": "",
                            "btn-option-content": ""
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "p",
                                { staticClass: "font-size-sm font-w600 mb-2" },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\tOnline Status\n\t\t\t\t\t\t\t"
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-form-checkbox",
                                {
                                  staticClass: "mb-1",
                                  attrs: {
                                    name: "so-settings-status",
                                    switch: ""
                                  },
                                  model: {
                                    value: _vm.settings.status,
                                    callback: function($$v) {
                                      _vm.$set(_vm.settings, "status", $$v)
                                    },
                                    expression: "settings.status"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\tShow your status to all\n\t\t\t\t\t\t\t"
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "p",
                                { staticClass: "font-size-sm font-w600 mb-2" },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\tAuto Updates\n\t\t\t\t\t\t\t"
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-form-checkbox",
                                {
                                  staticClass: "mb-1",
                                  attrs: {
                                    name: "so-settings-updated",
                                    switch: ""
                                  },
                                  model: {
                                    value: _vm.settings.updated,
                                    callback: function($$v) {
                                      _vm.$set(_vm.settings, "updated", $$v)
                                    },
                                    expression: "settings.updated"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\tKeep up to date\n\t\t\t\t\t\t\t"
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "p",
                                { staticClass: "font-size-sm font-w600 mb-1" },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\tApplication Alerts\n\t\t\t\t\t\t\t"
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-form-checkbox",
                                {
                                  staticClass: "mb-1",
                                  attrs: {
                                    name: "so-settings-notifications-email",
                                    switch: ""
                                  },
                                  model: {
                                    value: _vm.settings.notifications.email,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.settings.notifications,
                                        "email",
                                        $$v
                                      )
                                    },
                                    expression: "settings.notifications.email"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\tEmail Notifications\n\t\t\t\t\t\t\t"
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-form-checkbox",
                                {
                                  staticClass: "mb-1",
                                  attrs: {
                                    name: "so-settings-notifications-sms",
                                    switch: ""
                                  },
                                  model: {
                                    value: _vm.settings.notifications.sms,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.settings.notifications,
                                        "sms",
                                        $$v
                                      )
                                    },
                                    expression: "settings.notifications.sms"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\tSMS Notifications\n\t\t\t\t\t\t\t"
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "p",
                                { staticClass: "font-size-sm font-w600 mb-1" },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\tAPI\n\t\t\t\t\t\t\t"
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-form-checkbox",
                                {
                                  staticClass: "mb-1",
                                  attrs: {
                                    name: "so-settings-api",
                                    switch: ""
                                  },
                                  model: {
                                    value: _vm.settings.api,
                                    callback: function($$v) {
                                      _vm.$set(_vm.settings, "api", $$v)
                                    },
                                    expression: "settings.api"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\tEnable access\n\t\t\t\t\t\t\t"
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    {
                      staticClass: "fade-left pull-x",
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function() {
                            return [
                              _c("i", {
                                staticClass:
                                  "fa fa-fw fa-chart-line text-gray mr-1"
                              }),
                              _vm._v(" Sales\n\t\t\t\t\t")
                            ]
                          },
                          proxy: true
                        }
                      ])
                    },
                    [
                      _vm._v(" "),
                      _c(
                        "base-block",
                        {
                          staticClass: "mb-0",
                          attrs: { "content-class": "p-0" }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "block-content" },
                            [
                              _c(
                                "b-row",
                                { staticClass: "items-push pull-t" },
                                [
                                  _c("b-col", { attrs: { cols: "6" } }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "font-size-sm font-w600 text-uppercase"
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t\tSales\n\t\t\t\t\t\t\t\t\t"
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "font-size-lg",
                                        attrs: { href: "javascript:void(0)" }
                                      },
                                      [_vm._v("22.030")]
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("b-col", { attrs: { cols: "6" } }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "font-size-sm font-w600 text-uppercase"
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t\tBalance\n\t\t\t\t\t\t\t\t\t"
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "font-size-lg",
                                        attrs: { href: "javascript:void(0)" }
                                      },
                                      [_vm._v("$4.589,00")]
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "block-content block-content-full block-content-sm bg-body-light"
                            },
                            [
                              _c(
                                "b-row",
                                [
                                  _c("b-col", { attrs: { cols: "6" } }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "font-size-sm font-w600 text-uppercase"
                                      },
                                      [_vm._v("Today")]
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "text-right",
                                      attrs: { cols: "6" }
                                    },
                                    [
                                      _c("span", { staticClass: "ext-muted" }, [
                                        _vm._v("$996")
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "block-content" }, [
                            _c(
                              "ul",
                              { staticClass: "nav-items push" },
                              _vm._l(_vm.salesToday, function(sale, index) {
                                return _c(
                                  "li",
                                  { key: `sale-today-${index}` },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "text-dark media py-2",
                                        attrs: { href: `${sale.href}` }
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "mr-3 ml-2" },
                                          [_c("i", { class: `${sale.icon}` })]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "media-body" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "font-w600" },
                                              [_vm._v(_vm._s(sale.title))]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "small",
                                              { staticClass: "text-muted" },
                                              [_vm._v(_vm._s(sale.time))]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              }),
                              0
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "block-content block-content-full block-content-sm bg-body-light"
                            },
                            [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-6" }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "font-size-sm font-w600 text-uppercase"
                                    },
                                    [_vm._v("Yesterday")]
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-6 text-right" }, [
                                  _c("span", { staticClass: "text-muted" }, [
                                    _vm._v("$765")
                                  ])
                                ])
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "block-content" }, [
                            _c(
                              "ul",
                              { staticClass: "nav-items push" },
                              _vm._l(_vm.salesYesterday, function(sale, index) {
                                return _c(
                                  "li",
                                  { key: `sale-yesterday-${index}` },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "text-dark media py-2",
                                        attrs: { href: `${sale.href}` }
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "mr-3 ml-2" },
                                          [_c("i", { class: `${sale.icon}` })]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "media-body" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "font-w600" },
                                              [_vm._v(_vm._s(sale.title))]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "small",
                                              { staticClass: "text-muted" },
                                              [_vm._v(_vm._s(sale.time))]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              }),
                              0
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "text-center" },
                              [
                                _c(
                                  "b-button",
                                  {
                                    attrs: {
                                      size: "sm",
                                      variant: "light",
                                      href: "javascript:void(0)"
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fa fa-arrow-down mr-1"
                                    }),
                                    _vm._v(" Load More..\n\t\t\t\t\t\t\t\t")
                                  ]
                                )
                              ],
                              1
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }