import { AuthPlugin } from 'types/auth-plugin';
import { NavigationGuard, Route } from 'vue-router';
import { getInstance } from './authWrapper';

const authGuard: NavigationGuard = (
	to: Route,
	from: Route,
	next: () => void,
): void => {
	const authService = getInstance();

	const fn = (): void => {
		if ((authService as AuthPlugin).isAuthenticated) {
			return next();
		}

		(authService as AuthPlugin).loginWithRedirect({ appState: { targetUrl: to.fullPath } });

		return undefined;
	};

	if (!(authService as AuthPlugin).loading) {
		return fn();
	}

	authService.$watch('loading',
		(loading: boolean): void => {
			if (loading === false) {
				return fn();
			}

			return undefined;
		});

	return undefined;
};

export default authGuard;
