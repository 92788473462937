import { Vue, Component, Watch } from 'vue-property-decorator';
import * as DB from 'interfaces/database';
import { httpClient } from '../../utils/http';

@Component({})
export default class EditHyperlinksTable extends Vue {
	private singleHyperlink = {} as DB.HyperlinkModel;

	private region: DB.RegionModel[] = [];

	private productCategory: DB.ProductCategoryModel[] = [];

	private language: DB.LanguageModel[] = [];

	private pdp: DB.PDPModel[] = [];

	private loaded = false;

	private visible = false;

	private spinner = false;

	private index = 0;

	private isLoading = false;

	private pageOptions = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50];

	get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	mounted(): void {
		this.getSingleHyperlink();
		this.fetchData();
	}

	@Watch('loggedIn')
	async fetchData(): Promise<void> {
		try {
			const [
				regionResponse,
				categoryResponse,
				languageResponse,
				pdpResponse,
			] = await Promise.all([
				httpClient.get<DB.RegionModel[]>('/api/region?limit=0'),
				httpClient.get<DB.ProductCategoryModel[]>('/api/productcategory?limit=0'),
				httpClient.get<DB.LanguageModel[]>('/api/language?limit=0'),
				httpClient.get<DB.PDPModel[]>('/api/pdp?limit=0'),
			]);
			this.region = regionResponse.data;
			this.productCategory = categoryResponse.data;
			this.language = languageResponse.data;
			this.pdp = pdpResponse.data;
		} catch (error: any) {
			this.$bvToast.toast(`${error.message}`,
				{
					solid: true,
					variant: 'danger',
				});
		}
	}

	@Watch('loggedIn')
	async getSingleHyperlink(): Promise<DB.HyperlinkModel | undefined> {
		if (this.loggedIn) {
			// set the loading state
			this.isLoading = true;
			try {
				const resp = await httpClient.get<DB.HyperlinkModel>(`/api/hyperlink/${this.$route.params.id}`);
				this.singleHyperlink = resp.data;
				// eslint-disable-next-line consistent-return
				return this.singleHyperlink;
			} catch (error: any) {
				this.$bvToast.toast(`${error.message}`,
					{
						solid: true,
						variant: 'danger',
					});
			} finally {
				this.isLoading = false;
			}
		}
		return undefined;
	}

	editHyperlink(): Promise<void> | undefined {
		if (this.loggedIn) {
			this.isLoading = true;
			httpClient.put<DB.Model3DModel>(`/api/hyperlink/${this.$route.params.id}`,
				{ ...this.singleHyperlink }).then(() => {
				this.$bvToast.toast('Hyperlink Edited successfully',
					{
						solid: true,
						variant: 'success',
					});
				return undefined;
			})
				.finally(() => {
					this.isLoading = false;
				})
				.catch((error) => {
					this.$bvToast.toast(`${error.message}`,
						{
							solid: true,
							variant: 'danger',
						});
				});
		}
		return undefined;
	}
}
