import { Vue, Component, Ref } from 'vue-property-decorator';
import ThemeEditPageTable from 'components/Themes/ThemesTable/ThemeEditPageTable';
import Template from './template.vue';

@Component({
	components: {
		ThemeEditPageTable,
	},
})
export default class ThemeEditPage extends Vue.extend(Template) {
	@Ref('themeeditpage')
	private readonly theme!: HTMLFormElement;

	private routeId = this.$route.params.id;

	private pageId = this.$route.params.pageid;

	protected routeTemplate(id: string): void {
		this.$router.push(`/template/${id}`);
	}

	protected routeLayout(id: string): void {
		this.$router.push(`/layout/${id}`);
	}

	protected routePageObject(id: string): void {
		this.$router.push({
			name: 'PageObject',
			params: {
				objectid: id,
				id: this.routeId,
				pageid: this.pageId,
			},
		});
	}

	protected updateThemeEditProperties(): void {
		this.theme.editTheme();
	}
}
