var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ml-5", staticStyle: { width: "95%" } },
    [
      _c("loading-overlay", {
        attrs: { active: _vm.isLoading, "can-cancel": true }
      }),
      _vm._v(" "),
      _c(
        "base-block",
        { attrs: { title: "API Permissions", "header-bg": "" } },
        [_c("div", { ref: "apiPermissionsTable" })]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }