import {
	Vue, Component, Watch, Prop,
} from 'vue-property-decorator';
import * as DB from 'interfaces/database';
import { httpClient } from 'utils/http';
import TemplateState from '../TemplateState';
import Template from './template.vue';

@Component({
	components: {
		TemplateState,
	},
})
export default class EditTemplateTable extends Vue.extend(Template) {
	private singleTemplate = {} as DB.TemplateModel;

	private loaded = false;

	private visible = false;

	private spinner = false;

	private index = 0;

	private rowHeight = 60;

	private isLoading = false;

	private get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	@Prop({
		type: String,
		required: true,
	})
	public readonly templateId!: string;

	protected mounted(): void {
		this.getSingleTemplate();
	}

	protected get isChecked(): boolean {
		return this.singleTemplate.transformable === 1;
	}

	protected onChangeCheckbox(event: number): void {
		this.singleTemplate.transformable = event;
	}

	@Watch('loggedIn')
	private async getSingleTemplate(): Promise<DB.TemplateModel | undefined> {
		if (this.loggedIn) {
			// set the loading state
			this.isLoading = true;
			try {
				const resp = await httpClient.get<DB.TemplateModel>(`/api/template/${this.templateId}`);
				this.singleTemplate = resp.data;
				// eslint-disable-next-line consistent-return
				return this.singleTemplate;
			} catch (error: any) {
				this.$bvToast.toast(`${error.message}`,
					{
						solid: true,
						variant: 'danger',
					});
			} finally {
				this.isLoading = false;
			}
		}
		return undefined;
	}

	protected routeTemplateEdit(
		data: {
			id: string,
			templateid: string
		},
	): void {
		this.$emit('routeTemplateEdit',
			{
				id: data.id,
				templateid: data.templateid,
			});
	}

	protected async editTemplate(): Promise<void> {
		if (this.loggedIn) {
			try {
				this.isLoading = true;
				await httpClient.put<DB.TemplateModel>(`/api/template/${this.templateId}`,
					{ ...this.singleTemplate });
				this.$bvToast.toast('Template Edited successfully',
					{
						solid: true,
						variant: 'success',
					});
			} catch (error: any) {
				this.$bvToast.toast(`${error.message}`,
					{
						solid: true,
						variant: 'danger',
					});
			} finally {
				this.isLoading = false;
			}
		}
	}
}
