import { Vue, Component, Ref } from 'vue-property-decorator';
import EditLayoutsTable from 'components/Themes/LayoutsTable/EditLayoutsTable';
import Template from './template.vue';

@Component({
	components: {
		EditLayoutsTable,
	},
})
export default class EditLayout extends Vue.extend(Template) {
	@Ref('editlayout')
	private readonly layout!: HTMLFormElement;

	private routeId = this.$route.params.id;

	protected updateLayout(): void {
		this.layout.editLayout();
	}

	protected routeLayoutTemplate(id: string): void {
		this.$router.push(`/template/${id}`);
	}
}
