import { Vue, Component } from 'vue-property-decorator';
import PickupStoresTable from 'components/PickupStoresTable';
import Template from './template.vue';

@Component({
	components: {
		PickupStoresTable,
	},
})
export default class PickupStores extends Vue.extend(Template) {
	protected showModal(): void {
		this.$root.$emit('bv::show::modal',
			'pickupstores-modal',
			'#pickupstoresShow');
	}

	protected routePickup(id: string): void {
		this.$router.push(`/branch/${id}`);
	}
}
