var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex align-items-center" },
    [
      _c("b-form-checkbox", {
        attrs: { size: "lg", checked: _vm.checked },
        on: {
          change: function($event) {
            return _vm.onChange($event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }