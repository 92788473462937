var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ml-5 mt-4", staticStyle: { width: "95%", height: "100%" } },
    [
      _c(
        "b-modal",
        {
          ref: "reamark-modal",
          attrs: { id: "remark-modal", "hide-footer": "" },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function() {
                return [_vm._v("\n\t\t\tEdit remark\n\t\t")]
              },
              proxy: true
            }
          ])
        },
        [
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-block text-center" },
            [
              _c(
                "b-form-group",
                {
                  staticClass: "form-group",
                  attrs: { label: "Name: ", "label-for": "block-form1-name" }
                },
                [
                  _c("b-form-input", {
                    attrs: { type: "text" },
                    model: {
                      value: _vm.addRemarkData.remark,
                      callback: function($$v) {
                        _vm.$set(_vm.addRemarkData, "remark", $$v)
                      },
                      expression: "addRemarkData.remark"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { block: "" },
              on: {
                click: function($event) {
                  return _vm.addRemark()
                }
              }
            },
            [_vm._v("\n\t\t\tSave\n\t\t")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "12", lg: "12", sm: "12" } },
            [
              _c(
                "b-tabs",
                { attrs: { card: "", "content-class": "mt-3", fill: "" } },
                [
                  _c(
                    "b-tab",
                    { attrs: { title: "Properties", active: "" } },
                    [
                      _c(
                        "base-block",
                        { attrs: { title: "Discount Info", "header-bg": "" } },
                        [
                          _c(
                            "b-table-simple",
                            {
                              attrs: {
                                responsive: "",
                                "table-class": "table-vcenter"
                              }
                            },
                            [
                              _c("b-thead", {
                                attrs: { "head-variant": "light" }
                              }),
                              _vm._v(" "),
                              _c(
                                "b-tbody",
                                [
                                  _c(
                                    "b-tr",
                                    [
                                      _c("b-td", [
                                        _c("h6", [_vm._v("Claimed")]),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            "Number of vouchers that have been entered by users in the app"
                                          )
                                        ])
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "b-td",
                                        [
                                          _c(
                                            "b-badge",
                                            {
                                              attrs: {
                                                pill: "",
                                                variant: "secondary"
                                              }
                                            },
                                            [_c("h3", [_vm._v("3")])]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-tr",
                                    [
                                      _c("b-td", [
                                        _c("h6", [
                                          _vm._v("Total Vouchers Issued")
                                        ]),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            "Number of vouchers that have been issued through the dashboard"
                                          )
                                        ])
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "b-td",
                                        [
                                          _c(
                                            "b-badge",
                                            {
                                              attrs: {
                                                pill: "",
                                                variant: "secondary"
                                              }
                                            },
                                            [_c("h3", [_vm._v("2")])]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-tr",
                                    [
                                      _c("b-td", [
                                        _c("h6", [
                                          _vm._v("Total Vouchers Used")
                                        ]),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            "Number of vouchers that have been used for purchase"
                                          )
                                        ])
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "b-td",
                                        [
                                          _c(
                                            "b-badge",
                                            {
                                              attrs: {
                                                pill: "",
                                                variant: "secondary"
                                              }
                                            },
                                            [_c("h3", [_vm._v("1")])]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    { attrs: { title: "Discount Properties" } },
                    [
                      _c(
                        "base-block",
                        {
                          attrs: {
                            title: "Discount Properties",
                            "header-bg": ""
                          }
                        },
                        [
                          _vm.spinner
                            ? _c("div", { staticClass: "text-center" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "spinner-grow text-primary m-5",
                                    attrs: { role: "status" }
                                  },
                                  [
                                    _c("span", { staticClass: "sr-only" }, [
                                      _vm._v("Loading...")
                                    ])
                                  ]
                                )
                              ])
                            : _c(
                                "div",
                                { staticClass: "d-block text-center" },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "form-group",
                                      attrs: {
                                        label: "Campaign title: ",
                                        "label-for": "block-form1-name",
                                        "label-cols-lg": "5"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "control" },
                                        [
                                          _c("b-form-input", {
                                            attrs: { type: "text" },
                                            model: {
                                              value:
                                                _vm.singleDiscountData.name,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.singleDiscountData,
                                                  "name",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "singleDiscountData.name"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Tracker:",
                                        "label-for": "example-select1",
                                        "label-cols-lg": "5"
                                      }
                                    },
                                    [
                                      _c(
                                        "b-input-group",
                                        [
                                          _c(
                                            "b-form-select",
                                            {
                                              attrs: {
                                                id: "example-select8",
                                                plain: ""
                                              },
                                              model: {
                                                value:
                                                  _vm.singleDiscountData
                                                    .trackerid,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.singleDiscountData,
                                                    "trackerid",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "singleDiscountData.trackerid"
                                              }
                                            },
                                            [
                                              _c(
                                                "b-form-select-option",
                                                { attrs: { value: null } },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\tPlease select an Option\n\t\t\t\t\t\t\t\t\t\t"
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _vm._l(_vm.trackerData, function(
                                                track,
                                                id
                                              ) {
                                                return _c(
                                                  "b-form-select-option",
                                                  {
                                                    key: id,
                                                    attrs: {
                                                      value:
                                                        _vm.trackerData[
                                                          _vm.index
                                                        ].id
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(track.name) +
                                                        "\n\t\t\t\t\t\t\t\t\t\t"
                                                    )
                                                  ]
                                                )
                                              })
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Referral Program:",
                                        "label-for": "example-select2",
                                        "label-cols-lg": "5"
                                      }
                                    },
                                    [
                                      _c(
                                        "b-input-group",
                                        [
                                          _c(
                                            "b-form-select",
                                            {
                                              attrs: {
                                                id: "example-select8",
                                                plain: ""
                                              },
                                              model: {
                                                value:
                                                  _vm.singleDiscountData
                                                    .referralprogramid,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.singleDiscountData,
                                                    "referralprogramid",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "singleDiscountData.referralprogramid"
                                              }
                                            },
                                            [
                                              _c(
                                                "b-form-select-option",
                                                { attrs: { value: null } },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\tPlease select an Option\n\t\t\t\t\t\t\t\t\t\t"
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _vm._l(_vm.referral, function(
                                                refer,
                                                id
                                              ) {
                                                return _c(
                                                  "b-form-select-option",
                                                  {
                                                    key: id,
                                                    attrs: { value: id }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(refer.name) +
                                                        "\n\t\t\t\t\t\t\t\t\t\t"
                                                    )
                                                  ]
                                                )
                                              })
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Percentage:",
                                        "label-for": "example-select",
                                        "label-cols-lg": "5"
                                      }
                                    },
                                    [
                                      _c(
                                        "b-input-group",
                                        {
                                          scopedSlots: _vm._u([
                                            {
                                              key: "append",
                                              fn: function() {
                                                return [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t%\n\t\t\t\t\t\t\t\t\t"
                                                  )
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ])
                                        },
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              type: "text",
                                              disabled: ""
                                            },
                                            model: {
                                              value:
                                                _vm.singleDiscountData
                                                  .percentage,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.singleDiscountData,
                                                  "percentage",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "singleDiscountData.percentage"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "form-group",
                                      attrs: {
                                        label: "Max. Products:",
                                        "label-for": "block-form1-name",
                                        "label-cols-lg": "5"
                                      }
                                    },
                                    [
                                      _c(
                                        "b-badge",
                                        {
                                          attrs: {
                                            pill: "",
                                            variant: "secondary"
                                          }
                                        },
                                        [
                                          _c("h3", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.singleDiscountData
                                                    .maxproduct
                                                )
                                            )
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Extra Pages:",
                                        "label-for": "example-select",
                                        "label-cols-lg": "5"
                                      }
                                    },
                                    [
                                      _c(
                                        "b-input-group",
                                        [
                                          _c("b-form-input", {
                                            attrs: { type: "text" },
                                            model: {
                                              value:
                                                _vm.singleDiscountData
                                                  .extrapages,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.singleDiscountData,
                                                  "extrapages",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "singleDiscountData.extrapages"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Max orders:",
                                        "label-for": "example-select",
                                        "label-cols-lg": "5"
                                      }
                                    },
                                    [
                                      _c(
                                        "b-input-group",
                                        [
                                          _c("b-form-input", {
                                            attrs: { type: "text" },
                                            model: {
                                              value:
                                                _vm.singleDiscountData
                                                  .maxorders,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.singleDiscountData,
                                                  "maxorders",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "singleDiscountData.maxorders"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Global Shipping discount:",
                                        "label-for": "example-select2",
                                        "label-cols-lg": "5"
                                      }
                                    },
                                    [
                                      _c(
                                        "b-input-group",
                                        [
                                          _c(
                                            "b-form-select",
                                            {
                                              attrs: {
                                                id: "example-select8",
                                                plain: ""
                                              },
                                              model: {
                                                value:
                                                  _vm.singleDiscountData
                                                    .shipping_discount,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.singleDiscountData,
                                                    "shipping_discount",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "singleDiscountData.shipping_discount"
                                              }
                                            },
                                            [
                                              _c(
                                                "b-form-select-option",
                                                { attrs: { value: null } },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\tPlease select an Option\n\t\t\t\t\t\t\t\t\t\t"
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _vm._l(
                                                _vm.shippingOptions,
                                                function(ship, id) {
                                                  return _c(
                                                    "b-form-select-option",
                                                    {
                                                      key: id,
                                                      attrs: { value: ship }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(ship) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t"
                                                      )
                                                    ]
                                                  )
                                                }
                                              )
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Free handling:",
                                        "label-for": "example-select",
                                        "label-cols-lg": "5"
                                      }
                                    },
                                    [
                                      _vm.singleDiscountData.free_handling
                                        ? _c("i", {
                                            staticClass: "fa fa-fw fa-check"
                                          })
                                        : _c("i", {
                                            staticClass: "fa fa-fw fa-times"
                                          })
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "1 voucher per user:",
                                        "label-for": "example-select",
                                        "label-cols-lg": "5"
                                      }
                                    },
                                    [
                                      _vm.singleDiscountData.voucher_per_user
                                        ? _c("i", {
                                            staticClass: "fa fa-fw fa-check"
                                          })
                                        : _c("i", {
                                            staticClass: "fa fa-fw fa-times"
                                          })
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Registered users only?",
                                        "label-class": "font-w600",
                                        "label-cols-lg": "5"
                                      }
                                    },
                                    [
                                      _c("b-form-checkbox", {
                                        staticClass: "cameraCheckbox",
                                        attrs: {
                                          switch: "",
                                          size: "lg",
                                          checked:
                                            _vm.singleDiscountData
                                              .registered === 1
                                        },
                                        on: {
                                          change: function($event) {
                                            _vm.singleDiscountData.registered = $event
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "New customers only?",
                                        "label-class": "font-w600",
                                        "label-cols-lg": "5"
                                      }
                                    },
                                    [
                                      _c("b-form-checkbox", {
                                        staticClass: "cameraCheckbox",
                                        attrs: {
                                          switch: "",
                                          size: "lg",
                                          checked:
                                            _vm.singleDiscountData
                                              .newcustomers === 1
                                        },
                                        on: {
                                          change: function($event) {
                                            _vm.singleDiscountData.newcustomers = $event
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    { attrs: { title: "Discount Vouchers" } },
                    [
                      _c(
                        "base-block",
                        {
                          attrs: {
                            title: "Discount Vouchers",
                            "header-bg": ""
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "options",
                              fn: function() {
                                return [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: {
                                        type: "submit",
                                        size: "sm",
                                        variant: "primary"
                                      },
                                      on: { click: _vm.toggleDiscountVoucher }
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\tCreate new\n\t\t\t\t\t\t\t"
                                      )
                                    ]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _vm._v(" "),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.showDiscountVoucher,
                                      expression: "showDiscountVoucher"
                                    }
                                  ],
                                  attrs: { lg: "12" }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "d-block text-left" },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "Start Date",
                                            "label-for": "example-select",
                                            "label-cols-lg": "4"
                                          }
                                        },
                                        [
                                          _c("b-form-datepicker", {
                                            staticClass: "mb-2",
                                            attrs: { id: "example-datepicker" },
                                            model: {
                                              value:
                                                _vm.discountVoucherData
                                                  .startddate,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.discountVoucherData,
                                                  "startddate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "discountVoucherData.startddate"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "end Date",
                                            "label-for": "example-select",
                                            "label-cols-lg": "4"
                                          }
                                        },
                                        [
                                          _c("b-form-datepicker", {
                                            staticClass: "mb-2",
                                            attrs: { id: "example-datepicker" },
                                            model: {
                                              value:
                                                _vm.discountVoucherData.enddate,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.discountVoucherData,
                                                  "enddate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "discountVoucherData.enddate"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "Add vouchers:",
                                            "label-for": "example-select",
                                            "label-cols-lg": "4"
                                          }
                                        },
                                        [
                                          _c(
                                            "b-input-group",
                                            [
                                              _c("b-form-input", {
                                                attrs: { type: "text" },
                                                model: {
                                                  value:
                                                    _vm.discountVoucherData
                                                      .code,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.discountVoucherData,
                                                      "code",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "discountVoucherData.code"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "Voucher length:",
                                            "label-for": "example-select",
                                            "label-cols-lg": "4"
                                          }
                                        },
                                        [
                                          _c(
                                            "b-input-group",
                                            [
                                              _c("b-form-input", {
                                                attrs: { type: "text" },
                                                model: {
                                                  value: _vm.voucherLength,
                                                  callback: function($$v) {
                                                    _vm.voucherLength = $$v
                                                  },
                                                  expression: "voucherLength"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "mt-3 mb-2",
                                          on: {
                                            click: function($event) {
                                              return _vm.saveDiscountVoucher()
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\tsave\n\t\t\t\t\t\t\t\t\t"
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-row",
                            {
                              staticClass:
                                "d-flex flex-column justify-content-left"
                            },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass: "my-1",
                                  attrs: { sm: "5", md: "9" }
                                },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "mt-3",
                                      attrs: {
                                        disabled: _vm.selectedRows.length == 0
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.table.deselectRow()
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\tDeselect all\n\t\t\t\t\t\t\t\t"
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "mt-3",
                                      attrs: {
                                        disabled: _vm.selectedRows.length == 0
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.exportSelected()
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\tExport Selected\n\t\t\t\t\t\t\t\t"
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "mt-3",
                                      on: {
                                        click: function($event) {
                                          return _vm.exportAll()
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\tExport all\n\t\t\t\t\t\t\t\t"
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { ref: "discountVouchersTable" })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    { attrs: { title: "Valid Offerings" } },
                    [
                      _c(
                        "base-block",
                        {
                          attrs: { title: "Valid Offerings", "header-bg": "" }
                        },
                        [
                          _c(
                            "div",
                            [
                              _c("b-form-group", {
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function({ ariaDescribedby }) {
                                      return [
                                        _c("b-form-checkbox-group", {
                                          attrs: {
                                            id: "checkbox-group-1",
                                            options: _vm.productGroup,
                                            "value-field": "id",
                                            "text-field": "name",
                                            "aria-describedby": ariaDescribedby,
                                            name: "flavour-1"
                                          },
                                          model: {
                                            value: _vm.selectedOfferings,
                                            callback: function($$v) {
                                              _vm.selectedOfferings = $$v
                                            },
                                            expression: "selectedOfferings"
                                          }
                                        })
                                      ]
                                    }
                                  }
                                ])
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm._l(_vm.offering, function(off, index) {
                            return _c(
                              "b-row",
                              { key: index },
                              [
                                _c(
                                  "b-col",
                                  { attrs: { md: "4" } },
                                  [
                                    _c("b-form-checkbox", {
                                      attrs: {
                                        switch: "",
                                        size: "lg",
                                        checked: _vm.isChecked(off)
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("b-col", { attrs: { md: "8" } }, [
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(off.id) +
                                        " - " +
                                        _vm._s(off.name) +
                                        " - " +
                                        _vm._s(off.description)
                                    )
                                  ])
                                ])
                              ],
                              1
                            )
                          })
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    { attrs: { title: "Theme Categories" } },
                    [
                      _c(
                        "base-block",
                        {
                          attrs: { title: "Theme Categories", "header-bg": "" }
                        },
                        [
                          _c("loading-overlay", {
                            attrs: { active: _vm.isLoading, "can-cancel": true }
                          }),
                          _vm._v(" "),
                          _c(
                            "b-row",
                            { staticClass: "mb-3" },
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "5" } },
                                [
                                  _c(
                                    "b-card",
                                    { attrs: { header: "Available" } },
                                    [
                                      _c(
                                        "b-list-group",
                                        {
                                          staticStyle: {
                                            "overflow-y": "auto",
                                            height: "200px"
                                          }
                                        },
                                        _vm._l(
                                          _vm.availableCategories,
                                          function(category, index) {
                                            return _c(
                                              "b-list-group-item",
                                              {
                                                key: category.id,
                                                attrs: {
                                                  active:
                                                    _vm.selectedCategoryActiveIndex ===
                                                    index
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.availableItem(
                                                      category,
                                                      index
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      `(${category.locale})`
                                                    ) +
                                                    " " +
                                                    _vm._s(category.name) +
                                                    "\n\t\t\t\t\t\t\t\t\t\t"
                                                )
                                              ]
                                            )
                                          }
                                        ),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "d-flex flex-column justify-content-around align-items-center",
                                  attrs: { md: "2" }
                                },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: {
                                        size: "sm",
                                        variant: "outline-dark"
                                      },
                                      on: { click: _vm.addToSelected }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-arrow-right mr-1"
                                      })
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-button",
                                    {
                                      attrs: {
                                        size: "sm",
                                        variant: "outline-dark"
                                      },
                                      on: { click: _vm.removeFromSelected }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-arrow-left mr-1"
                                      })
                                    ]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-col",
                                { attrs: { md: "5" } },
                                [
                                  _c(
                                    "b-card",
                                    { attrs: { header: "Selected" } },
                                    [
                                      _c(
                                        "b-list-group",
                                        {
                                          staticStyle: {
                                            "overflow-y": "auto",
                                            height: "200px"
                                          }
                                        },
                                        _vm._l(_vm.selectedCategories, function(
                                          category,
                                          index
                                        ) {
                                          return _c(
                                            "b-list-group-item",
                                            {
                                              key: category.id,
                                              attrs: {
                                                active:
                                                  _vm.availableCategoryActiveIndex ===
                                                  index
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.selectedItem(
                                                    category,
                                                    index
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    `(${category.locale})`
                                                  ) +
                                                  " " +
                                                  _vm._s(category.name) +
                                                  "\n\t\t\t\t\t\t\t\t\t\t"
                                              )
                                            ]
                                          )
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }