import { Vue, Component } from 'vue-property-decorator';
import TemplateTable from 'components/Themes/TemplateTable';
import TemplateView from './template.vue';

@Component({
	components: {
		TemplateTable,
	},
})
export default class Template extends Vue.extend(TemplateView) {
	protected showModal(): void {
		this.$root.$emit('bv::show::modal',
			'template-modal',
			'#templateShow');
	}

	protected showImport(): void {
		this.$root.$emit('bv::show::modal',
			'import-modal',
			'#importShow');
	}

	protected routeTemplate(id: string): void {
		this.$router.push(`/template/${id}`);
	}

	protected openInNewTab(id: string): void {
		const route = this.$router.resolve({ name: 'EditTemplate', params: { id } });
		window.open(route.href,
			'_blank');
	}
}
