import axios from 'axios';
import Vue from 'vue';

export const creatorDomain = CREATOR_HOST;

export const httpClient = axios.create({
	baseURL: BASE_URL,
	headers: {
		'Content-Type': 'application/json',
	},
});

httpClient.interceptors.request.use(async (config) => {
	const token = await Vue.prototype.$auth.getIdTokenRaw();
	// eslint-disable-next-line no-param-reassign
	config.headers.Authorization = `AdminBearer ${token}`;
	return config;
// eslint-disable-next-line promise/no-promise-in-callback
},
(error) => Promise.reject(error));
