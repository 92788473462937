import { Vue, Component, Ref } from 'vue-property-decorator';
import EditThemesTable from 'components/Themes/ThemesTable/EditThemesTable';
import Template from './template.vue';

@Component({
	components: {
		EditThemesTable,
	},
})
export default class EditTheme extends Vue.extend(Template) {
	@Ref('edittheme')
	private readonly theme!: HTMLFormElement;

	private routeId = this.$route.params.id;

	protected updateThemeProperties(): void {
		this.theme.editTheme();
	}

	protected routeLayout(id: string): void {
		this.$router.push(`/layout/${id}`);
	}

	protected routeThemePages(id: string): void {
		this.$router.push(`/theme/${this.routeId}/page/${id}`);
	}
}
