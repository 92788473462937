var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("loading-overlay", {
        attrs: { active: _vm.isLoading, "can-cancel": true }
      }),
      _vm._v(" "),
      _vm.spinner
        ? _c("div", { staticClass: "text-center" }, [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "base-block",
        {
          attrs: { rounded: "", title: "Display Frame", "header-bg": "" },
          scopedSlots: _vm._u([
            {
              key: "options",
              fn: function() {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { type: "submit", size: "sm", variant: "primary" },
                      on: {
                        click: function($event) {
                          _vm.isHidden = !_vm.isHidden
                        }
                      }
                    },
                    [_vm._v("\n\t\t\t\tAdd\n\t\t\t")]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _vm._v(" "),
          _c(
            "b-col",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isHidden,
                  expression: "isHidden"
                }
              ],
              attrs: { lg: "12" }
            },
            [
              _c(
                "b-form-group",
                { attrs: { label: "Image", "label-cols-lg": "5" } },
                [
                  _c("b-form-file", {
                    ref: "image",
                    attrs: {
                      id: "image",
                      placeholder: _vm.placeHolders.thumbnailPlaceholder
                    },
                    nativeOn: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.updateImage.apply(null, arguments)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.imagePreview
                    ? _c("div", { staticClass: "thumbnail" }, [
                        _c("img", {
                          attrs: {
                            src: _vm.imagePreview,
                            alt: "",
                            height: "100",
                            width: "100"
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: { cursor: "pointer" },
                            on: { click: _vm.removeImage }
                          },
                          [_vm._v("\n\t\t\t\t\t\t✖ Remove\n\t\t\t\t\t")]
                        )
                      ])
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-group",
                {
                  staticClass: "form-group",
                  attrs: {
                    label: "X-axis",
                    "label-for": "block-form1-username",
                    "label-cols-lg": "5"
                  }
                },
                [
                  _c(
                    "b-input-group",
                    {
                      scopedSlots: _vm._u([
                        {
                          key: "append",
                          fn: function() {
                            return [
                              _c(
                                "b-input-group-text",
                                { staticClass: "input-group-text-alt" },
                                [
                                  _c("b-form-checkbox", {
                                    attrs: {
                                      id: "checkbox-8",
                                      name: "checkbox-1",
                                      checked:
                                        _vm.frame.frame_x == null ? true : false
                                    },
                                    on: {
                                      change: () => (_vm.frame.frame_x = null)
                                    }
                                  }),
                                  _vm._v("\n\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t")
                                ],
                                1
                              )
                            ]
                          },
                          proxy: true
                        }
                      ])
                    },
                    [
                      _c("b-form-input", {
                        attrs: { type: "email" },
                        model: {
                          value: _vm.frame.frame_x,
                          callback: function($$v) {
                            _vm.$set(_vm.frame, "frame_x", $$v)
                          },
                          expression: "frame.frame_x"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-group",
                {
                  staticClass: "form-group",
                  attrs: {
                    label: "Y-axis",
                    "label-for": "block-form1-username",
                    "label-cols-lg": "5"
                  }
                },
                [
                  _c(
                    "b-input-group",
                    {
                      scopedSlots: _vm._u([
                        {
                          key: "append",
                          fn: function() {
                            return [
                              _c(
                                "b-input-group-text",
                                { staticClass: "input-group-text-alt" },
                                [
                                  _c("b-form-checkbox", {
                                    attrs: {
                                      id: "checkbox-8",
                                      name: "checkbox-1",
                                      checked:
                                        _vm.frame.frame_y == null ? true : false
                                    },
                                    on: {
                                      change: () => (_vm.frame.frame_y = null)
                                    }
                                  }),
                                  _vm._v("\n\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t")
                                ],
                                1
                              )
                            ]
                          },
                          proxy: true
                        }
                      ])
                    },
                    [
                      _c("b-form-input", {
                        attrs: { type: "email" },
                        model: {
                          value: _vm.frame.frame_y,
                          callback: function($$v) {
                            _vm.$set(_vm.frame, "frame_y", $$v)
                          },
                          expression: "frame.frame_y"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-group",
                {
                  staticClass: "form-group",
                  attrs: {
                    label: "Width",
                    "label-for": "block-form1-username",
                    "label-cols-lg": "5"
                  }
                },
                [
                  _c(
                    "b-input-group",
                    {
                      scopedSlots: _vm._u([
                        {
                          key: "append",
                          fn: function() {
                            return [
                              _c(
                                "b-input-group-text",
                                { staticClass: "input-group-text-alt" },
                                [
                                  _c("b-form-checkbox", {
                                    attrs: {
                                      id: "checkbox-8",
                                      name: "checkbox-1",
                                      checked:
                                        _vm.frame.frame_width == null
                                          ? true
                                          : false
                                    },
                                    on: {
                                      change: () =>
                                        (_vm.frame.frame_width = null)
                                    }
                                  }),
                                  _vm._v("\n\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t")
                                ],
                                1
                              )
                            ]
                          },
                          proxy: true
                        }
                      ])
                    },
                    [
                      _c("b-form-input", {
                        attrs: { type: "email" },
                        model: {
                          value: _vm.frame.frame_width,
                          callback: function($$v) {
                            _vm.$set(_vm.frame, "frame_width", $$v)
                          },
                          expression: "frame.frame_width"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-group",
                {
                  staticClass: "form-group",
                  attrs: {
                    label: "Height",
                    "label-for": "block-form1-username",
                    "label-cols-lg": "5"
                  }
                },
                [
                  _c(
                    "b-input-group",
                    {
                      scopedSlots: _vm._u([
                        {
                          key: "append",
                          fn: function() {
                            return [
                              _c(
                                "b-input-group-text",
                                { staticClass: "input-group-text-alt" },
                                [
                                  _c("b-form-checkbox", {
                                    attrs: {
                                      id: "checkbox-8",
                                      name: "checkbox-1",
                                      checked:
                                        _vm.frame.frame_height == null
                                          ? true
                                          : false
                                    },
                                    on: {
                                      change: () =>
                                        (_vm.frame.frame_height = null)
                                    }
                                  }),
                                  _vm._v("\n\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t")
                                ],
                                1
                              )
                            ]
                          },
                          proxy: true
                        }
                      ])
                    },
                    [
                      _c("b-form-input", {
                        attrs: { type: "email" },
                        model: {
                          value: _vm.frame.frame_height,
                          callback: function($$v) {
                            _vm.$set(_vm.frame, "frame_height", $$v)
                          },
                          expression: "frame.frame_height"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Required",
                    "label-class": "font-w600",
                    "label-cols-lg": "5"
                  }
                },
                [
                  _c("b-form-checkbox", {
                    attrs: {
                      switch: "",
                      size: "lg",
                      checked: _vm.frame.required === 1 ? true : false
                    },
                    on: {
                      change: function($event) {
                        _vm.frame.required = $event
                      }
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Show on top?",
                    "label-class": "font-w600",
                    "label-cols-lg": "5"
                  }
                },
                [
                  _c("b-form-checkbox", {
                    attrs: {
                      switch: "",
                      size: "lg",
                      checked: _vm.frame.overpage === 1 ? true : false
                    },
                    on: {
                      change: function($event) {
                        _vm.frame.overpage = $event
                      }
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: { type: "submit", variant: "primary" },
                  on: { click: _vm.handleClick }
                },
                [
                  _vm._v(
                    "\n\t\t\t\t" +
                      _vm._s(_vm.frameDataExist ? "Edit" : "Add") +
                      "\n\t\t\t"
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "spinner-grow text-primary m-5",
        attrs: { role: "status" }
      },
      [_c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }