import 'core-js/stable';
import Vue from 'vue';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import 'bootstrap';
import BootstrapVue from 'bootstrap-vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faLink, faUser, faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import VueSweetalert2 from 'vue-sweetalert2';
import VueObserveVisibility from 'vue-observe-visibility';
import CountryFlag from 'vue-country-flag';
import * as filestack from 'filestack-js';
import {
	ValidationProvider, ValidationObserver, extend, localize,
} from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import en from 'vee-validate/dist/locale/en.json';
import nl from 'vee-validate/dist/locale/nl.json';
import fr from 'vee-validate/dist/locale/fr.json';
import es from 'vee-validate/dist/locale/es.json';
import store from './store/index';
// Your app initialization logic goes here
import App from './App.vue';
import router from './router';
import { Auth0Plugin } from './auth/authWrapper';
// Custom components
import BaseLayoutModifier from './components/BaseLayoutModifier.vue';
import BaseBlock from './components/BaseBlock.vue';
import BaseBackground from './components/BaseBackground.vue';
import BasePageHeading from './components/BasePageHeading.vue';
import BaseNavigation from './components/BaseNavigation.vue';
// Custom directives
import clickRipple from './directives/clickRipple';
import toggleClass from './directives/toggleClass';
// Bootstrap Vue
import 'ag-grid-enterprise';
// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';
import 'tabulator-tables/dist/css/tabulator.css';

Bugsnag.start({
	apiKey: '58f627a9cb9aa4dc8642806705b3c189',
	plugins: [new BugsnagPluginVue()],
	onUncaughtException: (event) => {
		// report error to bugsnag
		Bugsnag.notify(event);
	},
	// report errors to bugnag dashboard
	autoTrackSessions: true,
	enabledReleaseStages: ['production', 'staging', 'development'],
});
const bugsnagVue = Bugsnag.getPlugin('vue');
bugsnagVue?.installVueErrorHandler(Vue);
Vue.config.productionTip = false;
Vue.use(Auth0Plugin,
	{
		domain: AUTH0_DOMAIN,
		clientId: AUTH0_CLIENTID,
		responseType: 'token id_token',
		onRedirectCallback: (appState?: Record<string, string>) => {
			router.push(
				appState && appState.targetUrl
					? appState.targetUrl
					: window.location.pathname,
			);
		},
	});
// initialize filestack
const client = filestack.init(FILESTACK_API_KEY);
Vue.use(VueObserveVisibility);
Vue.use(BootstrapVue);
Vue.use(VueSweetalert2);
// Register global components
Vue.component('LoadingOverlay',
	Loading);
Vue.component(BaseLayoutModifier.name,
	BaseLayoutModifier);
Vue.component(BaseBlock.name,
	BaseBlock);
Vue.component(BaseBackground.name,
	BaseBackground);
Vue.component(BasePageHeading.name,
	BasePageHeading);
Vue.component(BaseNavigation.name,
	BaseNavigation);
Vue.component('CountryFlag',
	CountryFlag);
Vue.component('ValidationProvider',
	ValidationProvider);
Vue.component('ValidationObserver',
	ValidationObserver);

// eslint-disable-next-line no-restricted-syntax
for (const [rule, validation] of Object.entries(rules)) {
	extend(rule,
		validation);
}

localize({
	en,
	es,
	fr,
	nl,
});

// Register global directives
Vue.directive('click-ripple',
	clickRipple);
Vue.directive('toggle-class',
	toggleClass);

library.add(faLink,
	faUser,
	faPowerOff);
Vue.component('FontAwesomeIcon',
	FontAwesomeIcon);

const app = new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount('#app');
Vue.prototype.$app = app;
Vue.prototype.$client = client;
