import { Vue, Component, Ref } from 'vue-property-decorator';
import EditCountryTable from 'components/CountryTable/EditCountryTable.vue';

@Component({
	components: {
		EditCountryTable,
	},
})
export default class EditCountry extends Vue {
	@Ref('editcountry') readonly countryModal!: HTMLFormElement;

	private isLoading = false;

	get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	updateCountryProperties(): void {
		this.countryModal.editCountry();
	}
}
