var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-block",
    {
      attrs: { title: "Themes Page Object Table", "header-bg": "" },
      scopedSlots: _vm._u([
        {
          key: "options",
          fn: function() {
            return [
              _c(
                "b-button",
                {
                  attrs: { type: "submit", size: "sm", variant: "primary" },
                  on: { click: _vm.toggleExistingPage }
                },
                [_vm._v("\n\t\t\tCreate New\n\t\t")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showExistingPage,
                  expression: "showExistingPage"
                }
              ],
              attrs: { lg: "12" }
            },
            [
              _c(
                "div",
                { staticClass: "d-block text-left" },
                [
                  _c(
                    "b-form-group",
                    { attrs: { "label-for": "example-select" } },
                    [
                      _c(
                        "b-form-select",
                        {
                          staticClass: "mb-2",
                          attrs: { id: "example-select", plain: "" },
                          model: {
                            value: _vm.pageObjectData.type,
                            callback: function($$v) {
                              _vm.$set(_vm.pageObjectData, "type", $$v)
                            },
                            expression: "pageObjectData.type"
                          }
                        },
                        [
                          _c("b-form-select-option", { attrs: { value: "" } }, [
                            _vm._v(
                              "\n\t\t\t\t\t\t\tPlease select an option\n\t\t\t\t\t\t"
                            )
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.pageObject, function(theme, id) {
                            return _c(
                              "b-form-select-option",
                              { key: id, attrs: { value: theme } },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t" +
                                    _vm._s(theme) +
                                    "\n\t\t\t\t\t\t"
                                )
                              ]
                            )
                          })
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      staticClass: "mt-3 mb-2",
                      on: {
                        click: function($event) {
                          return _vm.createNew()
                        }
                      }
                    },
                    [_vm._v("\n\t\t\t\t\tCreate new\n\t\t\t\t")]
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { ref: "PageObjectTable" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }