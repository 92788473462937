import { Vue, Component, Ref } from 'vue-property-decorator';
import EditProductCategoriesTable from 'components/ProductCategoriesTable/EditProductCategoriesTable/';
import Template from './template.vue';

@Component({
	components: {
		EditProductCategoriesTable,
	},
})
export default class EditProductCategories extends Vue.extend(Template) {
	@Ref('editproductcategory')
	public readonly editproduct!: HTMLFormElement;

	private routeId = this.$route.params.id;

	private isLoading = false;

	private get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	protected updateProductCategoryProperties(): void {
		this.editproduct.editSingleProductCategory();
	}
}
