import {
	Vue, Component, Watch, Prop,
} from 'vue-property-decorator';
import { AgGridVue } from 'ag-grid-vue';
import * as DB from 'interfaces/database';
import { PickerResponse } from 'filestack-js';
import { httpClient } from 'utils/http';
import Template from './template.vue';
import LayoutTemplate from '../LayoutTemplate';

@Component({
	components: {
		AgGridVue,
		LayoutTemplate,
	},
})
export default class EditLayoutsTable extends Vue.extend(Template) {
	@Prop({
		type: String,
		required: true,
	})
	public readonly routeId!: string

	private singleLayout = {} as DB.LayoutModel;

	private loaded = false;

	private placeHolders = {
		icon: 'Choose a file',
	}

	private visible = false;

	private spinner = false;

	private index = 0;

	private rowHeight = 60;

	private isLoading = false;

	private get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	protected mounted(): void {
		this.getSingleCategory();
	}

	@Watch('loggedIn')
	private async getSingleCategory(): Promise<DB.LayoutModel | undefined> {
		if (this.loggedIn) {
			// set the loading state
			this.isLoading = true;
			try {
				const resp = await httpClient.get<DB.LayoutModel>(`/api/layout/${this.routeId}`);
				this.singleLayout = resp.data;
				// eslint-disable-next-line consistent-return
				return this.singleLayout;
			} catch (error: any) {
				this.$bvToast.toast(`${error.message}`,
					{
						solid: true,
						variant: 'danger',
					});
			} finally {
				this.isLoading = false;
			}
		}
		return undefined;
	}

	protected editPhotoframe(): Promise<void> | undefined {
		if (this.loggedIn) {
			this.isLoading = true;
			httpClient.put<DB.LayoutModel>(`/api/layout/${this.routeId}`,
				{ ...this.singleLayout }).then(() => {
				this.$bvToast.toast('Layout Edited successfully',
					{
						solid: true,
						variant: 'success',
					});
				return undefined;
			})
				.finally(() => {
					this.isLoading = false;
				})
				.catch((error) => {
					this.$bvToast.toast(`${error.message}`,
						{
							solid: true,
							variant: 'danger',
						});
				});
		}
		return undefined;
	}

	protected updateIcon(): void {
		const options = {
			fromSources: ['local_file_system'],
			onUploadDone: async (files: PickerResponse) => {
				this.singleLayout.icon = files.filesUploaded[0].url;
				this.placeHolders.icon = files.filesUploaded[0].filename;
			},
		};
		Vue.prototype.$client.picker(options).open();
	}

	protected routeLayoutTemplate(id: string): void {
		this.$emit('routeLayoutTemplate',
			`${id}`);
	}

	protected removeIcon(): void {
		this.singleLayout.icon = '';
	}
}
