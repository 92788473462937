import { Vue, Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import * as DB from 'interfaces/database';
import SalesTable from './SalesTable.vue';

const Regions = namespace('Regions');
@Component({
	components: {
		SalesTable,
	},
})
export default class SalesPrice extends Vue {
	private editing = false

	private isLoading = false

	@Prop()
	editedOffering!: DB.OfferingModel

	get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	@Regions.Getter
	private regionCurrencyForSalesGetter!: Array<DB.RegionCurrencyModel>;

	@Regions.Getter
	private getLoadingStatus!: boolean;
}
