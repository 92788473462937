import {
	Vue, Component, Watch, Prop,
} from 'vue-property-decorator';
import * as DB from 'interfaces/database';
import { httpClient } from 'utils/http';
import Template from './template.vue';

@Component({})
export default class EditCrossSellTable extends Vue.extend(Template) {
	@Prop({
		type: String,
		required: true,
	})
	private readonly routeId!: string;

	private singleCrossSell = {} as DB.CrossSellModel;

	private offerings: DB.OfferingModel[] = [];

	private theme: DB.ThemeModel[] = [];

	private loaded = false;

	private visible = false;

	private spinner = false;

	private index = 0;

	private isLoading = false;

	private pageOptions = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50];

	private get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	protected mounted(): void {
		this.getData();
	}

	@Watch('loggedIn')
	private async getData(): Promise<DB.CrossSellModel | undefined> {
		if (this.loggedIn) {
			const parameter = new URLSearchParams({
				limit: '0',
			});
			this.isLoading = true;
			try {
				const [crossData, offeringData, ThemeData] = await Promise.all([
					httpClient.get<DB.CrossSellModel>(`/api/crosssell/${this.routeId}`),
					httpClient.get<DB.OfferingModel[]>(`/api/offering?${parameter}`),
					httpClient.get<DB.ThemeModel[]>(`/api/theme?${parameter}`),
				]);
				this.singleCrossSell = crossData.data;
				this.offerings = offeringData.data;
				this.theme = ThemeData.data;
				// eslint-disable-next-line consistent-return
				return this.singleCrossSell;
			} catch (error: any) {
				this.$bvToast.toast(`${error.message}`,
					{
						solid: true,
						variant: 'danger',
					});
			} finally {
				this.isLoading = false;
			}
		}
		return undefined;
	}

	protected editCrossSell(): Promise<void> | undefined {
		if (this.loggedIn) {
			this.isLoading = true;
			httpClient.put<DB.CrossSellModel>(`/api/crosssell/${this.routeId}`,
				{ ...this.singleCrossSell }).then(() => {
				this.$bvToast.toast('Cross Sell Edited successfully',
					{
						solid: true,
						variant: 'success',
					});
				return undefined;
			})
				.finally(() => {
					this.isLoading = false;
				})
				.catch((error) => {
					this.$bvToast.toast(`${error.message}`,
						{
							solid: true,
							variant: 'danger',
						});
				});
		}
		return undefined;
	}
}
