import CountryFlag from 'vue-country-flag';
import * as DB from 'interfaces/database';
import { Vue, Component, Prop } from 'vue-property-decorator';
import Template from './template.vue';

@Component({
	components: {
		CountryFlag,
	},
})
export default class CountryFlagGlob extends Vue.extend(Template) {
	@Prop({
		type: Object,
		required: true,
	})
	public readonly data!: DB.CountryModel;
}
