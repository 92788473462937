import { Vue, Component, Ref } from 'vue-property-decorator';
import EditBulkDiscountTable from 'components/Discounts/BulkDiscount/EditBulkDiscountTable';
import Template from './template.vue';

@Component({
	components: {
		EditBulkDiscountTable,
	},
})
export default class EditBulkDiscount extends Vue.extend(Template) {
	@Ref('editbulkdiscount')
	public readonly editproduct!: HTMLFormElement;

	private bulkDiscountId = this.$route.params.id;

	protected updateBulkDiscountProperties(): void {
		this.editproduct.editSingleBulkDiscount();
	}
}
