var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ml-5 mt-4", staticStyle: { width: "95%", height: "100%" } },
    [
      _c("loading-overlay", {
        attrs: { active: _vm.isLoading, "can-cancel": true }
      }),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "12", lg: "12", sm: "12" } },
            [
              _c(
                "base-block",
                {
                  attrs: {
                    title: "Template position properties",
                    "header-bg": ""
                  }
                },
                [
                  _vm.spinner
                    ? _c("div", { staticClass: "text-center" }, [
                        _c(
                          "div",
                          {
                            staticClass: "spinner-grow text-primary m-5",
                            attrs: { role: "status" }
                          },
                          [
                            _c("span", { staticClass: "sr-only" }, [
                              _vm._v("Loading...")
                            ])
                          ]
                        )
                      ])
                    : _c(
                        "div",
                        { staticClass: "d-block" },
                        [
                          _vm.isPhotoRoute
                            ? [
                                _c(
                                  "b-form-group",
                                  {
                                    staticClass: "form-group",
                                    attrs: {
                                      "label-for": "block-form1-name",
                                      "label-cols-lg": "4"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "label",
                                          fn: function() {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "d-flex flex-row"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\tX-axis\n\t\t\t\t\t\t\t\t\t"
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: { id: "x_axis" }
                                                  })
                                                ]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      1785315885
                                    )
                                  },
                                  [
                                    _vm._v(" "),
                                    _c(
                                      "b-tooltip",
                                      {
                                        attrs: {
                                          target: "x_axis",
                                          triggers: "hover",
                                          placement: "bottom"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\tThe x-axis coordinate of the position on the page\n\t\t\t\t\t\t\t"
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-input-group",
                                      {
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "append",
                                              fn: function() {
                                                return [
                                                  _c(
                                                    "b-input-group-text",
                                                    {
                                                      staticClass:
                                                        "input-group-text-alt",
                                                      staticStyle: {
                                                        cursor: "pointer"
                                                      }
                                                    },
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          id: "checkbox-x_axis",
                                                          name: "checkbox-1",
                                                          checked:
                                                            _vm
                                                              .singleTemplatePosition
                                                              .x_axis == null
                                                              ? true
                                                              : false,
                                                          "unchecked-value":
                                                            "not_accepted"
                                                        },
                                                        on: {
                                                          change: () =>
                                                            (_vm.singleTemplatePosition.x_axis = null)
                                                        }
                                                      }),
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t\t"
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ],
                                          null,
                                          false,
                                          2131507578
                                        )
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: { type: "text" },
                                          model: {
                                            value:
                                              _vm.singleTemplatePosition.x_axis,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.singleTemplatePosition,
                                                "x_axis",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "singleTemplatePosition.x_axis"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-form-group",
                                  {
                                    staticClass: "form-group",
                                    attrs: {
                                      "label-for": "block-form1-name",
                                      "label-cols-lg": "4"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "label",
                                          fn: function() {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "d-flex flex-row"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\tY-axis\n\t\t\t\t\t\t\t\t\t"
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: { id: "y_axis" }
                                                  })
                                                ]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      105159405
                                    )
                                  },
                                  [
                                    _vm._v(" "),
                                    _c(
                                      "b-tooltip",
                                      {
                                        attrs: {
                                          target: "y_axis",
                                          triggers: "hover",
                                          placement: "bottom"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\tThe y-coordinate of the position on the page\n\t\t\t\t\t\t\t"
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-input-group",
                                      {
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "append",
                                              fn: function() {
                                                return [
                                                  _c(
                                                    "b-input-group-text",
                                                    {
                                                      staticClass:
                                                        "input-group-text-alt",
                                                      staticStyle: {
                                                        cursor: "pointer"
                                                      }
                                                    },
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          id: "checkbox-y_axis",
                                                          name: "checkbox-1",
                                                          checked:
                                                            _vm
                                                              .singleTemplatePosition
                                                              .y_axis == null
                                                              ? true
                                                              : false,
                                                          "unchecked-value":
                                                            "not_accepted"
                                                        },
                                                        on: {
                                                          change: () =>
                                                            (_vm.singleTemplatePosition.y_axis = null)
                                                        }
                                                      }),
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t\t"
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ],
                                          null,
                                          false,
                                          720588475
                                        )
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: { type: "text" },
                                          model: {
                                            value:
                                              _vm.singleTemplatePosition.y_axis,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.singleTemplatePosition,
                                                "y_axis",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "singleTemplatePosition.y_axis"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-form-group",
                                  {
                                    staticClass: "form-group",
                                    attrs: {
                                      "label-for": "block-form1-name",
                                      "label-cols-lg": "4"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "label",
                                          fn: function() {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "d-flex flex-row"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\tZ-axis\n\t\t\t\t\t\t\t\t\t"
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: { id: "z_axis" }
                                                  })
                                                ]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      1148856109
                                    )
                                  },
                                  [
                                    _vm._v(" "),
                                    _c(
                                      "b-tooltip",
                                      {
                                        attrs: {
                                          target: "z_axis",
                                          triggers: "hover",
                                          placement: "bottom"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\tThe z-coordinate of the position on the page\n\t\t\t\t\t\t\t"
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-input-group",
                                      {
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "append",
                                              fn: function() {
                                                return [
                                                  _c(
                                                    "b-input-group-text",
                                                    {
                                                      staticClass:
                                                        "input-group-text-alt",
                                                      staticStyle: {
                                                        cursor: "pointer"
                                                      }
                                                    },
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          id: "checkbox-z_axis",
                                                          name: "checkbox-1",
                                                          checked:
                                                            _vm
                                                              .singleTemplatePosition
                                                              .z_axis == null
                                                              ? true
                                                              : false,
                                                          "unchecked-value":
                                                            "not_accepted"
                                                        },
                                                        on: {
                                                          change: () =>
                                                            (_vm.singleTemplatePosition.z_axis = null)
                                                        }
                                                      }),
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t\t"
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ],
                                          null,
                                          false,
                                          3210435704
                                        )
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: { type: "text" },
                                          model: {
                                            value:
                                              _vm.singleTemplatePosition.z_axis,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.singleTemplatePosition,
                                                "z_axis",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "singleTemplatePosition.z_axis"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-form-group",
                                  {
                                    staticClass: "form-group",
                                    attrs: {
                                      "label-for": "block-form1-name",
                                      "label-cols-lg": "4"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "label",
                                          fn: function() {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "d-flex flex-row"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\tWidth\n\t\t\t\t\t\t\t\t\t"
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: { id: "width" }
                                                  })
                                                ]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      940050591
                                    )
                                  },
                                  [
                                    _vm._v(" "),
                                    _c(
                                      "b-tooltip",
                                      {
                                        attrs: {
                                          target: "width",
                                          triggers: "hover",
                                          placement: "bottom"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\tThe width of the position in pixels\n\t\t\t\t\t\t\t"
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-input-group",
                                      {
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "append",
                                              fn: function() {
                                                return [
                                                  _c(
                                                    "b-input-group-text",
                                                    {
                                                      staticClass:
                                                        "input-group-text-alt",
                                                      staticStyle: {
                                                        cursor: "pointer"
                                                      }
                                                    },
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          id: "checkbox-width",
                                                          name: "checkbox-1",
                                                          checked:
                                                            _vm
                                                              .singleTemplatePosition
                                                              .width == null
                                                              ? true
                                                              : false,
                                                          "unchecked-value":
                                                            "not_accepted"
                                                        },
                                                        on: {
                                                          change: () =>
                                                            (_vm.singleTemplatePosition.width = null)
                                                        }
                                                      }),
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t\t"
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ],
                                          null,
                                          false,
                                          294016696
                                        )
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: { type: "text" },
                                          model: {
                                            value:
                                              _vm.singleTemplatePosition.width,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.singleTemplatePosition,
                                                "width",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "singleTemplatePosition.width"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-form-group",
                                  {
                                    staticClass: "form-group",
                                    attrs: {
                                      "label-for": "block-form1-name",
                                      "label-cols-lg": "4"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "label",
                                          fn: function() {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "d-flex flex-row"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\tHeight\n\t\t\t\t\t\t\t\t\t"
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: { id: "height" }
                                                  })
                                                ]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      2942915487
                                    )
                                  },
                                  [
                                    _vm._v(" "),
                                    _c(
                                      "b-tooltip",
                                      {
                                        attrs: {
                                          target: "height",
                                          triggers: "hover",
                                          placement: "bottom"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\tThe height of the position in pixels\n\t\t\t\t\t\t\t"
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-input-group",
                                      {
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "append",
                                              fn: function() {
                                                return [
                                                  _c(
                                                    "b-input-group-text",
                                                    {
                                                      staticClass:
                                                        "input-group-text-alt",
                                                      staticStyle: {
                                                        cursor: "pointer"
                                                      }
                                                    },
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          id: "checkbox-height",
                                                          name: "checkbox-1",
                                                          checked:
                                                            _vm
                                                              .singleTemplatePosition
                                                              .height == null
                                                              ? true
                                                              : false,
                                                          "unchecked-value":
                                                            "not_accepted"
                                                        },
                                                        on: {
                                                          change: () =>
                                                            (_vm.singleTemplatePosition.height = null)
                                                        }
                                                      }),
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t\t"
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ],
                                          null,
                                          false,
                                          3824043969
                                        )
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: { type: "text" },
                                          model: {
                                            value:
                                              _vm.singleTemplatePosition.height,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.singleTemplatePosition,
                                                "height",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "singleTemplatePosition.height"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-form-group",
                                  {
                                    staticClass: "form-group",
                                    attrs: {
                                      "label-for": "block-form1-name",
                                      "label-cols-lg": "4"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "label",
                                          fn: function() {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "d-flex flex-row"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\tRotation angle\n\t\t\t\t\t\t\t\t\t"
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: {
                                                      id: "rotation_angle"
                                                    }
                                                  })
                                                ]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      3453827296
                                    )
                                  },
                                  [
                                    _vm._v(" "),
                                    _c(
                                      "b-tooltip",
                                      {
                                        attrs: {
                                          target: "rotation_angle",
                                          triggers: "hover",
                                          placement: "bottom"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\tThe angle of the position in degrees\n\t\t\t\t\t\t\t"
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-input-group",
                                      {
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "append",
                                              fn: function() {
                                                return [
                                                  _c(
                                                    "b-input-group-text",
                                                    {
                                                      staticClass:
                                                        "input-group-text-alt",
                                                      staticStyle: {
                                                        cursor: "pointer"
                                                      }
                                                    },
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          id: "checkbox-angle",
                                                          name: "checkbox-1",
                                                          checked:
                                                            _vm
                                                              .singleTemplatePosition
                                                              .angle == null
                                                              ? true
                                                              : false,
                                                          "unchecked-value":
                                                            "not_accepted"
                                                        },
                                                        on: {
                                                          change: () =>
                                                            (_vm.singleTemplatePosition.angle = null)
                                                        }
                                                      }),
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t\t"
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ],
                                          null,
                                          false,
                                          910026687
                                        )
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: { type: "text" },
                                          model: {
                                            value:
                                              _vm.singleTemplatePosition.angle,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.singleTemplatePosition,
                                                "angle",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "singleTemplatePosition.angle"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-form-group",
                                  {
                                    staticClass: "form-group",
                                    attrs: {
                                      label: "Border Image",
                                      "label-for": "block-form1-name",
                                      "label-cols-lg": "4"
                                    }
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c(
                                          "b-form-select",
                                          {
                                            attrs: {
                                              id: "block-form-layoutid"
                                            },
                                            model: {
                                              value:
                                                _vm.singleTemplatePosition
                                                  .borderimageid,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.singleTemplatePosition,
                                                  "borderimageid",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "singleTemplatePosition.borderimageid"
                                            }
                                          },
                                          [
                                            _c(
                                              "b-form-select-option",
                                              { attrs: { value: null } },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\tPlease select an option\n\t\t\t\t\t\t\t\t\t"
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm._l(_vm.borderImage, function(
                                              bd,
                                              id
                                            ) {
                                              return _c(
                                                "b-form-select-option",
                                                {
                                                  key: id,
                                                  attrs: { value: bd.id }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(bd.name) +
                                                      "\n\t\t\t\t\t\t\t\t\t"
                                                  )
                                                ]
                                              )
                                            })
                                          ],
                                          2
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-form-group",
                                  {
                                    staticClass: "form-group",
                                    attrs: {
                                      "label-for": "block-form1-name",
                                      "label-cols-lg": "4"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "label",
                                          fn: function() {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "d-flex flex-row"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\tBorder width\n\t\t\t\t\t\t\t\t\t"
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: {
                                                      id: "border_width"
                                                    }
                                                  })
                                                ]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      3859209632
                                    )
                                  },
                                  [
                                    _vm._v(" "),
                                    _c(
                                      "b-tooltip",
                                      {
                                        attrs: {
                                          target: "border_width",
                                          triggers: "hover",
                                          placement: "bottom"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\tThe width of the border around the object in pixels\n\t\t\t\t\t\t\t"
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-input-group",
                                      {
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "append",
                                              fn: function() {
                                                return [
                                                  _c(
                                                    "b-input-group-text",
                                                    {
                                                      staticClass:
                                                        "input-group-text-alt",
                                                      staticStyle: {
                                                        cursor: "pointer"
                                                      }
                                                    },
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          id:
                                                            "checkbox-borderwidth",
                                                          name: "checkbox-1",
                                                          checked:
                                                            _vm
                                                              .singleTemplatePosition
                                                              .borderwidth ==
                                                            null
                                                              ? true
                                                              : false,
                                                          "unchecked-value":
                                                            "not_accepted"
                                                        },
                                                        on: {
                                                          change: () =>
                                                            (_vm.singleTemplatePosition.borderwidth = null)
                                                        }
                                                      }),
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t\t"
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ],
                                          null,
                                          false,
                                          624692052
                                        )
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: { type: "text" },
                                          model: {
                                            value:
                                              _vm.singleTemplatePosition
                                                .borderwidth,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.singleTemplatePosition,
                                                "borderwidth",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "singleTemplatePosition.borderwidth"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-form-group",
                                  {
                                    staticClass: "form-group",
                                    attrs: {
                                      "label-for": "block-form1-name",
                                      "label-cols-lg": "4"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "label",
                                          fn: function() {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "d-flex flex-row"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\tBorder Color\n\t\t\t\t\t\t\t\t\t"
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: {
                                                      id: "border_color"
                                                    }
                                                  })
                                                ]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      1184807168
                                    )
                                  },
                                  [
                                    _vm._v(" "),
                                    _c(
                                      "b-tooltip",
                                      {
                                        attrs: {
                                          target: "border_color",
                                          triggers: "hover",
                                          placement: "bottom"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\tThe hex color of the border around the object\n\t\t\t\t\t\t\t"
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-input-group",
                                      {
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "append",
                                              fn: function() {
                                                return [
                                                  _c(
                                                    "b-input-group-text",
                                                    {
                                                      staticClass:
                                                        "input-group-text-alt",
                                                      staticStyle: {
                                                        cursor: "pointer"
                                                      }
                                                    },
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          id:
                                                            "checkbox-bordercolor",
                                                          name: "checkbox-1",
                                                          checked:
                                                            _vm
                                                              .singleTemplatePosition
                                                              .bordercolor ==
                                                            null
                                                              ? true
                                                              : false,
                                                          "unchecked-value":
                                                            "not_accepted"
                                                        },
                                                        on: {
                                                          change: () =>
                                                            (_vm.singleTemplatePosition.bordercolor = null)
                                                        }
                                                      }),
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t\t"
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ],
                                          null,
                                          false,
                                          404169487
                                        )
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: { type: "text" },
                                          model: {
                                            value:
                                              _vm.singleTemplatePosition
                                                .bordercolor,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.singleTemplatePosition,
                                                "bordercolor",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "singleTemplatePosition.bordercolor"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-form-group",
                                  {
                                    staticClass: "form-group",
                                    attrs: {
                                      "label-for": "block-form1-name",
                                      "label-cols-lg": "4"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "label",
                                          fn: function() {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "d-flex flex-row"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\tOverlap Top\n\t\t\t\t\t\t\t\t\t"
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: { id: "overlap_top" }
                                                  })
                                                ]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      4179939264
                                    )
                                  },
                                  [
                                    _vm._v(" "),
                                    _c(
                                      "b-tooltip",
                                      {
                                        attrs: {
                                          target: "overlap_top",
                                          triggers: "hover",
                                          placement: "bottom"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\tFor how many the top of the position overlap with other objects on the page\n\t\t\t\t\t\t\t"
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-input-group",
                                      {
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "append",
                                              fn: function() {
                                                return [
                                                  _c(
                                                    "b-input-group-text",
                                                    {
                                                      staticClass:
                                                        "input-group-text-alt",
                                                      staticStyle: {
                                                        cursor: "pointer"
                                                      }
                                                    },
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          id:
                                                            "checkbox-bordercolor",
                                                          name: "checkbox-1",
                                                          checked:
                                                            _vm
                                                              .singleTemplatePosition
                                                              .bordercolor ==
                                                            null
                                                              ? true
                                                              : false,
                                                          "unchecked-value":
                                                            "not_accepted"
                                                        },
                                                        on: {
                                                          change: () =>
                                                            (_vm.singleTemplatePosition.bordercolor = null)
                                                        }
                                                      }),
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t\t"
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ],
                                          null,
                                          false,
                                          404169487
                                        )
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: { type: "text" },
                                          model: {
                                            value:
                                              _vm.singleTemplatePosition
                                                .bordercolor,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.singleTemplatePosition,
                                                "bordercolor",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "singleTemplatePosition.bordercolor"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      "label-class": "font-w600",
                                      "label-cols-lg": "4"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "label",
                                          fn: function() {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "d-flex flex-row"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\tAutomatic rotation\n\t\t\t\t\t\t\t\t\t"
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: {
                                                      id: "automatic_rotation"
                                                    }
                                                  })
                                                ]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      3187622272
                                    )
                                  },
                                  [
                                    _vm._v(" "),
                                    _c(
                                      "b-tooltip",
                                      {
                                        attrs: {
                                          target: "automatic_rotation",
                                          triggers: "hover",
                                          placement: "bottom"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\tShould portrait/landscape photos automatically be rotated to best fit the dimensions of the position\n\t\t\t\t\t\t\t"
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("b-form-checkbox", {
                                      attrs: {
                                        switch: "",
                                        size: "lg",
                                        checked:
                                          _vm.singleTemplatePosition
                                            .autorotate === 1
                                            ? true
                                            : false
                                      },
                                      on: {
                                        change: function($event) {
                                          _vm.singleTemplatePosition.autorotate = $event
                                        }
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-form-group",
                                  {
                                    staticClass: "form-group",
                                    attrs: {
                                      "label-for": "block-form1-name",
                                      "label-cols-lg": "4"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "label",
                                          fn: function() {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "d-flex flex-row"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\tOverlap top\n\t\t\t\t\t\t\t\t\t"
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: { id: "overlap_top" }
                                                  })
                                                ]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      3223635616
                                    )
                                  },
                                  [
                                    _vm._v(" "),
                                    _c(
                                      "b-tooltip",
                                      {
                                        attrs: {
                                          target: "overlap_top",
                                          triggers: "hover",
                                          placement: "bottom"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\tfor how many pixels may the top of the position overlap with other objects on the page?\n\t\t\t\t\t\t\t"
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-input-group",
                                      {
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "append",
                                              fn: function() {
                                                return [
                                                  _c(
                                                    "b-input-group-text",
                                                    {
                                                      staticClass:
                                                        "input-group-text-alt",
                                                      staticStyle: {
                                                        cursor: "pointer"
                                                      }
                                                    },
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          id:
                                                            "checkbox-overlap_top",
                                                          name: "checkbox-1",
                                                          checked:
                                                            _vm
                                                              .singleTemplatePosition
                                                              .overlap_top ==
                                                            null
                                                              ? true
                                                              : false,
                                                          "unchecked-value":
                                                            "not_accepted"
                                                        },
                                                        on: {
                                                          change: () =>
                                                            (_vm.singleTemplatePosition.overlap_top = null)
                                                        }
                                                      }),
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t\t"
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ],
                                          null,
                                          false,
                                          2597643577
                                        )
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: { type: "text" },
                                          model: {
                                            value:
                                              _vm.singleTemplatePosition
                                                .overlap_top,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.singleTemplatePosition,
                                                "overlap_top",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "singleTemplatePosition.overlap_top"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-form-group",
                                  {
                                    staticClass: "form-group",
                                    attrs: {
                                      "label-for": "block-form1-name",
                                      "label-cols-lg": "4"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "label",
                                          fn: function() {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "d-flex flex-row"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\tOverlap right\n\t\t\t\t\t\t\t\t\t"
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: {
                                                      id: "overlap_right"
                                                    }
                                                  })
                                                ]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      2568857600
                                    )
                                  },
                                  [
                                    _vm._v(" "),
                                    _c(
                                      "b-tooltip",
                                      {
                                        attrs: {
                                          target: "overlap_right",
                                          triggers: "hover",
                                          placement: "bottom"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\tfor how many pixels may the right of the position overlap with other objects on the page?\n\t\t\t\t\t\t\t"
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-input-group",
                                      {
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "append",
                                              fn: function() {
                                                return [
                                                  _c(
                                                    "b-input-group-text",
                                                    {
                                                      staticClass:
                                                        "input-group-text-alt",
                                                      staticStyle: {
                                                        cursor: "pointer"
                                                      }
                                                    },
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          id:
                                                            "checkbox-overlap_right",
                                                          name: "checkbox-1",
                                                          checked:
                                                            _vm
                                                              .singleTemplatePosition
                                                              .overlap_right ==
                                                            null
                                                              ? true
                                                              : false,
                                                          "unchecked-value":
                                                            "not_accepted"
                                                        },
                                                        on: {
                                                          change: () =>
                                                            (_vm.singleTemplatePosition.overlap_right = null)
                                                        }
                                                      }),
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t\t"
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ],
                                          null,
                                          false,
                                          2495666386
                                        )
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: { type: "text" },
                                          model: {
                                            value:
                                              _vm.singleTemplatePosition
                                                .overlap_right,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.singleTemplatePosition,
                                                "overlap_right",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "singleTemplatePosition.overlap_right"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-form-group",
                                  {
                                    staticClass: "form-group",
                                    attrs: {
                                      "label-for": "block-form1-name",
                                      "label-cols-lg": "4"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "label",
                                          fn: function() {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "d-flex flex-row"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\tOverlap bottom\n\t\t\t\t\t\t\t\t\t"
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: {
                                                      id: "overlap_bottom"
                                                    }
                                                  })
                                                ]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      306068352
                                    )
                                  },
                                  [
                                    _vm._v(" "),
                                    _c(
                                      "b-tooltip",
                                      {
                                        attrs: {
                                          target: "overlap_bottom",
                                          triggers: "hover",
                                          placement: "bottom"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\tfor how many pixels may the bottom of the position overlap with other objects on the page?\n\t\t\t\t\t\t\t"
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-input-group",
                                      {
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "append",
                                              fn: function() {
                                                return [
                                                  _c(
                                                    "b-input-group-text",
                                                    {
                                                      staticClass:
                                                        "input-group-text-alt",
                                                      staticStyle: {
                                                        cursor: "pointer"
                                                      }
                                                    },
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          id:
                                                            "checkbox-overlap_bottom",
                                                          name: "checkbox-1",
                                                          checked:
                                                            _vm
                                                              .singleTemplatePosition
                                                              .overlap_bottom ==
                                                            null
                                                              ? true
                                                              : false,
                                                          "unchecked-value":
                                                            "not_accepted"
                                                        },
                                                        on: {
                                                          change: () =>
                                                            (_vm.singleTemplatePosition.overlap_bottom = null)
                                                        }
                                                      }),
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t\t"
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ],
                                          null,
                                          false,
                                          668136253
                                        )
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: { type: "text" },
                                          model: {
                                            value:
                                              _vm.singleTemplatePosition
                                                .overlap_bottom,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.singleTemplatePosition,
                                                "overlap_bottom",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "singleTemplatePosition.overlap_bottom"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-form-group",
                                  {
                                    staticClass: "form-group",
                                    attrs: {
                                      "label-for": "block-form1-name",
                                      "label-cols-lg": "4"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "label",
                                          fn: function() {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "d-flex flex-row"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\tOverlap left\n\t\t\t\t\t\t\t\t\t"
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: {
                                                      id: "overlap_left"
                                                    }
                                                  })
                                                ]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      4224774592
                                    )
                                  },
                                  [
                                    _vm._v(" "),
                                    _c(
                                      "b-tooltip",
                                      {
                                        attrs: {
                                          target: "overlap_left",
                                          triggers: "hover",
                                          placement: "bottom"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\tfor how many pixels may the left of the position overlap with other objects on the page?\n\t\t\t\t\t\t\t"
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-input-group",
                                      {
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "append",
                                              fn: function() {
                                                return [
                                                  _c(
                                                    "b-input-group-text",
                                                    {
                                                      staticClass:
                                                        "input-group-text-alt",
                                                      staticStyle: {
                                                        cursor: "pointer"
                                                      }
                                                    },
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          id:
                                                            "checkbox-overlap_left",
                                                          name: "checkbox-1",
                                                          checked:
                                                            _vm
                                                              .singleTemplatePosition
                                                              .overlap_left ==
                                                            null
                                                              ? true
                                                              : false,
                                                          "unchecked-value":
                                                            "not_accepted"
                                                        },
                                                        on: {
                                                          change: () =>
                                                            (_vm.singleTemplatePosition.overlap_left = null)
                                                        }
                                                      }),
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t\t"
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ],
                                          null,
                                          false,
                                          1214836617
                                        )
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: { type: "text" },
                                          model: {
                                            value:
                                              _vm.singleTemplatePosition
                                                .overlap_left,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.singleTemplatePosition,
                                                "overlap_left",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "singleTemplatePosition.overlap_left"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-form-group",
                                  {
                                    staticClass: "form-group",
                                    attrs: {
                                      "label-for": "block-form1-name",
                                      "label-cols-lg": "4"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "label",
                                          fn: function() {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "d-flex flex-row"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\tMatrix\n\t\t\t\t\t\t\t\t\t"
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: { id: "matrix" }
                                                  })
                                                ]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      16442655
                                    )
                                  },
                                  [
                                    _vm._v(" "),
                                    _c(
                                      "b-tooltip",
                                      {
                                        attrs: {
                                          target: "matrix",
                                          triggers: "hover",
                                          placement: "bottom"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\tcreate a matrix with x photos? Or use value 1 to create a matrix with all uploaded photos\n\t\t\t\t\t\t\t"
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          attrs: { type: "text" },
                                          model: {
                                            value:
                                              _vm.singleTemplatePosition.matrix,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.singleTemplatePosition,
                                                "matrix",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "singleTemplatePosition.matrix"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      "label-class": "font-w600",
                                      "label-cols-lg": "4"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "label",
                                          fn: function() {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "d-flex flex-row"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\tTransformable\n\t\t\t\t\t\t\t\t\t"
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: {
                                                      id: "transformable"
                                                    }
                                                  })
                                                ]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      2811143199
                                    )
                                  },
                                  [
                                    _vm._v(" "),
                                    _c(
                                      "b-tooltip",
                                      {
                                        attrs: {
                                          target: "transformable",
                                          triggers: "hover",
                                          placement: "bottom"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\tIs the object that fills this position draggable, resizable and rotatable by the user?\n\t\t\t\t\t\t\t"
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("b-form-checkbox", {
                                      attrs: {
                                        switch: "",
                                        size: "lg",
                                        checked:
                                          _vm.singleTemplatePosition
                                            .transformable === 1
                                            ? true
                                            : false
                                      },
                                      on: {
                                        change: function($event) {
                                          _vm.singleTemplatePosition.transformable = $event
                                        }
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      "label-class": "font-w600",
                                      "label-cols-lg": "4"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "label",
                                          fn: function() {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "d-flex flex-row"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\tFlag\n\t\t\t\t\t\t\t\t\t"
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: { id: "flag" }
                                                  })
                                                ]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      488522783
                                    )
                                  },
                                  [
                                    _vm._v(" "),
                                    _c(
                                      "b-tooltip",
                                      {
                                        attrs: {
                                          target: "flag",
                                          triggers: "hover",
                                          placement: "bottom"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\tWhen photo fills this position, flag it to avoid photo being used in other positions when auto filling?\n\t\t\t\t\t\t\t"
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("b-form-checkbox", {
                                      attrs: {
                                        switch: "",
                                        size: "lg",
                                        checked:
                                          _vm.singleTemplatePosition.flag === 1
                                            ? true
                                            : false
                                      },
                                      on: {
                                        change: function($event) {
                                          _vm.singleTemplatePosition.flag = $event
                                        }
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-form-group",
                                  {
                                    staticClass: "form-group",
                                    attrs: {
                                      label: "Mask",
                                      "label-for": "block-form1-name",
                                      "label-cols-lg": "4"
                                    }
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c(
                                          "b-form-select",
                                          {
                                            attrs: {
                                              id: "block-form-layoutid"
                                            },
                                            model: {
                                              value:
                                                _vm.singleTemplatePosition.mask,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.singleTemplatePosition,
                                                  "mask",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "singleTemplatePosition.mask"
                                            }
                                          },
                                          [
                                            _c(
                                              "b-form-select-option",
                                              { attrs: { value: null } },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\tPlease select an option\n\t\t\t\t\t\t\t\t\t"
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm._l(_vm.mask, function(ms, id) {
                                              return _c(
                                                "b-form-select-option",
                                                {
                                                  key: id,
                                                  attrs: { value: ms }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(ms) +
                                                      "\n\t\t\t\t\t\t\t\t\t"
                                                  )
                                                ]
                                              )
                                            })
                                          ],
                                          2
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            : [
                                _c(
                                  "b-form-group",
                                  {
                                    staticClass: "form-group",
                                    attrs: {
                                      "label-for": "block-form1-name",
                                      "label-cols-lg": "4"
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "label",
                                        fn: function() {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "d-flex flex-row"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "mr-2" },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\tX-axis\n\t\t\t\t\t\t\t\t\t"
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("div", {
                                                  staticClass: "tip",
                                                  attrs: { id: "x_axis" }
                                                })
                                              ]
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ])
                                  },
                                  [
                                    _vm._v(" "),
                                    _c(
                                      "b-tooltip",
                                      {
                                        attrs: {
                                          target: "x_axis",
                                          triggers: "hover",
                                          placement: "bottom"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\tThe x-axis coordinate of the position on the page\n\t\t\t\t\t\t\t"
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-input-group",
                                      {
                                        scopedSlots: _vm._u([
                                          {
                                            key: "append",
                                            fn: function() {
                                              return [
                                                _c(
                                                  "b-input-group-text",
                                                  {
                                                    staticClass:
                                                      "input-group-text-alt",
                                                    staticStyle: {
                                                      cursor: "pointer"
                                                    }
                                                  },
                                                  [
                                                    _c("b-form-checkbox", {
                                                      attrs: {
                                                        id: "checkbox-x_axis",
                                                        name: "checkbox-1",
                                                        checked:
                                                          _vm
                                                            .singleTemplatePosition
                                                            .x_axis == null
                                                            ? true
                                                            : false,
                                                        "unchecked-value":
                                                          "not_accepted"
                                                      },
                                                      on: {
                                                        change: () =>
                                                          (_vm.singleTemplatePosition.x_axis = null)
                                                      }
                                                    }),
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t\t"
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ])
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: { type: "text" },
                                          model: {
                                            value:
                                              _vm.singleTemplatePosition.x_axis,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.singleTemplatePosition,
                                                "x_axis",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "singleTemplatePosition.x_axis"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-form-group",
                                  {
                                    staticClass: "form-group",
                                    attrs: {
                                      "label-for": "block-form1-name",
                                      "label-cols-lg": "4"
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "label",
                                        fn: function() {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "d-flex flex-row"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "mr-2" },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\tY-axis\n\t\t\t\t\t\t\t\t\t"
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("div", {
                                                  staticClass: "tip",
                                                  attrs: { id: "y_axis" }
                                                })
                                              ]
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ])
                                  },
                                  [
                                    _vm._v(" "),
                                    _c(
                                      "b-tooltip",
                                      {
                                        attrs: {
                                          target: "y_axis",
                                          triggers: "hover",
                                          placement: "bottom"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\tThe y-coordinate of the position on the page\n\t\t\t\t\t\t\t"
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-input-group",
                                      {
                                        scopedSlots: _vm._u([
                                          {
                                            key: "append",
                                            fn: function() {
                                              return [
                                                _c(
                                                  "b-input-group-text",
                                                  {
                                                    staticClass:
                                                      "input-group-text-alt",
                                                    staticStyle: {
                                                      cursor: "pointer"
                                                    }
                                                  },
                                                  [
                                                    _c("b-form-checkbox", {
                                                      attrs: {
                                                        id: "checkbox-y_axis",
                                                        name: "checkbox-1",
                                                        checked:
                                                          _vm
                                                            .singleTemplatePosition
                                                            .y_axis == null
                                                            ? true
                                                            : false,
                                                        "unchecked-value":
                                                          "not_accepted"
                                                      },
                                                      on: {
                                                        change: () =>
                                                          (_vm.singleTemplatePosition.y_axis = null)
                                                      }
                                                    }),
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t\t"
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ])
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: { type: "text" },
                                          model: {
                                            value:
                                              _vm.singleTemplatePosition.y_axis,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.singleTemplatePosition,
                                                "y_axis",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "singleTemplatePosition.y_axis"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-form-group",
                                  {
                                    staticClass: "form-group",
                                    attrs: {
                                      "label-for": "block-form1-name",
                                      "label-cols-lg": "4"
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "label",
                                        fn: function() {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "d-flex flex-row"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "mr-2" },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\tZ-axis\n\t\t\t\t\t\t\t\t\t"
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("div", {
                                                  staticClass: "tip",
                                                  attrs: { id: "z_axis" }
                                                })
                                              ]
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ])
                                  },
                                  [
                                    _vm._v(" "),
                                    _c(
                                      "b-tooltip",
                                      {
                                        attrs: {
                                          target: "z_axis",
                                          triggers: "hover",
                                          placement: "bottom"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\tThe z-coordinate of the position on the page\n\t\t\t\t\t\t\t"
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-input-group",
                                      {
                                        scopedSlots: _vm._u([
                                          {
                                            key: "append",
                                            fn: function() {
                                              return [
                                                _c(
                                                  "b-input-group-text",
                                                  {
                                                    staticClass:
                                                      "input-group-text-alt",
                                                    staticStyle: {
                                                      cursor: "pointer"
                                                    }
                                                  },
                                                  [
                                                    _c("b-form-checkbox", {
                                                      attrs: {
                                                        id: "checkbox-z_axis",
                                                        name: "checkbox-1",
                                                        checked:
                                                          _vm
                                                            .singleTemplatePosition
                                                            .z_axis == null
                                                            ? true
                                                            : false,
                                                        "unchecked-value":
                                                          "not_accepted"
                                                      },
                                                      on: {
                                                        change: () =>
                                                          (_vm.singleTemplatePosition.z_axis = null)
                                                      }
                                                    }),
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t\t"
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ])
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: { type: "text" },
                                          model: {
                                            value:
                                              _vm.singleTemplatePosition.z_axis,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.singleTemplatePosition,
                                                "z_axis",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "singleTemplatePosition.z_axis"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-form-group",
                                  {
                                    staticClass: "form-group",
                                    attrs: {
                                      "label-for": "block-form1-name",
                                      "label-cols-lg": "4"
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "label",
                                        fn: function() {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "d-flex flex-row"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "mr-2" },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\tWidth\n\t\t\t\t\t\t\t\t\t"
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("div", {
                                                  staticClass: "tip",
                                                  attrs: { id: "width" }
                                                })
                                              ]
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ])
                                  },
                                  [
                                    _vm._v(" "),
                                    _c(
                                      "b-tooltip",
                                      {
                                        attrs: {
                                          target: "width",
                                          triggers: "hover",
                                          placement: "bottom"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\tThe width of the position in pixels\n\t\t\t\t\t\t\t"
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-input-group",
                                      {
                                        scopedSlots: _vm._u([
                                          {
                                            key: "append",
                                            fn: function() {
                                              return [
                                                _c(
                                                  "b-input-group-text",
                                                  {
                                                    staticClass:
                                                      "input-group-text-alt",
                                                    staticStyle: {
                                                      cursor: "pointer"
                                                    }
                                                  },
                                                  [
                                                    _c("b-form-checkbox", {
                                                      attrs: {
                                                        id: "checkbox-width",
                                                        name: "checkbox-1",
                                                        checked:
                                                          _vm
                                                            .singleTemplatePosition
                                                            .width == null
                                                            ? true
                                                            : false,
                                                        "unchecked-value":
                                                          "not_accepted"
                                                      },
                                                      on: {
                                                        change: () =>
                                                          (_vm.singleTemplatePosition.width = null)
                                                      }
                                                    }),
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t\t"
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ])
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: { type: "text" },
                                          model: {
                                            value:
                                              _vm.singleTemplatePosition.width,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.singleTemplatePosition,
                                                "width",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "singleTemplatePosition.width"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-form-group",
                                  {
                                    staticClass: "form-group",
                                    attrs: {
                                      "label-for": "block-form1-name",
                                      "label-cols-lg": "4"
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "label",
                                        fn: function() {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "d-flex flex-row"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "mr-2" },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\tHeight\n\t\t\t\t\t\t\t\t\t"
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("div", {
                                                  staticClass: "tip",
                                                  attrs: { id: "height" }
                                                })
                                              ]
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ])
                                  },
                                  [
                                    _vm._v(" "),
                                    _c(
                                      "b-tooltip",
                                      {
                                        attrs: {
                                          target: "height",
                                          triggers: "hover",
                                          placement: "bottom"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\tThe height of the position in pixels\n\t\t\t\t\t\t\t"
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-input-group",
                                      {
                                        scopedSlots: _vm._u([
                                          {
                                            key: "append",
                                            fn: function() {
                                              return [
                                                _c(
                                                  "b-input-group-text",
                                                  {
                                                    staticClass:
                                                      "input-group-text-alt",
                                                    staticStyle: {
                                                      cursor: "pointer"
                                                    }
                                                  },
                                                  [
                                                    _c("b-form-checkbox", {
                                                      attrs: {
                                                        id: "checkbox-height",
                                                        name: "checkbox-1",
                                                        checked:
                                                          _vm
                                                            .singleTemplatePosition
                                                            .height == null
                                                            ? true
                                                            : false,
                                                        "unchecked-value":
                                                          "not_accepted"
                                                      },
                                                      on: {
                                                        change: () =>
                                                          (_vm.singleTemplatePosition.height = null)
                                                      }
                                                    }),
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t\t"
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ])
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: { type: "text" },
                                          model: {
                                            value:
                                              _vm.singleTemplatePosition.height,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.singleTemplatePosition,
                                                "height",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "singleTemplatePosition.height"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-form-group",
                                  {
                                    staticClass: "form-group",
                                    attrs: {
                                      "label-for": "block-form1-name",
                                      "label-cols-lg": "4"
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "label",
                                        fn: function() {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "d-flex flex-row"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "mr-2" },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\tRotation angle\n\t\t\t\t\t\t\t\t\t"
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("div", {
                                                  staticClass: "tip",
                                                  attrs: {
                                                    id: "rotation_angle"
                                                  }
                                                })
                                              ]
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ])
                                  },
                                  [
                                    _vm._v(" "),
                                    _c(
                                      "b-tooltip",
                                      {
                                        attrs: {
                                          target: "rotation_angle",
                                          triggers: "hover",
                                          placement: "bottom"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\tThe angle of the position in degrees\n\t\t\t\t\t\t\t"
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-input-group",
                                      {
                                        scopedSlots: _vm._u([
                                          {
                                            key: "append",
                                            fn: function() {
                                              return [
                                                _c(
                                                  "b-input-group-text",
                                                  {
                                                    staticClass:
                                                      "input-group-text-alt",
                                                    staticStyle: {
                                                      cursor: "pointer"
                                                    }
                                                  },
                                                  [
                                                    _c("b-form-checkbox", {
                                                      attrs: {
                                                        id: "checkbox-angle",
                                                        name: "checkbox-1",
                                                        checked:
                                                          _vm
                                                            .singleTemplatePosition
                                                            .angle == null
                                                            ? true
                                                            : false,
                                                        "unchecked-value":
                                                          "not_accepted"
                                                      },
                                                      on: {
                                                        change: () =>
                                                          (_vm.singleTemplatePosition.angle = null)
                                                      }
                                                    }),
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t\t"
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ])
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: { type: "text" },
                                          model: {
                                            value:
                                              _vm.singleTemplatePosition.angle,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.singleTemplatePosition,
                                                "angle",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "singleTemplatePosition.angle"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-form-group",
                                  {
                                    staticClass: "form-group",
                                    attrs: {
                                      "label-for": "block-form1-name",
                                      "label-cols-lg": "4"
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "label",
                                        fn: function() {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "d-flex flex-row"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "mr-2" },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\tBorder width\n\t\t\t\t\t\t\t\t\t"
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("div", {
                                                  staticClass: "tip",
                                                  attrs: { id: "border_width" }
                                                })
                                              ]
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ])
                                  },
                                  [
                                    _vm._v(" "),
                                    _c(
                                      "b-tooltip",
                                      {
                                        attrs: {
                                          target: "border_width",
                                          triggers: "hover",
                                          placement: "bottom"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\tThe width of the border around the object in pixels\n\t\t\t\t\t\t\t"
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-input-group",
                                      {
                                        scopedSlots: _vm._u([
                                          {
                                            key: "append",
                                            fn: function() {
                                              return [
                                                _c(
                                                  "b-input-group-text",
                                                  {
                                                    staticClass:
                                                      "input-group-text-alt",
                                                    staticStyle: {
                                                      cursor: "pointer"
                                                    }
                                                  },
                                                  [
                                                    _c("b-form-checkbox", {
                                                      attrs: {
                                                        id:
                                                          "checkbox-borderwidth",
                                                        name: "checkbox-1",
                                                        checked:
                                                          _vm
                                                            .singleTemplatePosition
                                                            .borderwidth == null
                                                            ? true
                                                            : false,
                                                        "unchecked-value":
                                                          "not_accepted"
                                                      },
                                                      on: {
                                                        change: () =>
                                                          (_vm.singleTemplatePosition.borderwidth = null)
                                                      }
                                                    }),
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t\t"
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ])
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: { type: "text" },
                                          model: {
                                            value:
                                              _vm.singleTemplatePosition
                                                .borderwidth,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.singleTemplatePosition,
                                                "borderwidth",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "singleTemplatePosition.borderwidth"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-form-group",
                                  {
                                    staticClass: "form-group",
                                    attrs: {
                                      "label-for": "block-form1-name",
                                      "label-cols-lg": "4"
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "label",
                                        fn: function() {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "d-flex flex-row"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "mr-2" },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\tBorder Color\n\t\t\t\t\t\t\t\t\t"
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("div", {
                                                  staticClass: "tip",
                                                  attrs: { id: "border_color" }
                                                })
                                              ]
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ])
                                  },
                                  [
                                    _vm._v(" "),
                                    _c(
                                      "b-tooltip",
                                      {
                                        attrs: {
                                          target: "border_color",
                                          triggers: "hover",
                                          placement: "bottom"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\tThe hex color of the border around the object\n\t\t\t\t\t\t\t"
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-input-group",
                                      {
                                        scopedSlots: _vm._u([
                                          {
                                            key: "append",
                                            fn: function() {
                                              return [
                                                _c(
                                                  "b-input-group-text",
                                                  {
                                                    staticClass:
                                                      "input-group-text-alt",
                                                    staticStyle: {
                                                      cursor: "pointer"
                                                    }
                                                  },
                                                  [
                                                    _c("b-form-checkbox", {
                                                      attrs: {
                                                        id:
                                                          "checkbox-bordercolor",
                                                        name: "checkbox-1",
                                                        checked:
                                                          _vm
                                                            .singleTemplatePosition
                                                            .bordercolor == null
                                                            ? true
                                                            : false,
                                                        "unchecked-value":
                                                          "not_accepted"
                                                      },
                                                      on: {
                                                        change: () =>
                                                          (_vm.singleTemplatePosition.bordercolor = null)
                                                      }
                                                    }),
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t\t"
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ])
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: { type: "text" },
                                          model: {
                                            value:
                                              _vm.singleTemplatePosition
                                                .bordercolor,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.singleTemplatePosition,
                                                "bordercolor",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "singleTemplatePosition.bordercolor"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-form-group",
                                  {
                                    staticClass: "form-group",
                                    attrs: {
                                      label: "Font",
                                      "label-for": "block-form1-name",
                                      "label-cols-lg": "4"
                                    }
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c(
                                          "b-form-select",
                                          {
                                            attrs: {
                                              id: "block-form-layoutid"
                                            },
                                            model: {
                                              value:
                                                _vm.singleTemplatePosition
                                                  .fontface,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.singleTemplatePosition,
                                                  "fontface",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "singleTemplatePosition.fontface"
                                            }
                                          },
                                          [
                                            _c(
                                              "b-form-select-option",
                                              { attrs: { value: null } },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\tPlease select an option\n\t\t\t\t\t\t\t\t\t"
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm._l(_vm.font, function(ft, id) {
                                              return _c(
                                                "b-form-select-option",
                                                {
                                                  key: id,
                                                  attrs: { value: ft.id }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(ft.id) +
                                                      "\n\t\t\t\t\t\t\t\t\t"
                                                  )
                                                ]
                                              )
                                            })
                                          ],
                                          2
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-form-group",
                                  {
                                    staticClass: "form-group",
                                    attrs: {
                                      "label-for": "block-form1-name",
                                      "label-cols-lg": "4"
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "label",
                                        fn: function() {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "d-flex flex-row"
                                              },
                                              [
                                                _vm._v("e\n\t\t\t\t\t\t\t\t\t"),
                                                _c(
                                                  "div",
                                                  { staticClass: "mr-2" },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\tFont size\n\t\t\t\t\t\t\t\t\t"
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("div", {
                                                  staticClass: "tip",
                                                  attrs: { id: "fontsize" }
                                                })
                                              ]
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ])
                                  },
                                  [
                                    _vm._v(" "),
                                    _c(
                                      "b-tooltip",
                                      {
                                        attrs: {
                                          target: "fontsize",
                                          triggers: "hover",
                                          placement: "bottom"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\tThe size of the font in points\n\t\t\t\t\t\t\t"
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-input-group",
                                      {
                                        scopedSlots: _vm._u([
                                          {
                                            key: "append",
                                            fn: function() {
                                              return [
                                                _c(
                                                  "b-input-group-text",
                                                  {
                                                    staticClass:
                                                      "input-group-text-alt",
                                                    staticStyle: {
                                                      cursor: "pointer"
                                                    }
                                                  },
                                                  [
                                                    _c("b-form-checkbox", {
                                                      attrs: {
                                                        id:
                                                          "checkbox-font-size",
                                                        name: "checkbox-1",
                                                        checked:
                                                          _vm
                                                            .singleTemplatePosition
                                                            .pointsize == null
                                                            ? true
                                                            : false,
                                                        "unchecked-value":
                                                          "not_accepted"
                                                      },
                                                      on: {
                                                        change: () =>
                                                          (_vm.singleTemplatePosition.pointsize = null)
                                                      }
                                                    }),
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t\t"
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ])
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: { type: "text" },
                                          model: {
                                            value:
                                              _vm.singleTemplatePosition
                                                .pointsize,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.singleTemplatePosition,
                                                "pointsize",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "singleTemplatePosition.pointsize"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      "label-class": "font-w600",
                                      "label-cols-lg": "4"
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "label",
                                        fn: function() {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "d-flex flex-row"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "mr-2" },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\tSnap\n\t\t\t\t\t\t\t\t\t"
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("div", {
                                                  staticClass: "tip",
                                                  attrs: { id: "snap" }
                                                })
                                              ]
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ])
                                  },
                                  [
                                    _vm._v(" "),
                                    _c(
                                      "b-tooltip",
                                      {
                                        attrs: {
                                          target: "snap",
                                          triggers: "hover",
                                          placement: "bottom"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\tThe hex color of the text\n\t\t\t\t\t\t\t"
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("b-form-checkbox", {
                                      attrs: {
                                        switch: "",
                                        size: "lg",
                                        checked:
                                          _vm.singleTemplatePosition.snap === 1
                                            ? true
                                            : false
                                      },
                                      on: {
                                        change: function($event) {
                                          _vm.singleTemplatePosition.snap = $event
                                        }
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-form-group",
                                  {
                                    staticClass: "form-group",
                                    attrs: {
                                      "label-for": "block-form1-name",
                                      "label-cols-lg": "4"
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "label",
                                        fn: function() {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "d-flex flex-row"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "mr-2" },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\tOverlap top\n\t\t\t\t\t\t\t\t\t"
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("div", {
                                                  staticClass: "tip",
                                                  attrs: { id: "overlap_top" }
                                                })
                                              ]
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ])
                                  },
                                  [
                                    _vm._v(" "),
                                    _c(
                                      "b-tooltip",
                                      {
                                        attrs: {
                                          target: "overlap_top",
                                          triggers: "hover",
                                          placement: "bottom"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\tfor how many pixels may the top of the position overlap with other objects on the page?\n\t\t\t\t\t\t\t"
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-input-group",
                                      {
                                        scopedSlots: _vm._u([
                                          {
                                            key: "append",
                                            fn: function() {
                                              return [
                                                _c(
                                                  "b-input-group-text",
                                                  {
                                                    staticClass:
                                                      "input-group-text-alt",
                                                    staticStyle: {
                                                      cursor: "pointer"
                                                    }
                                                  },
                                                  [
                                                    _c("b-form-checkbox", {
                                                      attrs: {
                                                        id:
                                                          "checkbox-overlap_top",
                                                        name: "checkbox-1",
                                                        checked:
                                                          _vm
                                                            .singleTemplatePosition
                                                            .overlap_top == null
                                                            ? true
                                                            : false,
                                                        "unchecked-value":
                                                          "not_accepted"
                                                      },
                                                      on: {
                                                        change: () =>
                                                          (_vm.singleTemplatePosition.overlap_top = null)
                                                      }
                                                    }),
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t\t"
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ])
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: { type: "text" },
                                          model: {
                                            value:
                                              _vm.singleTemplatePosition
                                                .overlap_top,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.singleTemplatePosition,
                                                "overlap_top",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "singleTemplatePosition.overlap_top"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-form-group",
                                  {
                                    staticClass: "form-group",
                                    attrs: {
                                      "label-for": "block-form1-name",
                                      "label-cols-lg": "4"
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "label",
                                        fn: function() {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "d-flex flex-row"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "mr-2" },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\tOverlap right\n\t\t\t\t\t\t\t\t\t"
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("div", {
                                                  staticClass: "tip",
                                                  attrs: { id: "overlap_right" }
                                                })
                                              ]
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ])
                                  },
                                  [
                                    _vm._v(" "),
                                    _c(
                                      "b-tooltip",
                                      {
                                        attrs: {
                                          target: "overlap_right",
                                          triggers: "hover",
                                          placement: "bottom"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\tfor how many pixels may the right of the position overlap with other objects on the page?\n\t\t\t\t\t\t\t"
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-input-group",
                                      {
                                        scopedSlots: _vm._u([
                                          {
                                            key: "append",
                                            fn: function() {
                                              return [
                                                _c(
                                                  "b-input-group-text",
                                                  {
                                                    staticClass:
                                                      "input-group-text-alt",
                                                    staticStyle: {
                                                      cursor: "pointer"
                                                    }
                                                  },
                                                  [
                                                    _c("b-form-checkbox", {
                                                      attrs: {
                                                        id:
                                                          "checkbox-overlap_right",
                                                        name: "checkbox-1",
                                                        checked:
                                                          _vm
                                                            .singleTemplatePosition
                                                            .overlap_right ==
                                                          null
                                                            ? true
                                                            : false,
                                                        "unchecked-value":
                                                          "not_accepted"
                                                      },
                                                      on: {
                                                        change: () =>
                                                          (_vm.singleTemplatePosition.overlap_right = null)
                                                      }
                                                    }),
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t\t"
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ])
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: { type: "text" },
                                          model: {
                                            value:
                                              _vm.singleTemplatePosition
                                                .overlap_right,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.singleTemplatePosition,
                                                "overlap_right",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "singleTemplatePosition.overlap_right"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-form-group",
                                  {
                                    staticClass: "form-group",
                                    attrs: {
                                      "label-for": "block-form1-name",
                                      "label-cols-lg": "4"
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "label",
                                        fn: function() {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "d-flex flex-row"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "mr-2" },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\tOverlap bottom\n\t\t\t\t\t\t\t\t\t"
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("div", {
                                                  staticClass: "tip",
                                                  attrs: {
                                                    id: "overlap_bottom"
                                                  }
                                                })
                                              ]
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ])
                                  },
                                  [
                                    _vm._v(" "),
                                    _c(
                                      "b-tooltip",
                                      {
                                        attrs: {
                                          target: "overlap_bottom",
                                          triggers: "hover",
                                          placement: "bottom"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\tfor how many pixels may the bottom of the position overlap with other objects on the page?\n\t\t\t\t\t\t\t"
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-input-group",
                                      {
                                        scopedSlots: _vm._u([
                                          {
                                            key: "append",
                                            fn: function() {
                                              return [
                                                _c(
                                                  "b-input-group-text",
                                                  {
                                                    staticClass:
                                                      "input-group-text-alt",
                                                    staticStyle: {
                                                      cursor: "pointer"
                                                    }
                                                  },
                                                  [
                                                    _c("b-form-checkbox", {
                                                      attrs: {
                                                        id:
                                                          "checkbox-overlap_bottom",
                                                        name: "checkbox-1",
                                                        checked:
                                                          _vm
                                                            .singleTemplatePosition
                                                            .overlap_bottom ==
                                                          null
                                                            ? true
                                                            : false,
                                                        "unchecked-value":
                                                          "not_accepted"
                                                      },
                                                      on: {
                                                        change: () =>
                                                          (_vm.singleTemplatePosition.overlap_bottom = null)
                                                      }
                                                    }),
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t\t"
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ])
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: { type: "text" },
                                          model: {
                                            value:
                                              _vm.singleTemplatePosition
                                                .overlap_bottom,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.singleTemplatePosition,
                                                "overlap_bottom",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "singleTemplatePosition.overlap_bottom"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-form-group",
                                  {
                                    staticClass: "form-group",
                                    attrs: {
                                      "label-for": "block-form1-name",
                                      "label-cols-lg": "4"
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "label",
                                        fn: function() {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "d-flex flex-row"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "mr-2" },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\tOverlap left\n\t\t\t\t\t\t\t\t\t"
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("div", {
                                                  staticClass: "tip",
                                                  attrs: { id: "overlap_left" }
                                                })
                                              ]
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ])
                                  },
                                  [
                                    _vm._v(" "),
                                    _c(
                                      "b-tooltip",
                                      {
                                        attrs: {
                                          target: "overlap_left",
                                          triggers: "hover",
                                          placement: "bottom"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\tfor how many pixels may the left of the position overlap with other objects on the page?\n\t\t\t\t\t\t\t"
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-input-group",
                                      {
                                        scopedSlots: _vm._u([
                                          {
                                            key: "append",
                                            fn: function() {
                                              return [
                                                _c(
                                                  "b-input-group-text",
                                                  {
                                                    staticClass:
                                                      "input-group-text-alt",
                                                    staticStyle: {
                                                      cursor: "pointer"
                                                    }
                                                  },
                                                  [
                                                    _c("b-form-checkbox", {
                                                      attrs: {
                                                        id:
                                                          "checkbox-overlap_left",
                                                        name: "checkbox-1",
                                                        checked:
                                                          _vm
                                                            .singleTemplatePosition
                                                            .overlap_left ==
                                                          null
                                                            ? true
                                                            : false,
                                                        "unchecked-value":
                                                          "not_accepted"
                                                      },
                                                      on: {
                                                        change: () =>
                                                          (_vm.singleTemplatePosition.overlap_left = null)
                                                      }
                                                    }),
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t\t"
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ])
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: { type: "text" },
                                          model: {
                                            value:
                                              _vm.singleTemplatePosition
                                                .overlap_left,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.singleTemplatePosition,
                                                "overlap_left",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "singleTemplatePosition.overlap_left"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-form-group",
                                  {
                                    staticClass: "form-group",
                                    attrs: {
                                      "label-for": "block-form1-name",
                                      "label-cols-lg": "4"
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "label",
                                        fn: function() {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "d-flex flex-row"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "mr-2" },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\tMatrix\n\t\t\t\t\t\t\t\t\t"
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("div", {
                                                  staticClass: "tip",
                                                  attrs: { id: "matrix" }
                                                })
                                              ]
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ])
                                  },
                                  [
                                    _vm._v(" "),
                                    _c(
                                      "b-tooltip",
                                      {
                                        attrs: {
                                          target: "matrix",
                                          triggers: "hover",
                                          placement: "bottom"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\tcreate a matrix with x photos? Or use value 1 to create a matrix with all uploaded photos\n\t\t\t\t\t\t\t"
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          attrs: { type: "text" },
                                          model: {
                                            value:
                                              _vm.singleTemplatePosition.matrix,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.singleTemplatePosition,
                                                "matrix",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "singleTemplatePosition.matrix"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      "label-class": "font-w600",
                                      "label-cols-lg": "4"
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "label",
                                        fn: function() {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "d-flex flex-row"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "mr-2" },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\tTransformable\n\t\t\t\t\t\t\t\t\t"
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("div", {
                                                  staticClass: "tip",
                                                  attrs: { id: "transformable" }
                                                })
                                              ]
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ])
                                  },
                                  [
                                    _vm._v(" "),
                                    _c(
                                      "b-tooltip",
                                      {
                                        attrs: {
                                          target: "transformable",
                                          triggers: "hover",
                                          placement: "bottom"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\tIs the object that fills this position draggable, resizable and rotatable by the user?\n\t\t\t\t\t\t\t"
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("b-form-checkbox", {
                                      attrs: {
                                        switch: "",
                                        size: "lg",
                                        checked:
                                          _vm.singleTemplatePosition
                                            .transformable === 1
                                            ? true
                                            : false
                                      },
                                      on: {
                                        change: function($event) {
                                          _vm.singleTemplatePosition.transformable = $event
                                        }
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      "label-class": "font-w600",
                                      "label-cols-lg": "4"
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "label",
                                        fn: function() {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "d-flex flex-row"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "mr-2" },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\tFlag\n\t\t\t\t\t\t\t\t\t"
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("div", {
                                                  staticClass: "tip",
                                                  attrs: { id: "flag" }
                                                })
                                              ]
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ])
                                  },
                                  [
                                    _vm._v(" "),
                                    _c(
                                      "b-tooltip",
                                      {
                                        attrs: {
                                          target: "flag",
                                          triggers: "hover",
                                          placement: "bottom"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\tWhen photo fills this position, flag it to avoid photo being used in other positions when auto filling?\n\t\t\t\t\t\t\t"
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("b-form-checkbox", {
                                      attrs: {
                                        switch: "",
                                        size: "lg",
                                        checked:
                                          _vm.singleTemplatePosition.flag === 1
                                            ? true
                                            : false
                                      },
                                      on: {
                                        change: function($event) {
                                          _vm.singleTemplatePosition.flag = $event
                                        }
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-form-group",
                                  {
                                    staticClass: "form-group",
                                    attrs: {
                                      label: "Mask",
                                      "label-for": "block-form1-name",
                                      "label-cols-lg": "4"
                                    }
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c(
                                          "b-form-select",
                                          {
                                            attrs: {
                                              id: "block-form-layoutid"
                                            },
                                            model: {
                                              value:
                                                _vm.singleTemplatePosition.mask,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.singleTemplatePosition,
                                                  "mask",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "singleTemplatePosition.mask"
                                            }
                                          },
                                          [
                                            _c(
                                              "b-form-select-option",
                                              { attrs: { value: null } },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\tPlease select an option\n\t\t\t\t\t\t\t\t\t"
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm._l(_vm.mask, function(ms, id) {
                                              return _c(
                                                "b-form-select-option",
                                                {
                                                  key: id,
                                                  attrs: { value: ms }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(ms) +
                                                      "\n\t\t\t\t\t\t\t\t\t"
                                                  )
                                                ]
                                              )
                                            })
                                          ],
                                          2
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                        ],
                        2
                      )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }