import * as DB from 'interfaces/database';
import { Vue, Component, Prop } from 'vue-property-decorator';
import Template from './template.vue';

@Component({})
export default class ColoredBox extends Vue.extend(Template) {
	@Prop({
		type: Object,
		required: true,
	})
	public readonly data!: DB.PageBackgroundModel;
}
