import { Vue, Component, Watch } from 'vue-property-decorator';
import * as DB from 'interfaces/database';
import { PickerFileMetadata, PickerOptions, PickerResponse } from 'filestack-js';
import { httpClient } from '../../utils/http';

@Component({})
export default class EditModel2dTable extends Vue {
	private singleModel2d = {} as DB.Model2DModel;

	private loaded = false;

	private visible = false;

	private spinner = false;

	private imageUrl = '';

	private placeHolders = {
		thumbnailPlaceholder: 'Choose a file',
	}

	private index = 0;

	private isLoading = false;

	private pageOptions = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50];

	get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	mounted(): void {
		this.getSingleModel2d();
	}

	@Watch('loggedIn')
	async getSingleModel2d(): Promise<DB.Model2DModel | undefined> {
		if (this.loggedIn) {
			// set the loading state
			this.isLoading = true;
			try {
				const resp = await httpClient.get<DB.Model2DModel>(`/api/model2d/${this.$route.params.id}`);
				this.singleModel2d = resp.data;
				this.imageUrl = resp.data.imageUrl;
				// eslint-disable-next-line consistent-return
				return this.singleModel2d;
			} catch (error: any) {
				this.$bvToast.toast(`${error.message}`,
					{
						solid: true,
						variant: 'danger',
					});
			} finally {
				this.isLoading = false;
			}
		}
		return undefined;
	}

	editModel2dImage(): Promise<void> | undefined {
		if (this.loggedIn) {
			this.isLoading = true;
			httpClient.put<DB.Model2DModel>(`/api/model2d/${this.$route.params.id}`,
				{ ...this.singleModel2d }).then(() => {
				this.$bvToast.toast('2D Model Edited successfully',
					{
						solid: true,
						variant: 'success',
					});
				return undefined;
			})
				.finally(() => {
					this.isLoading = false;
				})
				.catch((error) => {
					this.$bvToast.toast(`${error.message}`,
						{
							solid: true,
							variant: 'danger',
						});
				});
		}
		return undefined;
	}

	//  update image preview
	async updateImagePreview(): Promise<void> {
		const options: PickerOptions = {
			fromSources: ['local_file_system'],
			exposeOriginalFile: true,
			onFileSelected: (file: PickerFileMetadata) => new Promise<void>((resolve) => {
				const blob = file.originalFile;

				// Get an object URL for the local file
				const url = URL.createObjectURL(blob as Blob);

				// Create an image and load the object URL
				const img = new Image();
				img.src = url;

				img.onload = () => {
					URL.revokeObjectURL(url);
					this.singleModel2d.imageHeight = img.height;
					this.singleModel2d.imageWidth = img.width;

					resolve();
				};
			}),
			onUploadDone: async (files: PickerResponse) => {
				this.singleModel2d.imageUrl = files.filesUploaded[0].url;
				this.placeHolders.thumbnailPlaceholder = files.filesUploaded[0].filename;
			},
		};
		this.$client.picker(options).open();
	}

	removeThumbnail(): void {
		this.singleModel2d.imageUrl = '';
		this.imageUrl = '';
	}
}
