import { Vue, Component, Ref } from 'vue-property-decorator';
import EditRegionTable from 'components/RegionTable/EditRegionTable';
import Template from './template.vue';

@Component({
	components: {
		EditRegionTable,
	},
})
export default class EditRegion extends Vue.extend(Template) {
	@Ref('editregion') readonly regionModal!: HTMLFormElement;

	private route = this.$route.params.id;

	private isLoading = false;

	protected updateRegionProperties(): void {
		this.regionModal.editRegion();
	}
}
