import { Vue, Component, Prop } from 'vue-property-decorator';
import { httpClient } from 'utils/http';
import floatingPoint from 'directives/floatingPoint';
import * as DB from 'interfaces/database';
import Template from './template.vue';

@Component({
	directives: {
		floatingPoint,
	},
})
export default class PriceInput extends Vue.extend(Template) {
	private dataChanged = false;

	private inputValue?: number | null;

	private result = [] as any;

	@Prop({
		type: Object,
		required: true,
	})
	data!: DB.OfferingModel

	@Prop({
		type: String,
		required: true,
	})
	type!: string

	updateValue(value: DB.PricingModel[], inputValue: string, index: number, key: string) :void {
		if (inputValue === '') {
			this.dataChanged = false;
		}

		this.result = value;
		// check to see if the current user input is same as the previous input or if the current input is empty
		if (this.result[index][key] === parseInt(inputValue,
			10)) {
			this.dataChanged = false;
		} else if (inputValue !== '' && this.result[index][key] !== parseInt(inputValue,
			10)) {
			this.result[index][key] = parseInt(inputValue,
				10);
			this.dataChanged = true;
		}
	}

	async onDataChange(): Promise<void> {
		if (this.dataChanged === true) {
			try {
				await httpClient.post('api/price/import',
					this.result);
			} catch (error: any) {
				this.$bvToast.toast(`${error.message}`,
					{
						solid: true,
						variant: 'danger',
					});
			}
		}
	}
}
