var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.data.salesvalue
      ? _c("div", [
          _vm._v(
            "\n\t\t" +
              _vm._s(_vm.data.currency) +
              " : " +
              _vm._s((_vm.data.salesvalue / 100).toFixed(2)) +
              "\n\t"
          )
        ])
      : _vm.discount_voucher
      ? _c("div", [
          _vm._v(
            "\n\t\t" +
              _vm._s(_vm.data.currency) +
              " " +
              _vm._s((_vm.data.discount_voucher / 100).toFixed(2)) +
              "\n\t"
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }