/*
 * Main and demo navigation arrays
 */

export default {
	main: [
		{
			name: 'Dashboard',
			to: '/dashboard',
			icon: 'si si-speedometer',
		},
		{
			name: 'Offering Category',
			icon: 'si si-energy',
			subActivePaths: '/backend/blocks',
			sub: [
				{
					name: 'Lister Items',
					to: '/productcategory',
				},
				{
					name: 'Offering',
					to: '/offerings',
				},
				{
					name: 'Pricing',
					to: '/pricing',
				},
				{
					name: 'Badges',
					to: '/badges',
				},
				{
					name: 'PDPs',
					to: '/pdp',
				},
				{
					name: '2D Models',
					to: '/model2d',
				},
				{
					name: '3D Models',
					to: '/model3d',
				},
				{
					name: 'Cross Sell',
					to: '/crosssell',
				},
			],
		},
		{
			name: 'Product Stream',
			icon: 'si si-badge',
			subActivePaths: '/products-ordered',
			sub: [
				{
					name: 'Products Ordered',
					to: '/products-ordered',
				},
			],
		},
		{
			name: 'Developer',
			icon: 'si si-wrench',
			subActivePaths: '/app-settings',
			sub: [
				{
					name: 'App Setting',
					to: '/appsetting',
				},
				{
					name: 'API permission',
					to: '/application',
				},
			],
		},
		{
			name: 'Localisation',
			icon: 'si si-puzzle',
			subActivePaths: '/region',
			sub: [
				{
					name: 'Regions',
					to: '/region',
				},
				{
					name: 'Currencies',
					to: '/currency',
				},
				{
					name: 'Countries',
					to: '/country',
				},
				{
					name: 'Hyperlinks',
					to: '/hyperlink',
				},
				{
					name: 'Pickup stores',
					to: '/branch',
				},
			],
		},
		{
			name: 'Themes',
			icon: 'si si-fire',
			subActivePaths: '/themecategories',
			sub: [
				{
					name: 'Photoframes',
					to: '/photoframe',
				},
				{
					name: 'Layouts',
					to: '/layout',
				},
				{
					name: 'Category',
					to: '/themecategory',
				},
				{
					name: 'Themes',
					to: '/themes',
				},
				{
					name: 'Templates',
					to: '/template',
				},
				{
					name: 'Page Background',
					to: '/pagebg',
				},
			],
		},
		{
			name: 'Discounts',
			icon: 'si si-layers',
			subActivePaths: '/discount',
			sub: [
				{
					name: 'Bulk Discount',
					to: '/discount-bulk',
				},
				{
					name: 'Discount',
					to: '/discount',
				},
			],
		},
	],
};
