var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { class: _vm.classContainer },
    _vm._l(_vm.filteredNodes, function(node, index) {
      return _c(
        "li",
        {
          key: `node-${index}`,
          class: {
            "nav-main-heading": node.heading,
            "nav-main-item": !node.heading,
            open:
              node.sub && node.subActivePaths
                ? _vm.subIsActive(node.subActivePaths)
                : false
          }
        },
        [
          !node.heading && !node.sub
            ? _c(
                "router-link",
                {
                  staticClass: "nav-main-link",
                  attrs: {
                    to: node.to || "#",
                    "active-class": node.to && node.to !== "#" ? "active" : ""
                  },
                  nativeOn: {
                    click: function($event) {
                      return _vm.linkClicked($event)
                    }
                  }
                },
                [
                  node.icon
                    ? _c("i", { class: `nav-main-link-icon ${node.icon}` })
                    : _vm._e(),
                  _vm._v(" "),
                  node.name
                    ? _c("span", { staticClass: "nav-main-link-name" }, [
                        _vm._v(_vm._s(node.name))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  node.badge
                    ? _c(
                        "span",
                        {
                          staticClass:
                            "nav-main-link-badge badge badge-pill badge-primary",
                          class: node["badge-variant"]
                            ? `badge-${node["badge-variant"]}`
                            : "badge-primary"
                        },
                        [_vm._v(_vm._s(node.badge))]
                      )
                    : _vm._e()
                ]
              )
            : !node.heading && node.sub
            ? _c(
                "a",
                {
                  staticClass: "nav-main-link nav-main-link-submenu",
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.linkClicked($event, true)
                    }
                  }
                },
                [
                  node.icon
                    ? _c("i", { class: `nav-main-link-icon ${node.icon}` })
                    : _vm._e(),
                  _vm._v(" "),
                  node.name
                    ? _c("span", { staticClass: "nav-main-link-name" }, [
                        _vm._v(_vm._s(node.name))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  node.badge
                    ? _c(
                        "span",
                        {
                          staticClass:
                            "nav-main-link-badge badge badge-pill badge-primary",
                          class: node["badge-variant"]
                            ? `badge-${node["badge-variant"]}`
                            : "badge-primary"
                        },
                        [_vm._v(_vm._s(node.badge))]
                      )
                    : _vm._e()
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          node.sub
            ? _c("base-navigation", {
                attrs: { nodes: node.sub, "sub-menu": "" }
              })
            : _vm._e()
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }