import { Vue, Component } from 'vue-property-decorator';
import PageBgTable from 'components/Themes/PageBgTable';
import Template from './template.vue';

@Component({
	components: {
		PageBgTable,
	},
})
export default class PageBackground extends Vue.extend(Template) {
	protected showModal(): void {
		this.$root.$emit('bv::show::modal',
			'pagebg-modal',
			'#pagebgShow');
	}

	protected routePagebg(id: string): void {
		this.$router.push(`/pagebg/${id}`);
	}
}
