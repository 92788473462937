import { Vue, Component, Ref } from 'vue-property-decorator';
import * as DB from 'interfaces/database';
import { BModal } from 'bootstrap-vue';
import mitt from 'mitt';
import Swal from 'sweetalert2';
import { TabEvent } from 'interfaces/app';
import { httpClient } from 'utils/http';
import { CellComponentExtended, ColumnDefinitionExtended, TabulatorFull as Tabulator } from 'tabulator-tables';
import { createInstance } from 'utils/vue';
import TabulatorBtn from 'components/Tabulator/TabulatorBtn';

const eventBus = mitt<TabEvent<DB.HyperlinkModel>>();
@Component({
	components: {
		TabulatorBtn,
	},
})
export default class HyperlinksTable extends Vue {
	@Ref('addhyperlinks-modal') readonly modal!: BModal;

	@Ref('HyperLinksTable')
	private readonly tableReference!: HTMLDivElement;

	private hyperlink : DB.HyperlinkModel[] = [];

	private region: DB.RegionModel[] = [];

	private productCategory: DB.ProductCategoryModel[] = [];

	private language: DB.LanguageModel[] = [];

	private pdp: DB.PDPModel[] = [];

	private hyperLinkdata = {
		languageid: '',
		regionid: null,
		pdpid: null,
		productcategoryid: null,
		tag: null,
		url: '',
		delegate: false,
	};

	private table?: Tabulator;

	private isLoaded = false;

	private columnDefs: ColumnDefinitionExtended[] = [];

	private get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	protected beforeMount(): void {
		this.columnDefs = [
			{
				field: 'languageid',
				title: 'Language',
			},
			{
				title: 'Region',
				field: 'regionname',
			},
			{
				title: 'Product Category',
				field: 'categoryname',
			},
			{
				title: 'Tag',
				field: 'tag',
			},
			{
				title: 'URL',
				field: 'url',
			},
			{
				title: 'Delegate',
				field: 'delegate',
				formatter: (cell: CellComponentExtended<DB.HyperlinkModel>) => {
					const icon = document.createElement('i');
					if (cell.getValue() === 0) {
						icon.className = 'fa fa-fw fa-times';
						return icon;
					}
					icon.className = 'si si-check';
					return icon;
				},
			},
			{
				title: 'Actions',
				formatter: (cell: CellComponentExtended<DB.HyperlinkModel>) => {
					const instance = createInstance({
						component: TabulatorBtn,
						props: {
							data: cell.getData(),
							buttons: [
								{
									id: 'edit',
									eventName: 'editItem',
									className: 'fa-edit',
								},
								{
									id: 'delete',
									eventName: 'deleteItem',
									className: 'fa-times',
								},
							],
							eventBus,
						},
					});
					instance.$mount();

					return (instance.$el as HTMLElement);
				},
			},
		];
	}

	protected mounted(): void {
		eventBus.on('deleteItem',
			this.deleteHyperlink);
		eventBus.on('editItem',
			this.editHyperlink);
		this.tableInitialization();
		this.table?.on('tableBuilt',
			this.onTableBuilt);
	}

	protected beforeDestroy(): void {
		eventBus.off('deleteItem',
			this.deleteHyperlink);
		eventBus.off('editItem',
			this.editHyperlink);
		this.table?.off('tableBuilt',
			this.onTableBuilt);
		this.table?.destroy();
	}

	private onTableBuilt(): void {
		this.getData();
	}

	private tableInitialization(): void {
		this.table = new Tabulator(this.tableReference,
			{
				height: '60vh',
				layout: 'fitColumns',
				columns: this.columnDefs,
			});
	}

	private getData(): void {
		const parameter = new URLSearchParams({
			limit: '0',
		});
		this.table?.alert('Loading');
		Promise
			.all([
				httpClient.get<DB.HyperlinkModel[]>(`/api/hyperlink?${parameter}`),
				httpClient.get<DB.RegionModel[]>(`/api/region?${parameter}`),
				httpClient.get<DB.ProductCategoryModel[]>(`/api/productcategory?${parameter}`),
				httpClient.get<DB.PDPModel[]>(`/api/pdp?${parameter}`),
				httpClient.get<DB.LanguageModel[]>(`/api/language?${parameter}`),
			])
			.then((
				[
					hyperlinkResponse,
					regionResponse,
					productCategoryResponse,
					pdpResponse,
					languageResponse,
				],
			) => {
				this.hyperlink = hyperlinkResponse.data;
				this.region = regionResponse.data;
				this.productCategory = productCategoryResponse.data;
				this.pdp = pdpResponse.data;
				this.language = languageResponse.data;
				const data = hyperlinkResponse.data.map((item) => ({
					...item,
					regionname: regionResponse.data.find((i) => i.id === item.regionid)?.name,
					categoryname: productCategoryResponse.data.find((i) => i.id === item.productcategoryid)?.name,
				}));
				this.table?.setData(data);
				return undefined;
			}).finally(() => {
				this.table?.clearAlert();
			}).catch((err) => {
				this.$bvToast.toast(`${err.message}`,
					{
						solid: true,
						variant: 'danger',
					});
			});
	}

	protected async addHyperlinks(): Promise<void> {
		try {
			this.isLoaded = true;
			const res = await httpClient.post('/api/hyperlink',
				{ ...this.hyperLinkdata });
			this.modal.hide();
			this.table?.addData(res.data);
			this.hyperLinkdata = {
				pdpid: null, languageid: '', regionid: null, productcategoryid: null, tag: null, url: '', delegate: false,
			};
			this.$emit('routeHyperlink',
				res.data.id);
		} catch (err: any) {
			this.$bvToast.toast(`${err.message}`,
				{ solid: true, variant: 'danger' });
		} finally {
			this.isLoaded = false;
		}
	}

	private editHyperlink(data: TabEvent<DB.HyperlinkModel>['editItem']): void {
		this.$emit('routeHyperlink',
			data.id);
	}

	private deleteHyperlink(data: TabEvent<DB.HyperlinkModel>['deleteItem']): void {
		Swal.fire({
			title: 'Are you sure?',
			text: 'You will not be able to recover this file!',
			icon: 'warning',
			showCancelButton: true,
			customClass: {
				confirmButton: 'btn btn-danger m-1',
				cancelButton: 'btn btn-secondary m-1',
			},
			confirmButtonText: 'Yes, delete it!',
			html: false,
		}).then((result) => {
			if (result.value) {
				this.table?.alert('Loading...');
				// eslint-disable-next-line promise/no-nesting
				httpClient.delete(`/api/hyperlink/${data.id}`).then(() => {
					this.$bvToast.toast('Item Deleted',
						{
							solid: true,
							variant: 'success',
						});
					this.table?.deleteRow(data.id);
					return null;
				}).finally(() => {
					this.table?.clearAlert();
				}).catch((err) => {
					this.$bvToast.toast(`${err.message}`,
						{
							solid: true,
							variant: 'danger',
						});
				});
			}
			return undefined;
		}).catch((err) => {
			this.$bvToast.toast(`${err.message}`,
				{
					solid: true,
					variant: 'danger',
				});
		});
	}
}
