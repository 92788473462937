import { Vue, Component, Ref } from 'vue-property-decorator';
import EditModel3dTable from 'components/Model3dTable/EditModel3dTable.vue';

@Component({
	components: {
		EditModel3dTable,
	},
})
export default class EditModel3d extends Vue {
	@Ref('editmodel3d') readonly model3dModal!: HTMLFormElement;

	private isLoading = false;

	get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	updateModel3dProperties(): void {
		this.model3dModal.editModel3dImage();
	}
}
