import { Vue, Component, Watch } from 'vue-property-decorator';
import * as DB from 'interfaces/database';
import { httpClient } from '../../utils/http';

@Component({})
export default class EditCountryTable extends Vue {
	private singleCountry = {} as DB.CountryModel;

	private currency: DB.CurrencyModel[] = [];

	private region: DB.RegionModel[] = [];

	private loaded = false;

	private visible = false;

	private spinner = false;

	private index = 0;

	private isLoading = false;

	private pageOptions = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50];

	get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	mounted() {
		this.getSingleCountry();
		Promise
			.all([
				httpClient.get<DB.CurrencyModel[]>('/api/currency?limit=0'),
				httpClient.get<DB.RegionModel[]>('/api/region?limit=0'),
			])
			.then(([currencyResponse, regionResponse]) => {
				this.currency = currencyResponse.data;
				this.region = regionResponse.data;
				return null;
			}).catch((error) => {
				this.$bvToast.toast(`${error.message}`,
					{
						solid: true,
						variant: 'danger',
					});
			});
	}

	@Watch('loggedIn')
	async getSingleCountry(): Promise<DB.CountryModel | undefined> {
		if (this.loggedIn) {
			// set the loading state
			this.isLoading = true;
			try {
				const resp = await httpClient.get<DB.CountryModel>(`/api/country/${this.$route.params.id}`);
				this.singleCountry = resp.data;
				// eslint-disable-next-line consistent-return
				return this.singleCountry;
			} catch (error: any) {
				this.$bvToast.toast(`${error.message}`,
					{
						solid: true,
						variant: 'danger',
					});
			} finally {
				this.isLoading = false;
			}
		}
		return undefined;
	}

	editCountry(): Promise<void> | undefined {
		if (this.loggedIn) {
			this.isLoading = true;
			httpClient.put<DB.CountryModel>(`/api/country/${this.$route.params.id}`,
				{ ...this.singleCountry }).then(() => {
				this.$bvToast.toast('country Edited successfully',
					{
						solid: true,
						variant: 'success',
					});
				return undefined;
			})
				.finally(() => {
					this.isLoading = false;
				})
				.catch((error) => {
					this.$bvToast.toast(`${error.message}`,
						{
							solid: true,
							variant: 'danger',
						});
				});
		}
		return undefined;
	}
}
